<app-loading-spinner
    *ngIf="isLoading"
    [text]="text"
    [overlay]="true"></app-loading-spinner>
<div class="col-10">
    <div class="top-section">
        <div class="item space">
            <app-test-result
                [recentTestRun]="recentTestRun"></app-test-result>
        </div>
        <div class="item">
            <app-test-start
                [testIsRunning]="testIsRunning"
                (testStartedEvent)="testStartedEvent($event)"></app-test-start>
        </div>
    </div>
    <app-test-cancel
        *ngIf="testIsRunning"
        [testIsRunning]="testIsRunning"
        [activeTestId]="activeTestId"
        (testStartedEvent)="testStartedEvent($event)"></app-test-cancel>
    <div [ngClass]=" testIsRunning ? '': 'topspace'">
        <app-test-history
            [testRuns]="testRuns"></app-test-history>
    </div>
</div>
