export class SecurityMessage {
    constructor(
        public type: string,
        public title: string,
        public message: string,
        public showLogin: boolean = false,
        public isDefault: boolean = false
    ) {}
}

export const securityMessages = [
    new SecurityMessage(
        'COOKIE_IDLE_TIMEOUT_REACHED',
        'Sitzung abgelaufen',
        'Die Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein.',
        true
    ),
    new SecurityMessage(
        'ACCESS_TOKEN_REFRESH_FAILED',
        'Authentifizierungsfehler',
        'Es ist ein Authentifizierungsfehler augetreten. Bitte laden Sie die Seite neu.',
        false,
        true
    ),
    new SecurityMessage(
        'COOKIE_PARSING_FAILED',
        'Authentifizierungfehler',
        'Es konnten keine Authenifizierungsinformationen gefunden werden. Bitte laden Sie die Seite neu, um sich anzumelden.'
    ),
    new SecurityMessage(
        'MISSING_AUTHORIZATION_GROUP',
        'Keine Zugriffsrechte',
        'Sie verfügen nicht über ausreichende Rechte, um die Applikation zu starten.'
    ),
];

export function getSecurityMessageInformation(type: string): SecurityMessage {
    const securityMessage = securityMessages.find(
        message => message.type === type
    );
    return securityMessage ? securityMessage : getDefaultSecurityMessage();
}

export function getDefaultSecurityMessage() {
    return securityMessages.find(message => message.isDefault);
}
