import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    SecurityMessage,
    getDefaultSecurityMessage,
    getSecurityMessageInformation,
} from '@shared/models/security-message';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { ErrorServiceService } from '@shared/services/error-service/error-service.service';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UnauthorizedModalComponent } from '../unauthorized-modal/unauthorized-modal.component';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
    private calculateApiPath = 'process/inbound/calculate';

    constructor(
        private errorService: ErrorServiceService,
        private notificationService: ErrorHandlerService,
        private modalService: NgbModal,
        private localStorageService: LocalStorageService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.url.indexOf('api/import') > -1) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': `text/csv`,
                },
            });
        } else if (request.url.indexOf('api/export/file/') > -1) {
            // Set header for exporting files from datamanager and also calculation
            request = request.clone({
                setHeaders: {
                    'Content-Type': `application/octet-stream`,
                    Accept: `application/octet-stream`,
                },
            });
        } else {
            if (
                request.headers.keys().indexOf('Content-Type') === -1 &&
                request.headers.keys().indexOf('enctype') === -1
            ) {
                request = request.clone({
                    setHeaders: {
                        'Content-Type': `application/json; charset=utf-8`,
                        Accept: `application/json; charset=utf-8`,
                    },
                });
            }
        }

        return next.handle(this.addAccessToken(request)).pipe(
            catchError((error: HttpErrorResponse | Error) => {
                // Custom Handling for processor (XML Usage)
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        this.handleUnauthorizedRequest(error);
                    } else {
                        if (request.url.indexOf(this.calculateApiPath) < 0) {
                            // Handle Server Error
                            const notification = this.errorService.getServerMessage(
                                error
                            );

                            if (
                                request.url.indexOf('jwt-echo') < 0 &&
                                error.status !== 200
                            ) {
                                this.notificationService.showError(
                                    notification.title,
                                    notification.message
                                );
                            }
                        }
                    }
                } else {
                    // Handle Client Error
                    const notification = this.errorService.getClientMessage(
                        error
                    );
                    this.notificationService.showError(
                        notification.title,
                        notification.message
                    );
                }

                return throwError(error);
            })
        );
    }

    private handleUnauthorizedRequest(httpError: HttpErrorResponse) {
        const modalRef = this.modalService.open(UnauthorizedModalComponent, {
            backdrop: 'static',
            keyboard: false,
        });

        let securityMessage: SecurityMessage;

        if (httpError && httpError.error && httpError.error.message) {
            securityMessage = getSecurityMessageInformation(
                httpError.error.message
            );
        } else {
            securityMessage = getDefaultSecurityMessage();
        }

        modalRef.componentInstance.title = securityMessage.title;
        modalRef.componentInstance.message = securityMessage.message;
        modalRef.componentInstance.showLogin = securityMessage.showLogin;
    }

    private addAccessToken(request: HttpRequest<any>): HttpRequest<any> {
        const accessToken = this.localStorageService.getItem(
            'zke-access-token'
        );

        if (!accessToken) {
            return request;
        }

        if (
            request.url.includes('s3') &&
            request.url.includes('amazonaws.com/') &&
            request.url.includes('/ZKE_User_Manual.pdf')
        ) {
            return request;
        }

        return request.clone({
            headers: request.headers.set(
                'Authorization',
                'Bearer ' + accessToken
            ),
        });
    }
}
