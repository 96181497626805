import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { interval, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(
        private httpClient: HttpClient,
        private localStorageService: LocalStorageService
    ) {}

    handleError(error: HttpErrorResponse) {
        let errorMessage;

        if (error.error instanceof ErrorEvent) {
            errorMessage = `LoginService.handleError()\nError: ${error.error.message}`;
        } else {
            errorMessage = `LoginService.handleError()\nError Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

    getAccessToken(code: string) {
        return this.httpClient
            .get(`${environment.commonApiUrl}/login`, {
                params: { code },
            })
            .pipe(catchError(this.handleError));
    }

    refreshAccessToken(refreshToken: string) {
        return this.httpClient
            .post(`${environment.commonApiUrl}/login-refresh`, {
                refresh_token: refreshToken,
            })
            .pipe(catchError(this.handleError));
    }

    startRefreshAccessTokenInterval() {
        // -3 minutes delay * conversion to milliseconds
        const expiresIn =
            (this.localStorageService.getItem('zke-access-expires-in') - 300) *
            1000;

        interval(expiresIn).subscribe(() => {
            const refreshToken = this.localStorageService.getItem(
                'zke-access-refresh-token'
            );

            if (refreshToken && refreshToken.length) {
                this.refreshAccessToken(refreshToken).subscribe({
                    next: (response: any) => {
                        if (response && response.access_token) {
                            this.setResponseDataToLocalStorage(response);
                        } else {
                            console.log(
                                'LoginService.startRefreshAccessTokenInterval() - Response Failure - Clear Local Storage '
                            );
                            this.localStorageService.clearExcept([
                                'savedVins',
                                'savedZksnrCebas',
                                'savedZksnrCebas',
                            ]);
                        }
                    },
                    error: error => {
                        console.log(
                            `LoginService.startRefreshAccessTokenInterval()\nError: ${error}`
                        );
                    },
                });
            }
        });
    }

    setResponseDataToLocalStorage(response: any) {
        if (response && response.access_token) {
            this.localStorageService.setItem(
                'zke-access-token',
                response.access_token
            );

            this.localStorageService.setItem(
                'zke-access-refresh-token',
                response.refresh_token
            );

            this.localStorageService.setItem(
                'zke-access-expires-in',
                response.expires_in
            );

            this.localStorageService.setItem(
                'zke-access-valid-till',
                new Date().getTime() + response.expires_in * 1000
            );
        }
    }
}
