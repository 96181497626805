import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { menuPaths } from '@shared/constants/paths';
import { DataPoolService } from '@shared/services/datapool/datapool.service';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { SecurityService } from '@shared/services/security/security.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { LoginService } from './login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    private notifier = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private loginService: LoginService,
        private localStorageService: LocalStorageService,
        private securityService: SecurityService,
        private dataPoolService: DataPoolService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(
                switchMap(params => {
                    return this.loginService.getAccessToken(params.code);
                }),
                takeUntil(this.notifier)
            )
            .subscribe({
                next: (response: any) => {
                    if (response && response.access_token) {
                        this.loginService.setResponseDataToLocalStorage(
                            response
                        );
                        this.localStorageService.removeItem(
                            'loginProgressValidTill'
                        );
                        this.securityService.initialize().then(() => {
                            this.securityService.isAuthorized.next(true);
                            this.loginService.startRefreshAccessTokenInterval();
                            this.dataPoolService.initializeDatapools();
                            this.router.navigate([
                                `${menuPaths.dataManagement.root}/${menuPaths.dataManagement.seriesData}`,
                            ]);
                        });
                    } else {
                        this.localStorageService.clearExcept([
                            'savedVins',
                            'savedZksnrCebas',
                            'savedZksnrCebas',
                        ]);
                        this.router.navigate([
                            `${menuPaths.dataManagement.root}/${menuPaths.dataManagement.seriesData}`,
                        ]);
                    }
                },
                error: error => {
                    console.log(`LoginComponent.ngOnInit()\nError: ${error}`);
                },
            });
    }

    ngOnDestroy() {
        this.notifier.next();
        this.notifier.complete();
    }
}
