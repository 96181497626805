import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WhitelistFormularComponent } from './whitelist-formular.component';

@NgModule({
    declarations: [WhitelistFormularComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule],
    exports: [WhitelistFormularComponent],
})
export class WhitelistFormularModule {}
