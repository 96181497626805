import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IconButtonModule } from '@shared/components/icon-button/icon-button.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ControlDataModule } from '../components/control-data/control-data.module';
import { MappingModule } from '../components/mapping/mapping.module';
import { MasterDataModule } from '../components/master-data/master-data.module';
import { TestDataModalModule } from './test-data-list/modal/test-data-modal.module';
import { TestDataListDatatableComponent } from './test-data-list/test-data-list-datatable/test-data-list-datatable.component';
import { TestDataSelectedDatapoolComponent } from './test-data-list/test-data-list-datatable/test-data-selected-datapool/test-data-selected-datapool.component';
import { TestDataListComponent } from './test-data-list/test-data-list.component';

@NgModule({
    declarations: [
        TestDataListComponent,
        TestDataListDatatableComponent,
        TestDataSelectedDatapoolComponent,
    ],
    imports: [
        CommonModule,
        MappingModule,
        NgbDropdownModule,
        HttpClientModule,
        NgbModule,
        NgxDatatableModule,
        FontAwesomeModule,
        MasterDataModule,
        ControlDataModule,
        IconButtonModule,
        TestDataModalModule,
    ],
    exports: [],
})
export class TestDataModule {}
