import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HintComponent } from '@shared/components/hint/hint.component';

@NgModule({
    declarations: [HintComponent],
    exports: [HintComponent],
    imports: [CommonModule, FontAwesomeModule],
})
export class HintModule {}
