import { AbstractControl } from '@angular/forms';

export function seriesValidator(
    control: AbstractControl
): {
    [key: string]: any;
} | null {
    const validSeries = /^[C][0-9]{3}/.test(control.value);
    return validSeries ? null : { invalidSeries: { value: control.value } };
}
