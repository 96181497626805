import { Injectable } from '@angular/core';
import { UserDatapool } from '@shared/models/datapool/user-datapool';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SelectedUserDataPoolService {
    private userDataPool = new BehaviorSubject(
        new UserDatapool(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
        )
    );
    sharedDataPool = this.userDataPool.asObservable();

    constructor() {}

    OnSelectUserDataPool(selectedDataPool: UserDatapool) {
        this.userDataPool.next(selectedDataPool);
    }
}
