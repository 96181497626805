
export class TestautomationTest {
    public timeStamp: string;
    public duration: number;
    public successfulTestCases: number;
    public unsuccessfulTestCases: number;
    public username: string;

    constructor(timeStamp: string, duration: number, successfulTestCases: number, unsuccessfulTestCases: number, username: string) {
        this.timeStamp = timeStamp;
        this.duration = duration;
        this.successfulTestCases = successfulTestCases;
        this.unsuccessfulTestCases = unsuccessfulTestCases;
        this.username = username;
    }
}
