import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { WarningComponent } from './warning.component';

@NgModule({
    declarations: [WarningComponent],
    imports: [CommonModule, FontAwesomeModule],
    exports: [WarningComponent],
})
export class WarningModule {}
