import { ModuleWithProviders, NgModule } from '@angular/core';

import { XmlPipe } from './xml.pipe';

@NgModule({
    imports: [],
    declarations: [XmlPipe],
    exports: [XmlPipe],
})
export class XmlPipeModule {
    static forRoot(): ModuleWithProviders<XmlPipeModule> {
        return {
            ngModule: XmlPipeModule,
            providers: [],
        };
    }
}
