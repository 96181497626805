import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataPoolSelectorModule } from '@shared/components/datapool-selector/datapool-selector.module';
import { IconButtonModule } from '@shared/components/icon-button/icon-button.module';
import { MetadataTableModule } from '@shared/components/metadata-table/metadata-table.module';
import { RoleDirectiveModule } from '@shared/directives/role.module';
import { ToastrModule } from 'ngx-toastr';

import { MasterDataComponent } from './master-data.component';
import { UploadFormularModule } from './upload-formular/upload-formular.module';

@NgModule({
    declarations: [MasterDataComponent],
    imports: [
        CommonModule,
        DataPoolSelectorModule,
        FormsModule,
        NgbModule,
        ToastrModule.forRoot(),
        MetadataTableModule,
        UploadFormularModule,
        IconButtonModule,
        RoleDirectiveModule,
    ],
    exports: [MasterDataComponent],
})
export class MasterDataModule {}
