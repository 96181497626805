import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImagesModule } from '../images/images.module';
import { PreDataImagesComponent } from './pre-data-images.component';

@NgModule({
    declarations: [PreDataImagesComponent],
    imports: [CommonModule, ImagesModule],
    exports: [PreDataImagesComponent],
})
export class PreDataImagesModule {}
