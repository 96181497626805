<div class="footer-container">
    <span>Anzahl {{element}}: </span>
    <span>{{rowCount}}</span>
</div>
<div class="footer-container">
    <span>Ergebnisse pro Seite: </span>
    <ng-container *ngFor="let limit of visibleLimits">
        <a (click)="setActiveLimit(limit)" [ngClass]="isLimitActiveLimit(limit) ? 'active-limit' : 'limit'"
            [class.disabled]="isLimitActiveLimit(limit)">{{limit}}</a>
        <span *ngIf="!isLastLimitOfArray(limit)"> | </span>
    </ng-container>
</div>