import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '@shared/services/api/api.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { VersionService } from '@shared/services/version/version.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const { version } = require('../../../../../package.json');

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit, OnDestroy {
    uiVersion = '';
    calculationVersion = '';
    dataManagerVersion = '';
    controllerVersion = '';
    subscriptionManagerVersion = '';
    testManagerVersion = '';
    vedocGatewayVersion = '';

    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();

    constructor(
        private versionService: VersionService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.uiVersion = version;
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );

                this.versionService
                    .getCalculationVersion(this.ROOT_URL)
                    .subscribe(
                        calculationVersion =>
                            (this.calculationVersion = calculationVersion)
                    );

                this.versionService
                    .getDataManagerVersion(this.ROOT_URL)
                    .subscribe(
                        dataManagerVersion =>
                            (this.dataManagerVersion = dataManagerVersion)
                    );

                this.versionService
                    .getControllerVersion(this.ROOT_URL)
                    .subscribe(
                        controllerVersion =>
                            (this.controllerVersion = controllerVersion)
                    );

                this.versionService
                    .getSubscriptionManagerVersion(this.ROOT_URL)
                    .subscribe(
                        subscriptionManagerVersion =>
                            (this.subscriptionManagerVersion = subscriptionManagerVersion)
                    );

                this.versionService
                    .getTestManagerVersion()
                    .subscribe(
                        testManagerVersion =>
                            (this.testManagerVersion = testManagerVersion)
                    );

                this.versionService
                    .getVedocGatewayVersion()
                    .subscribe(
                        vedocGatewayVersion =>
                            (this.vedocGatewayVersion = vedocGatewayVersion)
                    );
            });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
