import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export const etmSkidOrZkSnrValidator: ValidatorFn = (
    formGroup: UntypedFormGroup
) => {
    const zksnrETM = formGroup.get('zksnrETM').value;
    const skidETM = formGroup.get('skidETM').value;

    if (!zksnrETM && skidETM) {
        const validSkidETM = /^([0-9A-Fa-f]{2}-){19}[0-9A-Fa-f]{2}$/.test(
            skidETM
        );
        return validSkidETM ? null : { invalidSkidETM: { value: skidETM } };
    } else if (zksnrETM && !skidETM) {
        const validZkSnrETM = /^A[0-9]{10}$/.test(zksnrETM);
        return validZkSnrETM ? null : { invalidZkSnrETM: { value: zksnrETM } };
    }
};
