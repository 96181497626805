import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnsTopic } from '@shared/models/sns-notification/sns-topic';
import { SnsTopicSubscriber } from '@shared/models/sns-notification/sns-topic-subscriber';
import { ApiService } from '@shared/services/api/api.service';
import { SnsNotificationService } from '@shared/services/sns-notification/sns-notification.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationDetailComponent } from './components/notification-detail/notification-detail.component';

@Component({
    selector: 'app-notification-management',
    templateUrl: './notification-management.component.html',
    styleUrls: ['./notification-management.component.scss'],
})
export class NotificationManagementComponent implements OnInit, OnDestroy {
    topics: SnsTopic[];
    isLoading = false;

    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();

    constructor(
        private modalService: NgbModal,
        private switchService: SwitchService,
        private apiService: ApiService,
        private snsNotificationService: SnsNotificationService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
                this.getTopics();
            });
    }

    detail(event: { topic: SnsTopic }) {
        this.getSubscribersByTopicName(
            event.topic.name
        ).subscribe((subscribers: SnsTopicSubscriber[]) =>
            this.openDetailModal(event.topic, subscribers)
        );
    }

    private openDetailModal(topic, subscribers: SnsTopicSubscriber[]) {
        const modalRef = this.modalService.open(NotificationDetailComponent, {
            windowClass: 'modalVeryBig',
        });

        modalRef.componentInstance.topic = topic;
        modalRef.componentInstance.subscribers = subscribers;
        modalRef.componentInstance.ROOT_URL = this.ROOT_URL;
    }

    private getTopics() {
        this.isLoading = true;
        this.snsNotificationService
            .get(this.ROOT_URL)
            .pipe(takeUntil(this.notifier))
            .subscribe((notifications: SnsTopic[]) => {
                this.topics = notifications;
                this.isLoading = false;
            });
    }

    private getSubscribersByTopicName(
        topicName: string
    ): Observable<SnsTopicSubscriber[]> {
        return this.snsNotificationService
            .getSubscribersByTopicName(this.ROOT_URL, topicName)
            .pipe(takeUntil(this.notifier));
    }

    ngOnDestroy() {
        this.notifier.next();
        this.notifier.complete();
    }
}
