import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Datapool } from '@shared/models/datapool/datapool';
import { Image } from '@shared/models/image';
import { ImageDeploymentHistory } from '@shared/models/image-deployment-history';
import { ApiService } from '@shared/services/api/api.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ImageDeploymentHistoryComponent } from '../images/image-deployment-history/image-deployment-history.component';
import { ImageFileListComponent } from '../images/image-file-list/images-file-list.component';
import { ImageManagementService } from '../services/image-management/image-management.service';
import { ProductiveImageService } from '../services/productive-image/productive-image.service';

@Component({
    selector: 'app-productive-image',
    templateUrl: './productive-image.component.html',
    styleUrls: ['./productive-image.component.scss'],
})
export class ProductiveImageComponent implements OnInit, OnDestroy {
    image: Image;
    previousImage: Image;
    fileCount = 0;
    isCollapsed = false;
    isLoading = true;

    private dataPool: Datapool = Datapool.SERIE;
    private ROOT_URL: string;
    private notifier = new Subject();
    private switchSetting: string;

    constructor(
        private imageService: ImageManagementService,
        private productiveImageService: ProductiveImageService,
        private modalService: NgbModal,
        private switchService: SwitchService,
        private apiService: ApiService,
        private notificationService: ErrorHandlerService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
                this.getImage();

                this.productiveImageService
                    .refreshImageSubscriber()
                    .subscribe(() => {
                        this.getImage();
                    });
            });
    }

    getImage() {
        this.isLoading = true;
        this.imageService
            .getImages(this.ROOT_URL, this.dataPool)
            .subscribe(async (images: Image[]) => {
                this.setDeployedImage(images);
                this.getPreviousImage(images);
                this.fileCount = await this.getFilesCounter();
                this.isLoading = false;
            });
    }

    async showImageFiles() {
        let success = false;
        try {
            await this.getImageFiles(this.image);
            success = !isNullOrUndefined(this.image.files);
        } finally {
            if (success) {
                const modalRef = this.modalService.open(
                    ImageFileListComponent,
                    {
                        windowClass: 'modalVeryBig',
                        backdrop: 'static',
                        keyboard: false,
                    }
                );
                modalRef.componentInstance.image = this.image;
                modalRef.componentInstance.dataPool = this.dataPool;
            }
        }
    }
    async showImageDeploymentHistory() {
        let toOpenHistory: ImageDeploymentHistory[];
        let success = false;
        try {
            toOpenHistory = await this.getImageDeploymentHistory(this.image);
            success = !isNullOrUndefined(toOpenHistory);
        } finally {
            if (success) {
                const modalRef = this.modalService.open(
                    ImageDeploymentHistoryComponent,
                    {
                        windowClass: 'modalBig',
                        backdrop: 'static',
                        keyboard: false,
                    }
                );
                modalRef.componentInstance.image = this.image;
                modalRef.componentInstance.history = toOpenHistory;
            }
        }
    }

    getLoadingText(): string {
        return 'Informationen zum produktiven Image werden geladen...';
    }

    productiveImageExist(): boolean {
        return !this.isLoading && this.image && true;
    }

    private async getFilesCounter(): Promise<number> {
        try {
            await this.getImageFiles(this.image);
        } finally {
            if (!this.image || Object.keys(this.image.files).length === 0) {
                return 0;
            }
            return Object.keys(this.image.files)
                .map(key => this.image.files[key].length)
                .reduce((previous, currentValue) => previous + currentValue);
        }
    }

    private setDeployedImage(images: Image[]) {
        this.image = images.find(
            image => image.deployedFrom != null && image.deployedUntil == null
        );
        this.getImageFiles(this.image);
    }

    private getPreviousImage(images: Image[]) {
        if (!this.image) {
            return;
        }

        images.forEach(image => {
            if (image.deployedUntil) {
                if (this.previousImage) {
                    this.previousImage =
                        image.deployedUntil > this.previousImage.deployedUntil
                            ? image
                            : this.previousImage;
                } else {
                    this.previousImage = image;
                }
            }
        });
    }

    private async getImageFiles(image: Image) {
        try {
            this.image = await this.imageService
                .getImageWithFiles(this.ROOT_URL, this.dataPool, image.imageTag)
                .toPromise();
        } catch (error) {
            this.notificationService.showError(
                'Fehler',
                `Image details von ${image.imageTag} konnten nicht geladen werden.`
            );
        }
    }

    private async getImageDeploymentHistory(
        image: Image
    ): Promise<ImageDeploymentHistory[]> {
        var myDeploymentHistory = [];
        try {
            myDeploymentHistory = await this.imageService
                .getImageDeploymentHistory(
                    this.ROOT_URL,
                    this.dataPool,
                    image.imageTag
                )
                .toPromise();
        } catch (error) {
            this.notificationService.showError(
                'Fehler',
                `Deployment Historie von ${image.imageTag} konnte nicht geladen werden.`
            );
        }
        return myDeploymentHistory;
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
