import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';

import { ForbiddenPageComponent } from './forbidden-page.component';

@NgModule({
    declarations: [ForbiddenPageComponent],
    imports: [CommonModule, LoadingSpinnerModule],
    exports: [ForbiddenPageComponent],
})
export class ForbiddenPageModule {}
