import { Injectable } from '@angular/core';

export enum UserDatapoolEntitlement {
    OWNER = 'OWNER',
    WRITE = 'WRITE',
    READ = 'READ',
}

export function setEntitlementText(
    entitlement: UserDatapoolEntitlement
): string {
    return entitlement === UserDatapoolEntitlement.OWNER
        ? 'Vollzugriff'
        : entitlement === UserDatapoolEntitlement.WRITE
        ? 'Vollzugriff'
        : entitlement === UserDatapoolEntitlement.READ
        ? 'Lesen'
        : undefined;
}

export class UserDataPoolEntitlementEntry {
    constructor(
        public username: string,
        public dataPool?: string,
        public writeAccess?: boolean
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class UserDataPoolEntitlementEntryAdapter {
    adapt(item: any): UserDataPoolEntitlementEntry {
        return new UserDataPoolEntitlementEntry(item || undefined);
    }
}
