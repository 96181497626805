import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WarningModule } from '@shared/components/warning/warning.module';

import { DataManagementComponent } from './data-management.component';
import { PreDataModule } from './pre-data/pre-data.module';
import { SeriesDataModule } from './series-data/series-data.module';
import { TestDataModule } from './test-data/test-data.module';

@NgModule({
    declarations: [DataManagementComponent],
    imports: [
        TestDataModule,
        PreDataModule,
        SeriesDataModule,
        WarningModule,
        RouterModule,
    ],
    exports: [DataManagementComponent],
})
export class DataManagementModule {}
