export const loginPath = 'oauth2/idpresponse';

export const commonPaths = {
    home: 'home',
    forbidden: 'forbidden',
};

export const menuPaths = {
    expertTool: {
        root: 'experttool',
    },
    dataManagement: {
        root: 'uploadtool',
        testData: 'test',
        preData: 'vorab',
        seriesData: 'serien',
    },
    imageManagement: {
        root: 'verwaltung',
        testImages: 'test',
        preImages: 'vorab',
        seriesImages: 'serien',
    },
    settings: {
        root: 'einstellungen',
        productiveSetting: 'produktivsetzung',
        logLevelManagement: 'logging',
        smokeTestManagement: 'smokeTestManagement',
        notificationManagement: 'notificationManagement',
        testautomation: 'testautomation',
    },
};

export const apiPaths = {
    zkePkwRoot: 'zke-pkw',
    zkePkwVedocGateway: 'zke-pkw/vedoc-gateway',
    zkePkwBerechnung: 'zke-pkw/berechnung',
    zkePkwDatamanager: 'zke-pkw/data-manager',
    zkePkwSteuerung: 'zke-pkw/steuerung',
    zkePkwSteuerungImage: 'zke-pkw/steuerung/api/image',
    zkePkwSteuerungImages: 'zke-pkw/steuerung/api/images',
    zkePkwVorabImage: 'zke-pkw/vorab-image',
    zkePkwTestImage: 'zke-pkw/test-image',
    zkeVanVorabImage: 'zke-van/vorab-image',
    zkeVanTestImage: 'zke-van/test-image',
    zkeVanRoot: 'zke-van',
    zkeVanVedocGateway: 'zke-van/vedoc-gateway',
    zkeVanBerechnung: 'zke-van/berechnung',
    zkeVanDatamanager: 'zke-van/data-manager',
    zkeVanSteuerung: 'zke-van/steuerung',
    zkeVanSteuerungImage: 'zke-van/steuerung/api/image',
    zkeVanSteuerungImages: 'zke-van/steuerung/api/images',

    vedocGateway: 'vedoc-gateway/api/vedoc',
    vedocRoot: 'vedoc-gateway',
    controller: 'steuerung',
    controllerImages: 'steuerung/api/images',
    controllerImage: 'steuerung/api/image',
    controllerImageStage: 'steuerung/api/image-stage',
    calculation: 'berechnung',
    dataManager: 'data-manager',
    subscriptionManager: 'subscription-manager',
    userManualUrl: 's3-presigned-url',
    testManager: 'test-manager'
};
