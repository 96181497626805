import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { apiPaths } from '@shared/constants/paths';
import { IActuatorInfo } from '@shared/models/actuator-info';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { sanitizeUrl } from 'src/app/core/utils/sanitizeUrl/sanitizeUrl';

@Injectable({
    providedIn: 'root',
})
export abstract class VersionService {
    private actuatorInfoApi = 'actuator/info';

    protected constructor(private http: HttpClient) {}

    getCalculationVersion(url: string): Observable<string> {
        return this.getVersion(
            `${url}/${apiPaths.calculation}/${this.actuatorInfoApi}`
        );
    }

    getDataManagerVersion(url: string): Observable<string> {
        return this.getVersion(
            `${url}/${apiPaths.dataManager}/${this.actuatorInfoApi}`
        );
    }

    getControllerVersion(url: string): Observable<string> {
        return this.getVersion(
            `${url}/${apiPaths.controller}/${this.actuatorInfoApi}`
        );
    }

    getSubscriptionManagerVersion(url: string): Observable<string> {
        return this.getVersion(
            `${url}/${apiPaths.subscriptionManager}/${this.actuatorInfoApi}`
        );
    }

    getTestManagerVersion(): Observable<string> {
        return this.getVersion(
            `${environment.commonApiUrl}/${apiPaths.testManager}/${this.actuatorInfoApi}`
        );
    }

    getVedocGatewayVersion(): Observable<string> {
        return this.getVersion(
            `${environment.commonApiUrl}/${apiPaths.vedocRoot}/${this.actuatorInfoApi}`
        );
    }

    private getVersion(url: string): Observable<string> {
        return this.http
            .get<IActuatorInfo>(sanitizeUrl(url))
            .pipe(
                map(
                    actuatorInfoResponse =>
                        actuatorInfoResponse.version ||
                        actuatorInfoResponse.build.version
                )
            );
    }
}
