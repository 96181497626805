<span class="toggle-text" [ngClass]="!status ? 'highlight': ''">Aus</span>
<div class="toggle-container">
    <input autocomplete="off" type="checkbox" id="toggle" [(ngModel)]="status"
           (change)="onStatusChange($event.target.checked)">
    <label class="toggle-button-switch" for="toggle"></label>
    <div class="toggle-button-text">
        <div class="toggle-button-text-on">&nbsp;</div>
        <div class="toggle-button-text-off">&nbsp;</div>
    </div>
</div>
<span class="toggle-text" [ngClass]="status ? 'highlight': ''">Ein</span>
