import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataPoolSelectorModule } from '@shared/components/datapool-selector/datapool-selector.module';
import { IconButtonModule } from '@shared/components/icon-button/icon-button.module';
import { MetadataTableModule } from '@shared/components/metadata-table/metadata-table.module';
import { RoleDirectiveModule } from '@shared/directives/role.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MappingComponent } from './mapping.component';
import { UploadFormularModule } from './upload-formular/upload-formular.module';

@NgModule({
    declarations: [MappingComponent],
    imports: [
        CommonModule,
        DataPoolSelectorModule,
        FontAwesomeModule,
        FormsModule,
        MetadataTableModule,
        NgbModule,
        NgxDatatableModule,
        UploadFormularModule,
        IconButtonModule,
        RoleDirectiveModule,
    ],
    exports: [MappingComponent],
})
export class MappingModule {}
