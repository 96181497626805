import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { IconButtonComponent } from './icon-button.component';

@NgModule({
    declarations: [IconButtonComponent],
    imports: [CommonModule, FontAwesomeModule, NgbModule],
    exports: [IconButtonComponent],
})
export class IconButtonModule {}
