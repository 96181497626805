<div class="uploadtoolInfoContainer">
    <div class="vus-title">
        <span>{{ getHeadline() }}</span>
        <div class="round-button-container">
            <icon-button *ngIf="isTestDataPool()" (action)="goBack()" [buttonType]="'Page'"
                [iconSelector]="'arrow-alt-left'"></icon-button>

            <icon-button (action)="refreshTable()" [buttonType]="'Page'" [iconSelector]="'redo'"></icon-button>

            <ng-container *appRequiredRoles="requiredWriteRoles">
                <icon-button *ngIf="hasUserEntitlement()" (action)="uploadData()" [buttonType]="'Page'"
                    [iconSelector]="'plus'"></icon-button>
            </ng-container>
        </div>
    </div>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="uploadToolTabset zke-nav-tabs">
    <li [ngbNavItem]="fileType.enumType" *ngFor="let fileType of fileTypeEnum; let index = index" id="Dateien {{fileType.enumType}}" class="zke-nav-item">
        <a ngbNavLink class="zke-nav-link">{{fileType.enumType}}</a>
        <ng-template ngbNavContent>
            <app-metadata-table *ngIf="dataPool" [dataPool]="dataPool" [fileType]="fileType.enumType"
                                [dataPoolEntitlement]="dataPoolEntitlement">
            </app-metadata-table>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
