import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FileParserService {
    parse(file: any): Promise<unknown> {
        return new Promise((resolve, reject) => {
            if (!file) {
                resolve('');
            }

            const fileReader = new FileReader();
            fileReader.onload = e => {
                resolve(fileReader.result);
            };

            fileReader.onerror = e => {
                reject(e);
            };
            fileReader.readAsText(file);
        });
    }
}
