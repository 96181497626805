import { Component, Input, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { reasons } from '@shared/components/reason-dialog/reasons';
import { Datapool } from '@shared/models/datapool/datapool';
import { ModalResult } from '@shared/models/modal-result';

export const OTHER_REASON_VALUE = 'Sonstiges';

@Component({
    selector: 'app-reason-dialog',
    templateUrl: './reason-dialog.component.html',
    styleUrls: ['./reason-dialog.component.scss'],
})
export class ReasonDialogComponent implements OnInit {
    @Input() selectedDataPool?: Datapool;

    form: UntypedFormGroup;
    reasons = reasons;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            selectedReason: [this.reasons[0].text, Validators.required],
            other: '',
        });
    }

    close() {
        if (this.form.invalid) {
            return;
        }

        const result = {
            result: ModalResult.UPDATE_SUCCESSFUL,
            reason: this.isOther()
                ? this.form.controls.other.value
                : this.form.controls.selectedReason.value,
        };
        this.activeModal.close(result);
    }

    dismiss() {
        this.activeModal.dismiss(ModalResult.UPDATE_CANCEL);
    }

    isOther(): boolean {
        return this.form.controls.selectedReason.value === OTHER_REASON_VALUE;
    }

    onChange(event) {
        this.form.controls.selectedReason.setValue(event.text);
    }
}
