import {
    Directive,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { SecurityService } from 'src/app/shared/services/security/security.service';

@Directive({
    selector: '[appRequiredRoles]',
})
export class RoleDirective implements OnDestroy {
    roles: string[];
    stop = new Subject();
    isVisible = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private securityService: SecurityService
    ) {}

    @Input() set appRequiredRoles(requiredRoles: string[]) {
        this.roles = this.securityService.user
            ? this.securityService.user.permissions
            : [];
        if (!this.roles) {
            this.viewContainerRef.clear();
        }

        if (this.roles.some(role => requiredRoles.includes(role))) {
            if (!this.isVisible) {
                this.isVisible = true;
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        } else {
            this.isVisible = false;
            this.viewContainerRef.clear();
        }
    }

    ngOnDestroy() {
        this.stop.next();
    }
}
