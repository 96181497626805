import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    constructor() {}

    downloadFile(response: HttpResponse<Blob>) {
        const fileName = response.headers
            .get('content-disposition')
            .split(';')[1]
            .split('=')[1];

        importedSaveAs(response.body, fileName);
    }

    public downloadFileWithName(blob: Blob, filename: string) {
        importedSaveAs(blob, filename);
    }
}
