import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { ModalResult } from '@shared/models/modal-result';
import { Page } from '@shared/models/page';
import { SmoketestSetting } from '@shared/models/smoketest/smoketest-setting';
import { SmoketestSettingPage } from '@shared/models/smoketest/smoketest-setting-page';
import { ApiService } from '@shared/services/api/api.service';
import { SmoketestSettingsService } from '@shared/services/smoketest-settings/smoketest-settings.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Mode } from './components/smoketest-setting-detail/models/smoketest-setting-detail-result';
import { SmoketestSettingDetailComponent } from './components/smoketest-setting-detail/smoketest-setting-detail.component';

@Component({
    selector: 'app-smoketest-management',
    templateUrl: './smoketest-management.component.html',
    styleUrls: ['./smoketest-management.component.scss'],
})
export class SmoketestManagementComponent implements OnInit, OnDestroy {
    isLoading = true;
    smoketestSettingPage: SmoketestSettingPage;
    mode = Mode;

    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();
    private page: Page;

    constructor(
        private smoketestSettingsService: SmoketestSettingsService,
        private modalService: NgbModal,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
            });
    }

    openDetailModal(mode: string, index?: number) {
        const modalRef = this.modalService.open(
            SmoketestSettingDetailComponent,
            {
                windowClass: 'modalVeryBig',
            }
        );

        modalRef.componentInstance.mode = mode;

        if (index !== undefined) {
            modalRef.componentInstance.smoketestSetting = this.smoketestSettingPage.content[
                index
            ];
        }

        modalRef.result.then(
            result =>
                result.mode === this.mode.POST
                    ? this.save(result.data)
                    : this.update(result.data),
            error => null
        );
    }

    delete(index: number) {
        const modalRef = this.modalService.open(WarningComponent);
        modalRef.componentInstance.message = `<p>Wollen Sie den ausgewählten Datensatz für die Baureihe "${this.smoketestSettingPage.content[index].baureihe}" unwiderruflich löschen?</p>`;
        modalRef.componentInstance.closingOption = false;
        modalRef.result.then(
            result => {
                if (result === ModalResult.WARNING_OK) {
                    this.smoketestSettingsService
                        .delete(
                            this.ROOT_URL,
                            this.smoketestSettingPage.content[index].baureihe
                        )
                        .pipe(takeUntil(this.notifier))
                        .subscribe(() => this.getSmokeTestSettings(this.page));
                }
            },
            error => {}
        );
    }

    paginate(page: Page) {
        this.page = page;
        this.getSmokeTestSettings(page);
    }

    private save(smoketestSetting: SmoketestSetting) {
        this.smoketestSettingsService
            .save(this.ROOT_URL, smoketestSetting)
            .pipe(takeUntil(this.notifier))
            .subscribe(
                () => this.getSmokeTestSettings(this.page),
                error => {}
            );
    }

    private update(smoketestSetting: SmoketestSetting) {
        this.smoketestSettingsService
            .update(this.ROOT_URL, smoketestSetting)
            .pipe(takeUntil(this.notifier))
            .subscribe(() => this.getSmokeTestSettings(this.page));
    }

    private getSmokeTestSettings(page: Page) {
        this.smoketestSettingsService
            .get(this.ROOT_URL, page)
            .pipe(takeUntil(this.notifier))
            .subscribe((data: SmoketestSettingPage) => {
                this.smoketestSettingPage = data;
                this.isLoading = false;
            });
    }

    ngOnDestroy() {
        this.notifier.next();
        this.notifier.complete();
    }
}
