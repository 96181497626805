<div class="vus-modal">

    <div class="modal-header close-top-right">
        <h3 class="modal-title">{{datapool.userFriendlyName}} - Vollzugriffe verwalten</h3>

        <button type="button" class="btn close" aria-label="Close" (click)="dismiss()">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div class="modal-body">
        <ngx-datatable #dataTable class="material datatable-scrollable-small" [columnMode]="'force'" [rows]="data"
            [loadingIndicator]="!data" [headerHeight]="60" [rowHeight]="54" [footerHeight]="100" [sorts]="sortConfig"
            [scrollbarH]="true" [scrollbarV]="true">
            <ngx-datatable-column name="Benutzer" prop="username" [width]="320" [resizeable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="value">{{ value }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="" prop="value" [width]="50" [resizeable]="false">
                <ng-template let-value="value" let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <icon-button (action)="deleteWriteEntitlement(row)" [toolTipDescription]="'Vollzugriff entziehen'"
                        [iconSelector]="'trash'">
                    </icon-button>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
                    <div class="footer-form">
                        <form [formGroup]="form">
                            <div class="row mt-3">
                                <div class="col-8 form-group">
                                    <label for="username">Benutzernamen eingeben</label>
                                    <input autocomplete="off" id="username" class="form-control" type="text"
                                        formControlName="username" aria-describedby="username">
                                </div>
                                <div class="col-4 form-group add-button">
                                    <button class="btn btn-primary" [disabled]="!isUsernameNotEmpty()"
                                        (click)="saveNewEntitlementEntry()">
                                        <span>Hinzufügen</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>
