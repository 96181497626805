import { UntypedFormGroup } from '@angular/forms';

export class ZKERequest {
    public vin: string;
    public zksnrCEBAS?: string;
    public skidCEBAS?: string;
    public zksnrETM?: string;
    public skidETM?: string;
    public category?: string;

    constructor(
        vin?: string,
        zksnrCEBAS?: string,
        skidCEBAS?: string,
        zksnrETM?: string,
        skidETM?: string,
        category?: string
    ) {
        this.vin = vin;
        this.zksnrCEBAS = zksnrCEBAS;
        this.skidCEBAS = skidCEBAS;
        this.zksnrETM = zksnrETM;
        this.skidETM = skidETM;
        this.category = category;
    }

    public static toModel(form: UntypedFormGroup): ZKERequest {
        const zkeRequest = new ZKERequest();
        zkeRequest.vin = form.controls.vin.value || '';
        zkeRequest.zksnrCEBAS = form.controls.zksnrCEBAS.value;
        zkeRequest.skidCEBAS = form.controls.skidCEBAS.value;
        zkeRequest.zksnrETM = form.controls.zksnrETM.value;
        zkeRequest.skidETM = form.controls.skidETM.value;
        zkeRequest.category = form.controls.sparte.value || '';
        return zkeRequest;
    }
}
