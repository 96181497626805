<div class="card calculate-container">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <span class="card-title">Image auswählen</span>
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <label for="calculationInstanceSelector">Image *</label>
        <shared-calculate-instance-selector id="calculationInstanceSelector" [instances]="calculationInstances"
                                            (selectedInstance)="setSelectedInstance($event)">
        </shared-calculate-instance-selector>
      </div>
    </div>
  </div>
</div>
