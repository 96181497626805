import { Injectable } from '@angular/core';
import { System } from '@shared/models/system';

@Injectable({
    providedIn: 'root',
})
export class SystemsService {
    private systems: System[] = [new System('Xentry'), new System('MRS')];

    constructor() {}

    getSystems(): System[] {
        return this.systems;
    }
}
