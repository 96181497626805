import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Datapool, isTestDataPool } from '@shared/models/datapool/datapool';
import { UserDatapool } from '@shared/models/datapool/user-datapool';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';
import { ModalResult } from '@shared/models/modal-result';
import { ApiService } from '@shared/services/api/api.service';
import { UserDataPoolService } from '@shared/services/datapool/userDataPool/user-datapool.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ImageManagementService } from '../services/image-management/image-management.service';

@Component({
    selector: 'app-create-image',
    templateUrl: './create-image.component.html',
    styleUrls: ['./create-image.component.scss'],
})
export class CreateImageComponent implements OnInit, OnDestroy {
    @Input() dataPool: Datapool;
    @Input() dataPoolType: string;

    form: UntypedFormGroup;
    isLoading = false;
    text = 'Das Image wird gerade erstellt';
    data: UserDatapool[];
    sortConfig = [{ prop: 'userFriendlyName', dir: 'desc' }];
    selected = [];
    selectionType = SelectionType.single;
    ROOT_URL: string;

    private notifier = new Subject();
    private switchSetting: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        private imageAdministrationService: ImageManagementService,
        private userDataPoolService: UserDataPoolService,
        private notificationService: ErrorHandlerService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
                if (this.showSelectDatapoolTable()) {
                    this.getUserDataPools();
                }
            });

        this.form = this.formBuilder.group({
            note: ['', Validators.maxLength(255)],
        });
    }

    getUserDataPools() {
        this.userDataPoolService.getAllUserDataPools(this.ROOT_URL).subscribe(
            (dataPools: UserDatapool[]) => {
                this.data = dataPools.filter(
                    datapool =>
                        datapool.entitlement ===
                        (this.dataPoolType === 'Meine'
                            ? UserDatapoolEntitlement.OWNER
                            : UserDatapoolEntitlement.WRITE)
                );

                if (this.data.length > 0) {
                    // set default for radio button
                    this.data[0].checked = true;
                }
            },
            error => {}
        );
    }

    onSelect() {
        this.data.forEach(dataPool => (dataPool.checked = false));
        this.data.filter(
            data => data.userFriendlyName === this.selected[0].userFriendlyName
        )[0].checked = !this.selected[0].checked;

        this.selected = [];
    }

    create() {
        this.isLoading = true;
        const dataPoolIdentifier =
            this.data && this.data.length > 0
                ? this.data[this.data.findIndex(datapool => datapool.checked)]
                      .dataPool
                : undefined;

        if (this.form.valid) {
            this.imageAdministrationService
                .createImage(
                    this.ROOT_URL,
                    this.dataPool,
                    dataPoolIdentifier,
                    this.form.controls.note.value
                )
                .subscribe(
                    response => {
                        this.notificationService.showSuccess(
                            'Erfolgreich',
                            'Das Erstellen eines Images wurde gestartet.'
                        );
                        this.activeModal.close(ModalResult.CREATE_IMAGE_OK);
                        this.isLoading = false;
                    },
                    error => {
                        this.isLoading = false;
                        this.activeModal.close(ModalResult.CREATE_IMAGE_FAILED);
                    }
                );
        }
    }

    disableCreate(): boolean {
        return (
            this.showSelectDatapoolTable() &&
            this.data &&
            this.data.length === 0
        );
    }

    getHeadline(): string {
        return this.dataPool.substr(0, this.dataPool.indexOf('_'));
    }

    showSelectDatapoolTable(): boolean {
        return isTestDataPool(this.dataPool);
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    inputIsInvalid(control: string): boolean {
        return this.form.get(control).errors && this.form.get(control).touched;
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
