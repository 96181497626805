import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDatapool } from '@shared/models/datapool/user-datapool';
import { UserDataPoolEntitlementEntry } from '@shared/models/datapool/user-datapool-entitlement';
import { ApiService } from '@shared/services/api/api.service';
import { UserDataPoolEntitlementService } from '@shared/services/datapool/userDataPool/user-datapool-entitlement/user-datapool-entitlement.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-entitlements-modal',
    templateUrl: './entitlements-modal.component.html',
})
export class EntitlementsModalComponent implements OnInit, OnDestroy {
    @Input() datapool: UserDatapool;
    sortConfig = [{ prop: 'username', dir: 'asc' }];
    data: UserDataPoolEntitlementEntry[];
    form: UntypedFormGroup;
    ROOT_URL: string;

    private notifier = new Subject();
    private switchSetting: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        private notificationService: ErrorHandlerService,
        private userDataPoolEntitlementService: UserDataPoolEntitlementService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
            });

        this.form = this.formBuilder.group({
            username: [''],
        });

        this.getUserDataPoolEntitlementEntries();
    }

    saveNewEntitlementEntry() {
        if (this.isUsernameNotEmpty() && this.isUsernameNotExisting()) {
            this.userDataPoolEntitlementService
                .createNewUserDataPoolEntitlements(
                    this.ROOT_URL,
                    this.datapool.dataPool,
                    this.form.controls.username.value.toUpperCase()
                )
                .subscribe(
                    response => {
                        this.notificationService.showSuccess(
                            'Erfolgreich',
                            `Der Benutzer ${this.form.controls.username.value.toUpperCase()} hat Vollzugriff auf den Datenpool ${
                                this.datapool.userFriendlyName
                            } erhalten!`
                        );
                        this.getUserDataPoolEntitlementEntries();
                        this.form.reset();
                    },
                    error => {
                        this.notificationService.showError(
                            'Fehler',
                            `Der Benutzer ${this.form.controls.username.value.toUpperCase()} konnte keinen Zugriff erhalten!`
                        );
                    }
                );
        } else {
            this.notificationService.showError(
                'Fehler',
                `Der Benutzer ${this.form.controls.username.value.toUpperCase()} verfügt bereits über einen Vollzugriff!`
            );
        }
    }

    deleteWriteEntitlement(entry: UserDataPoolEntitlementEntry) {
        this.userDataPoolEntitlementService
            .deleteSelectedUserDataPoolEntitlements(
                this.ROOT_URL,
                this.datapool.dataPool,
                entry.username
            )
            .subscribe(
                response => {
                    this.notificationService.showSuccess(
                        'Erfolgreich',
                        `Dem Benutzer ${entry.username} wurden der Vollzugriff auf den Datenpool ${this.datapool.userFriendlyName} entzogen.`
                    );

                    this.getUserDataPoolEntitlementEntries();
                },
                error => {
                    this.notificationService.showError(
                        'Fehler',
                        `Das Entfernen des Vollzugriffs für den Benutzer ${entry.username} ist fehlgeschlagen.`
                    );
                }
            );
    }

    isUsernameNotEmpty(): boolean {
        return this.form.controls.username.value !== '';
    }

    isUsernameNotExisting(): boolean {
        return (
            this.data.findIndex(
                entry =>
                    entry.username.toUpperCase() ===
                    this.form.controls.username.value.toUpperCase()
            ) === -1
        );
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    private getUserDataPoolEntitlementEntries() {
        this.userDataPoolEntitlementService
            .getAllUserDataPoolEntitlements(
                this.ROOT_URL,
                this.datapool.dataPool
            )
            .subscribe((entries: UserDataPoolEntitlementEntry[]) => {
                this.data = entries;
            });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
