import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    constructor() {}

    setEnvironmentUrlViaSubdomain(subdomain: string): void {
        switch (subdomain) {
            case undefined:
                environment.production = false;
                environment.baseUrl = 'http://localhost:4200';
                environment.logoutUrl = '/logout';
                environment.commonApiUrl = 'http://localhost:4200';
                environment.carApiUrl = 'http://localhost:4200';
                environment.vanApiUrl = 'http://localhost:4200';
                environment.zkeUrl = 'http://localhost:4200';
                environment.accessToken = ''; // Add your Access-Token for local development here...
                environment.refreshToken = ''; // Add your Access-Token for local development here...
                break;

            default:
                const env = subdomain === "zke" ? "" : "-int"
                environment.production = true; //Non-local Environment
                environment.baseUrl =
                    'https://api.' + subdomain + '.vus.corpinter.net';
                environment.logoutUrl = 'https://sso' + env + '.mercedes-benz.com';
                environment.commonApiUrl =
                    'https://api.' + subdomain + '.vus.corpinter.net';
                environment.carApiUrl =
                    'https://api.' + subdomain + '.vus.corpinter.net';
                environment.vanApiUrl =
                    'https://api.' + subdomain + '.vus.corpinter.net';
                environment.zkeUrl = 'https://ui.' + subdomain + '.vus.corpinter.net';
                environment.accessToken = ''; // Add your Access-Token for local development here...
                environment.refreshToken = ''; // Add your Refresh-Token for local development here...
                break;
        }
    }
}
