import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ProductiveImageService {
    private refreshStatus = new EventEmitter<boolean>();

    constructor() {}

    refreshImageStatus(): void {
        this.refreshStatus.emit(true);
    }

    refreshImageSubscriber() {
        return this.refreshStatus;
    }
}
