import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';

import { ProductiveImageComponent } from './productive-image.component';

@NgModule({
    declarations: [ProductiveImageComponent],
    imports: [CommonModule, FontAwesomeModule, LoadingSpinnerModule],
    exports: [ProductiveImageComponent],
})
export class ProductiveImageModule {}
