import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { NgxDataTablePage, NgxDataTableSorts, Page } from '@shared/models/page';
import { SmoketestSettingPage } from '@shared/models/smoketest/smoketest-setting-page';
import { ZertArt } from '@shared/models/smoketest/zert-art';
import { DatatableComponent, SortType } from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-smoketest-table',
    templateUrl: './smoketest-table.component.html',
    styleUrls: ['./smoketest-table.component.scss'],
})
export class SmoketestTableComponent {
    @ViewChild('smokeTestOverviewTable', { static: true })
    table: DatatableComponent;
    @Input()
    isLoading: boolean;
    @Input()
    data: SmoketestSettingPage;
    @Output()
    detail = new EventEmitter<number>();
    @Output()
    delete = new EventEmitter<number>();
    @Output()
    paginationChange = new EventEmitter<Page>();

    pagination = new Page(0, 10, 'baureihe,asc');
    sortConfig = [{ prop: 'baureihe', dir: 'asc' }];
    sortType = SortType.single;

    deleteEntry(index: number) {
        this.delete.emit(index);
    }

    openDetails(index: number) {
        this.detail.emit(index);
    }

    paginate(page: NgxDataTablePage) {
        this.pagination.page = page.offset;
        this.paginationChange.emit(this.pagination);
    }

    sort(sort: NgxDataTableSorts) {
        this.pagination.sort = `${sort.sorts[0].prop},${sort.sorts[0].dir}`;
        this.paginationChange.emit(this.pagination);
    }

    size(size: number) {
        this.pagination.size = size;
        this.paginationChange.emit(this.pagination);
    }

    protected readonly ZertArt = ZertArt;
}
