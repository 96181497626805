import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { CHECKBOX_VALUE_ACCESSOR } from './toggle-button.accessor';

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss'],
    providers: [CHECKBOX_VALUE_ACCESSOR],
})
export class ToggleButtonComponent implements ControlValueAccessor {
    @Output() changed = new EventEmitter<boolean>();
    @Input() status: boolean;
    @Input() disabled: boolean;
    @Input() rowIndex: number;
    onTouched: () => void;

    constructor() {}

    onChange = (_: any) => {};

    writeValue(status: any): void {
        this.status = status;
    }

    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onStatusChange(status: boolean): void {
        this.changed.emit(status);
        this.onChange(status);
    }

    getId(): string {
        return `toggle-button-${this.rowIndex}`;
    }
}
