import { Component, OnDestroy, OnInit } from '@angular/core';
import { DIVISIONS } from '@shared/constants/division';
import { AutoDeployment } from '@shared/models/auto-deployment';
import { ApiService } from '@shared/services/api/api.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { ZkePkwAutoDeploymentService } from '@shared/services/settings/zke-pkw-autoDeployment.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-productive-setting',
    templateUrl: './productive-setting.component.html',
    styleUrls: ['./productive-setting.component.scss'],
})
export class ProductiveSettingComponent implements OnInit, OnDestroy {
    autoDeployment: AutoDeployment;
    division: string;

    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();

    constructor(
        private autoDeploymentService: ZkePkwAutoDeploymentService,
        private notificationService: ErrorHandlerService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
                this.division = DIVISIONS[data];
                this.getAutoDeployment();
            });
    }

    updateAutoDeployment(status: boolean): void {
        if (this.autoDeployment != null) {
            this.autoDeployment.enabled = status;
            this.autoDeploymentService
                .updateAutoDeployment(this.ROOT_URL, this.autoDeployment)
                .subscribe(
                    response => {
                        this.showMessage('Erfolgreich', 'geändert');
                    },
                    error => {
                        this.showMessage('Fehler', 'geändert');
                    }
                );
        } else {
            this.showMessage('Fehler', 'geändert');
        }
    }

    private getAutoDeployment(): void {
        this.autoDeploymentService.getAutoDeployment(this.ROOT_URL).subscribe(
            (autoDeployment: AutoDeployment) => {
                this.autoDeployment = autoDeployment;
            },
            error => {
                this.showMessage('Fehler', 'geladen');
            }
        );
    }

    private showMessage(type: string, action: string) {
        if (type === 'Fehler') {
            this.notificationService.showError(
                `${type}`,
                `Die Einstellungen zum automatischen Deployment von AFM konnten nicht ${action} werden.`
            );
        } else if (type === 'Erfolgreich') {
            this.notificationService.showSuccess(
                `${type}`,
                `Die Einstellungen zum automatischen Deployment von AFM wurden erfolgreich ${action}.`
            );
        }
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
