import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import {
    UserDataPoolEntitlementEntry,
    UserDataPoolEntitlementEntryAdapter,
    UserDatapoolEntitlement,
} from '@shared/models/datapool/user-datapool-entitlement';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserDataPoolEntitlementService {
    constructor(
        private http: HttpClient,
        private adapter: UserDataPoolEntitlementEntryAdapter
    ) {}

    getAllUserDataPoolEntitlements(
        url: string,
        dataPoolName: string
    ): Observable<UserDataPoolEntitlementEntry[]> {
        return this.http
            .get(
                `${url}/${apiPaths.dataManager}/api/user-datapool/entitlement/${dataPoolName}`
            )
            .pipe(
                map((data: UserDataPoolEntitlementEntry[]) =>
                    data.map(item => this.adapter.adapt(item))
                )
            );
    }

    createNewUserDataPoolEntitlements(
        url: string,
        dataPoolName: string,
        userName: string
    ) {
        return this.saveUserDataPoolEntitlement(
            url,
            dataPoolName,
            userName,
            UserDatapoolEntitlement.WRITE
        );
    }

    // No Delete-API for DataPoolEntitlement - Use saveNewUserDataPoolEntitlements with "entitlement=READ"
    deleteSelectedUserDataPoolEntitlements(
        url: string,
        dataPoolName: string,
        userName: string
    ) {
        return this.saveUserDataPoolEntitlement(
            url,
            dataPoolName,
            userName,
            UserDatapoolEntitlement.READ
        );
    }

    private saveUserDataPoolEntitlement(
        url: string,
        dataPoolName: string,
        userName: string,
        entitlement: UserDatapoolEntitlement
    ) {
        return this.http.put(
            `${url}/${apiPaths.dataManager}/api/user-datapool/entitlement?dataPool=${dataPoolName}&entitlement=${entitlement}&userName=${userName}`,
            {
                observe: 'response',
            }
        );
    }
}
