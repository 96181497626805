import { Component, OnInit } from '@angular/core';
import { SecurityService } from '@shared/services/security/security.service';

@Component({
    selector: 'app-forbidden-page',
    templateUrl: './forbidden-page.component.html',
    styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent implements OnInit {
    isAuthorized;

    constructor(private securityService: SecurityService) {}

    ngOnInit() {
        this.isAuthorized =
            this.securityService.user && this.securityService.user.upn;
    }
}
