import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { UserManual } from '@shared/models/user-manual';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserManualService {
    constructor(private http: HttpClient) {}

    public getPreSignedUrl(url: string): Observable<UserManual> {
        return this.http.get<UserManual>(`${url}/${apiPaths.userManualUrl}`);
    }

    public getExportFileFromPresignedUrl(url: string) {
        return this.http.get(url, {
            responseType: 'blob',
        });
    }
}
