import { Component } from '@angular/core';
import { permissions } from '@shared/constants/permissions';
import { Datapool } from '@shared/models/datapool/datapool';
import { RoleConfig } from '@shared/models/role-config';

@Component({
    selector: 'app-test-images',
    templateUrl: './test-images.component.html',
    styleUrls: ['./test-images.component.scss'],
})
export class TestImagesComponent {
    dataPool = Datapool.TEST;
    roleConfig: RoleConfig = {
        description: 'WRITE',
        roles: [
            permissions.PKW.IMAGE_TEST_WRITE,
            permissions.VAN.IMAGE_TEST_WRITE,
        ],
    };

    constructor() {}
}
