import { Component } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { routes } from 'src/app/app-routing.module';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    menu: Routes = routes;

    constructor(private router: Router) {}

    isActiveMenuItem(url: string): string {
        return url === this.router.url ||
            url ===
                this.router.url.substring(0, this.router.url.lastIndexOf('/'))
            ? 'menu-item-active'
            : '';
    }

    navigateByUrl(url: string) {
        return this.router.navigateByUrl(url);
    }
}
