import { AbstractControl } from '@angular/forms';

export function vinValidator(
    control: AbstractControl
): {
    [key: string]: any;
} | null {
    const validVin = /^[0-9A-HJ-NPR-Z]{17}/.test(control.value);
    return validVin ? null : { invalidVin: { value: control.value } };
}
