<div class="vus-modal">
    <div class="modal-header close-top-right">
        <h3 class="modal-title">{{headline}}</h3>
        <button *ngIf="closingOption" type="button" class="btn close" aria-label="Close" (click)="dismiss()">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>

    <div class="modal-body" [innerHtml]="message"></div>

    <div class="modal-footer">
        <div *ngIf="!futureFeature" class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" (click)="dismiss()">
                <span>{{buttonNegative}}</span>
            </button>
            <button type="button" class="btn btn-primary" (click)="close()">
                <span>{{buttonPositive}}</span>
            </button>
        </div>
        <div *ngIf="futureFeature" class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary" (click)="close()">
                <span>OK</span>
            </button>
        </div>
    </div>
</div>
