import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { Datapool, isSerienDataPool } from '@shared/models/datapool/datapool';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';
import { ImportMetadata } from '@shared/models/import-metadata';
import { ModalResult } from '@shared/models/modal-result';
import { ApiService } from '@shared/services/api/api.service';
import { SelectedUserDataPoolService } from '@shared/services/datapool/userDataPool/selected-user-datapool/selected-user-datapool.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { AbstractMetadataService } from '@shared/services/metadata/abstract-metadata.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MetadataRoleConfig } from './metadata-role-config';

@Component({
    selector: 'app-metadata-table',
    templateUrl: 'metadata-table.component.html',
})
export class MetadataTableComponent implements OnInit, OnDestroy {
    @ViewChild('dataTable', { static: true }) table: DatatableComponent;
    @Input() dataPoolEntitlement = UserDatapoolEntitlement.WRITE;
    @Input() fileType;
    @Input() dataPool: Datapool;

    sortConfig = [{ prop: 'created', dir: 'desc' }];
    requiredWriteRole: string[];
    data: ImportMetadata[];
    dataTableLimit = 10;

    private roleConfig = new MetadataRoleConfig();
    private dataPoolIdentifier: string;
    private ROOT_URL: string;
    private notifier = new Subject();
    private switchSetting: string;

    constructor(
        private downloadService: DownloadService,
        private modalService: NgbModal,
        private notificationService: ErrorHandlerService,
        private metadataService: AbstractMetadataService,
        private selectedUserDataPoolService: SelectedUserDataPoolService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
            });

        if (this.dataPool) {
            if (this.dataPool === Datapool.TEST) {
                this.setDataPoolIdentifier();
            }

            this.initializeRoles(this.dataPool);
            this.getMetadataList();
        }
    }

    setDataPoolIdentifier() {
        this.selectedUserDataPoolService.sharedDataPool.subscribe(
            selectedDataPool => {
                this.dataPoolIdentifier = selectedDataPool.dataPool;
            }
        );
    }

    getMetadataList() {
        if (this.dataPool) {
            this.metadataService
                .getAllMetadata(
                    this.ROOT_URL,
                    this.dataPool,
                    this.fileType,
                    this.dataPoolIdentifier
                )
                .subscribe(
                    (metadata: ImportMetadata[]) => {
                        this.data = this.translateDataPool(metadata);
                    },
                    error => {
                        this.data = [];
                    }
                );
        }
    }

    initializeRoles(dataPool) {
        const mappedWriteRoles = this.mapRoles(
            this.roleConfig.writeRoleConfig,
            dataPool
        );

        this.requiredWriteRole = mappedWriteRoles
            ? mappedWriteRoles[0].roles
            : [];
    }

    mapRoles(config, dataPool: Datapool) {
        return config.filter(i => i.dataPool === dataPool);
    }

    isDeletable(): boolean {
        return this.isModifiable();
    }

    hasBaureihe(): boolean {
        // display if there is no data, as this is a column we consider as default
        if (!this.data) {
            return true;
        }
        return this.data && this.data.some(item => item.baureihe !== undefined);
    }

    hasReferenceName(): boolean {
        return this.data && this.data.some(item => item.fileRef !== undefined);
    }

    deleteMetadata(metadata: ImportMetadata) {
        if (!this.isDeletable()) {
            return;
        }

        const modalRef = this.modalService.open(WarningComponent);
        modalRef.componentInstance.closingOption = false;
        modalRef.componentInstance.message =
            '<p>Wollen sie die ausgewählten Daten wirklich unwiderruflich löschen?</p>';
        modalRef.result.then(
            reason => {
                if (reason === ModalResult.WARNING_OK) {
                    this.metadataService
                        .deleteMetadata(this.ROOT_URL, metadata)
                        .subscribe(
                            (res: Response) => {
                                this.getMetadataList();
                                this.notificationService.showSuccess(
                                    'Erfolgreich',
                                    'Die Datei wurde erfolgreich gelöscht'
                                );
                            },
                            error => {
                                this.notificationService.showError(
                                    'Fehler',
                                    'Die Datei konnte nicht gelöscht werden'
                                );
                            }
                        );
                }
            },
            error => {
                // needs to be there that the dismiss ends not with an error
            }
        );
    }

    exportFile(metadata: ImportMetadata) {
        this.metadataService
            .getExportFile(this.ROOT_URL, metadata, this.dataPool)
            .subscribe((response: HttpResponse<Blob>) =>
                this.downloadService.downloadFile(response)
            );
    }

    hasUserEntitlement(): boolean {
        return this.dataPoolEntitlement !== UserDatapoolEntitlement.READ;
    }

    setLimit(event) {
        this.dataTableLimit = event;

        setTimeout(() => {
            this.table.limit = this.dataTableLimit;
            this.table.recalculate();
        });
    }

    private translateDataPool(
        metadataList: ImportMetadata[]
    ): ImportMetadata[] {
        if (isSerienDataPool(this.dataPool)) {
            metadataList.forEach(metadata => {
                metadata.dataPool = 'SERIEN_DATEN';
            });
        }
        return metadataList;
    }

    private isModifiable(): boolean {
        return !(this.dataPool && isSerienDataPool(this.dataPool));
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
