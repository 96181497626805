<div class="card calculate-container response-container">
    <div class="card-body">
        <div class="card-title">ZKE Antwort </div>
        <div class="status" *ngIf="code">Http Status: {{code}}<span *ngIf="code" [ngStyle]="statusColor"
                class="dot"></span>
        </div>
        <app-hint *ngIf="showHint$ | async" [hint]="hint"></app-hint>
        <pre *ngIf="zkeResponse">{{zkeResponse | xml}}</pre>
    </div>
</div>
