import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
    @Input() status: boolean;
    @Output() changed = new EventEmitter<boolean>();

    constructor() {}

    onStatusChange(status: boolean): void {
        this.changed.emit(status);
    }
}
