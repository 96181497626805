import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

enum DataTableType {
    PAGE = 'Page',
    MODAL = 'Modal',
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'custom-table-footer',
    templateUrl: './custom-table-footer.component.html',
    styleUrls: ['./custom-table-footer.component.scss'],
})
export class CustomTableFooterComponent implements OnInit {
    @Output() setLimit = new EventEmitter<number>();
    @Input() dataTableType: string = DataTableType.PAGE;
    @Input() rowCount: number;
    @Input() element = 'Zeilen';
    @Input() customLimits = [];
    @Input() inititialIndexPage = 0;
    @Input() inititialIndexModal = 1;

    activeLimit;
    visibleLimits = [];

    private pageLimits = [10, 30, 100];
    private modalLimits = [5, 10, 30];

    constructor() {}

    ngOnInit() {
        this.setLimits();
    }

    setActiveLimit(limit: number) {
        this.activeLimit = limit;
        this.setLimit.emit(limit);
    }

    isLastLimitOfArray(limit: number): boolean {
        return limit === this.visibleLimits[this.visibleLimits.length - 1];
    }

    isLimitActiveLimit(limit: number): boolean {
        return this.activeLimit === limit;
    }

    private setLimits() {
        this.visibleLimits =
            this.customLimits.length > 0
                ? this.customLimits
                : this.isDataTableTypePage()
                ? this.pageLimits
                : this.modalLimits;

        this.setActiveLimit(
            this.visibleLimits[
                this.isDataTableTypePage()
                    ? this.inititialIndexPage
                    : this.inititialIndexModal
            ]
        );
    }

    private isDataTableTypePage(): boolean {
        return this.dataTableType === DataTableType.PAGE;
    }
}
