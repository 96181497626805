<div class="notification-management">
    <h3 class="vus-page-title">Benachrichtigungen</h3>
    <p class="vus-page-description">Verwalten Sie hier, welche Nutzer die Fehler-Benachrichtigungen per E-Mail erhalten sollen.</p>

    <div class="card vus-card">
        <div class="card-body vus-card-body">
            <div class="vus-title">
                <span>Simple Notification Service Topics</span>

            </div>
            <app-loading-spinner *ngIf="isLoading" [text]="'Loading'" [overlay]="true"></app-loading-spinner>
            <app-notification-table [topics]="topics" [isLoading]="isLoading" (detail)="detail($event)"></app-notification-table>
        </div>
    </div>
</div>
