import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    constructor(private toastr: ToastrService) {}

    showError(errorType: string, errorMessage: string) {
        this.toastr.error(errorMessage, errorType, {
            timeOut: 0,
            extendedTimeOut: 0,
        });
    }

    showSuccess(errorType: string, errorMessage: string) {
        this.toastr.success(errorMessage, errorType);
    }
}
