<!-- Type Row -->
<button [disabled]="disabled" *ngIf="isButtonTypeRow() && visible" class="option-button" (click)="clickAction()"
        ngbTooltip="{{toolTipDescription}}" [openDelay]="1000" container="body">
    <fa-icon icon="{{iconSelector}}"></fa-icon>
</button>

<button *ngIf="isButtonTypeRow() && !visible" class="option-button invisible">
</button>

<!-- Type Checkbox -->
<button [disabled]="disabled" *ngIf="isButtonTypeCheckbox()" class="option-button" (click)="clickAction()">
    <fa-icon *ngIf="!toggle" icon="square"></fa-icon>
    <fa-icon *ngIf="toggle" icon="check-square"></fa-icon>
</button>

<!-- Type Radio -->
<button [disabled]="disabled" *ngIf="isButtonTypeRadio()" class="option-button" (click)="clickAction()">
    <fa-icon *ngIf="!toggle" icon="circle" class="radio"></fa-icon>
    <fa-icon *ngIf="toggle" icon="dot-circle" class="radio"></fa-icon>
</button>

<!-- Type Expander -->
<a *ngIf="isButtonTypeExpander()" class="toggle-icon" href="javascript:void(0)" ngbTooltip="{{toolTipDescription}}"
   [openDelay]="1000" (click)="clickAction()" container="body">
    <fa-icon *ngIf="!toggle" icon="angle-down"></fa-icon>
    <fa-icon *ngIf="toggle" icon="angle-up"></fa-icon>
</a>

<!-- Type Page -->
<button *ngIf="iconSelector && isButtonTypePage()" [disabled]="disabled" (click)="clickAction()"
        class="btn btn-primary round-button">
    <fa-icon icon="{{iconSelector}}"></fa-icon>
</button>
