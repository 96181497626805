export const permissions = {
    // User Group - PKW
    PKW: {
        // Menu - Expert Tool
        CALCULATE_TEST_WRITE: 'VUS.ZKE_PKW_CALCULATE_TEST_WRITE',
        CALCULATE_VORAB_WRITE: 'VUS.ZKE_PKW_CALCULATE_VORAB_WRITE',
        CALCULATE_SERIEN_WRITE: 'VUS.ZKE_PKW_CALCULATE_SERIEN_WRITE',

        // Menu - Data Management
        DATA_TEST_READ: 'VUS.ZKE_PKW_DATA_TEST_READ',
        DATA_TEST_WRITE: 'VUS.ZKE_PKW_DATA_TEST_WRITE',
        DATA_VORAB_READ: 'VUS.ZKE_PKW_DATA_VORAB_READ',
        DATA_VORAB_WRITE: 'VUS.ZKE_PKW_DATA_VORAB_WRITE',
        DATA_SERIEN_READ: 'VUS.ZKE_PKW_DATA_SERIEN_READ',
        DATA_SERIEN_WRITE: 'VUS.ZKE_PKW_DATA_SERIEN_WRITE',

        // Menu - Image Management
        IMAGE_TEST_READ: 'VUS.ZKE_PKW_IMAGE_TEST_READ',
        IMAGE_TEST_WRITE: 'VUS.ZKE_PKW_IMAGE_TEST_WRITE',
        IMAGE_VORAB_READ: 'VUS.ZKE_PKW_IMAGE_VORAB_READ',
        IMAGE_VORAB_WRITE: 'VUS.ZKE_PKW_IMAGE_VORAB_WRITE',
        IMAGE_SERIEN_READ: 'VUS.ZKE_PKW_IMAGE_SERIEN_READ',
        IMAGE_SERIEN_WRITE: 'VUS.ZKE_PKW_IMAGE_SERIEN_WRITE',

        // Menu - Settings
        SETTINGS_AUTODEPLOY_READ: 'VUS.ZKE_PKW_SETTINGS_AUTODEPLOY_READ',
        SETTINGS_AUTODEPLOY_WRITE: 'VUS.ZKE_PKW_SETTINGS_AUTODEPLOY_WRITE',
        SETTINGS_LOGLEVEL_READ: 'VUS.ZKE_PKW_SETTINGS_LOGLEVEL_READ',
        SETTINGS_LOGLEVEL_WRITE: 'VUS.ZKE_PKW_SETTINGS_LOGLEVEL_WRITE',
        SETTINGS_SMOKETESTDATA_READ: 'VUS.ZKE_PKW_SETTINGS_SMOKETESTDATA_READ',
        SETTINGS_SMOKETESTDATA_WRITE:
            'VUS.ZKE_PKW_SETTINGS_SMOKETESTDATA_WRITE',
        SETTINGS_SNS_READ: 'VUS.ZKE_PKW_SETTINGS_SNS_READ',
        SETTINGS_SNS_WRITE: 'VUS.ZKE_PKW_SETTINGS_SNS_WRITE',
        TESTINGBUTTON: 'VUS.ZKE_TestingButton',
    },
    // User Group - VAN
    VAN: {
        // Menu - Expert Tool
        CALCULATE_TEST_WRITE: 'VUS.ZKE_VAN_CALCULATE_TEST_WRITE',
        CALCULATE_VORAB_WRITE: 'VUS.ZKE_VAN_CALCULATE_VORAB_WRITE',
        CALCULATE_SERIEN_WRITE: 'VUS.ZKE_VAN_CALCULATE_SERIEN_WRITE',

        // Menu - Data Management
        DATA_TEST_READ: 'VUS.ZKE_VAN_DATA_TEST_READ',
        DATA_TEST_WRITE: 'VUS.ZKE_VAN_DATA_TEST_WRITE',
        DATA_VORAB_READ: 'VUS.ZKE_VAN_DATA_VORAB_READ',
        DATA_VORAB_WRITE: 'VUS.ZKE_VAN_DATA_VORAB_WRITE',
        DATA_SERIEN_READ: 'VUS.ZKE_VAN_DATA_SERIEN_READ',
        DATA_SERIEN_WRITE: 'VUS.ZKE_VAN_DATA_SERIEN_WRITE',

        // Menu - Image Management
        IMAGE_TEST_READ: 'VUS.ZKE_VAN_IMAGE_TEST_READ',
        IMAGE_TEST_WRITE: 'VUS.ZKE_VAN_IMAGE_TEST_WRITE',
        IMAGE_VORAB_READ: 'VUS.ZKE_VAN_IMAGE_VORAB_READ',
        IMAGE_VORAB_WRITE: 'VUS.ZKE_VAN_IMAGE_VORAB_WRITE',
        IMAGE_SERIEN_READ: 'VUS.ZKE_VAN_IMAGE_SERIEN_READ',
        IMAGE_SERIEN_WRITE: 'VUS.ZKE_VAN_IMAGE_SERIEN_WRITE',

        // Menu - Settings
        SETTINGS_AUTODEPLOY_READ: 'VUS.ZKE_VAN_SETTINGS_AUTODEPLOY_READ',
        SETTINGS_AUTODEPLOY_WRITE: 'VUS.ZKE_VAN_SETTINGS_AUTODEPLOY_WRITE',
        SETTINGS_LOGLEVEL_READ: 'VUS.ZKE_VAN_SETTINGS_LOGLEVEL_READ',
        SETTINGS_LOGLEVEL_WRITE: 'VUS.ZKE_VAN_SETTINGS_LOGLEVEL_WRITE',
        SETTINGS_SMOKETESTDATA_READ: 'VUS.ZKE_VAN_SETTINGS_SMOKETESTDATA_READ',
        SETTINGS_SMOKETESTDATA_WRITE:
            'VUS.ZKE_VAN_SETTINGS_SMOKETESTDATA_WRITE',
        SETTINGS_SNS_READ: 'VUS.ZKE_VAN_SETTINGS_SNS_READ',
        SETTINGS_SNS_WRITE: 'VUS.ZKE_VAN_SETTINGS_SNS_WRITE',
    },
};
