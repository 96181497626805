import { Injectable } from '@angular/core';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';

export class UserDatapool {
    constructor(
        // TO-DO: Wait for Backend-Property "changedAt"
        public dataPool: string,
        public userFriendlyName: string,
        public note: string,
        public owner: string,
        public createdAt: Date,
        public entitlement: UserDatapoolEntitlement,
        public name: string,
        public checked?: boolean
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class UserDatapoolAdapter {
    adapt(item: any): UserDatapool {
        return new UserDatapool(
            item.dataPool || undefined,
            item.userFriendlyName || undefined,
            item.note || undefined,
            item.owner || undefined,
            item.createdAt ? new Date(item.createdAt) : undefined,
            item.entitlement || undefined,
            item.name || undefined
        );
    }
}
