import { ZertArt } from '@shared/models/smoketest/zert-art';

export class SmoketestSetting {
    constructor(
        public fin: string,
        public baureihe: string,
        public ttz: string,
        public werkskennung: number,
        public codes: string,
        public zertArt: ZertArt,
        public skipable : boolean
    ) {}
}
