import { Injectable } from '@angular/core';
import { Baureihe } from '@shared/models/baureihe';
import { Datapool } from '@shared/models/datapool/datapool';
import { FileType } from '@shared/models/filetype/file-type';
import { ImportMetadata } from '@shared/models/import-metadata';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export abstract class AbstractMetadataService {
    abstract getAllMetadata(
        url: string,
        dataPool: Datapool,
        fileType: FileType,
        dataPoolIdentifier?: string
    ): Observable<ImportMetadata[]>;

    abstract deleteMetadata(url: string, metadata: ImportMetadata);

    abstract getExportFile(
        url: string,
        metadata: ImportMetadata,
        dataPool: Datapool
    );

    abstract uploadFile(
        url: string,
        fileType: FileType,
        dataPool: Datapool,
        baureihe: Baureihe,
        sourceFileName: string,
        file: any,
        note: string,
        createdAt: number,
        dataPoolIdentifier?: string
    );
}
