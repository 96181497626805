<app-loading-spinner *ngIf="!isAuthorized" [overlay]="true" [text]="'Login...'"></app-loading-spinner>

<div *ngIf="isAuthorized" class="container">
    <div class="row">
        <div class="col-12">
            <div class="text-center mt-5">
                <h3>Sie besitzen keine Berechtigung, um diese Seite zu sehen</h3>
                <p>Weitere Informationen finden Sie unter
                    <a class="forbidden__link" href="https://pages.git.i.mercedes-benz.com/vus/home/flashwarecalculation/zke/">
                        https://pages.git.i.mercedes-benz.com/vus/home/flashwarecalculation/zke/</a>
                </p>
            </div>
        </div>
    </div>
</div>
