import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { VinlistFormularComponent } from './vinlist-formular.component';

@NgModule({
    declarations: [VinlistFormularComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbAlertModule],
    exports: [VinlistFormularComponent],
})
export class VinListFormularModule {}
