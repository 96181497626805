import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CODE_017_HINT } from '@shared/constants/app-constants';
import { ROLES } from '@shared/constants/roles';
import { UpdateRequest } from '@shared/interfaces/update-request';
import { VedocCalculationRequest } from '@shared/interfaces/vedoc-calculation-request';
import { AscMetadata } from '@shared/models/asc-metadata';
import { Division, getAllDivisions } from '@shared/models/division';
import { System } from '@shared/models/system';
import { VedocData } from '@shared/models/vedoc-data';
import { ZKERequest } from '@shared/models/zke-request';
import { Code17Service } from '@shared/services/code-17/code-17.service';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { SecurityService } from '@shared/services/security/security.service';
import { SystemsService } from '@shared/services/systems/systems.service';
import { ZkeService } from '@shared/services/zke/zke.service';
import { cebasSkidOrZkSnrValidator } from '@shared/validators/cebas-skid-or-zksnr-validator';
import { etmSkidOrZkSnrValidator } from '@shared/validators/etm-skid-or-zksnr-validator';
import { vinValidator } from '@shared/validators/vin-validator';
import { Observable, OperatorFunction, Subject, merge } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators';

@Component({
    selector: 'app-zke-request-formular',
    templateUrl: './zke-request-formular.component.html',
    styleUrls: ['./zke-request-formular.component.scss'],
})
export class ZkeRequestFormularComponent implements AfterViewChecked, OnInit {
    private static APP_PREFIX = 'EXP_';

    @Output() updateRequested = new EventEmitter<UpdateRequest>();
    @Output() calculationRequested = new EventEmitter<
        VedocCalculationRequest
    >();
    @Output() inputReset = new EventEmitter<any>();
    @Input() targetEndpoint = null;
    @Input() switchSetting;

    @ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;
    focusVin$ = new Subject<string>();
    clickVin$ = new Subject<string>();
    focusZksnrCebas$ = new Subject<string>();
    clickZksnrCebas$ = new Subject<string>();
    focusZksnrEtm$ = new Subject<string>();
    clickZksnrEtm$ = new Subject<string>();

    form: UntypedFormGroup;
    divisions: Division[];
    systems: System[];
    resetCounter = 0;
    savedVins: string[] = [];
    savedZksnrCebas: string[] = [];
    savedZksnrEtm: string[] = [];
    hint = CODE_017_HINT;
    showHint$: Observable<boolean>;

    private VIN_KEY = 'savedVins';
    private ZKSNR_CEBAS_KEY = 'savedZksnrCebas';
    private ZKSNR_ETM_KEY = 'savedZksnrEtm';
    private initialDivision: Division;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private zkeService: ZkeService,
        private systemService: SystemsService,
        private cdRef: ChangeDetectorRef,
        private localStorageService: LocalStorageService,
        private securityService: SecurityService,
        private code17Service: Code17Service
    ) {}

    ngOnInit(): void {
        this.loadLocalStorageDataByKey(this.VIN_KEY, 'savedVins');
        this.loadLocalStorageDataByKey(this.ZKSNR_CEBAS_KEY, 'savedZksnrCebas');
        this.loadLocalStorageDataByKey(this.ZKSNR_ETM_KEY, 'savedZksnrEtm');
        this.divisions = getAllDivisions();
        this.systems = this.systemService.getSystems();
        this.initialDivision = this.getInitialDivision();
        this.showHint$ = this.code17Service.showHint;

        this.form = this.formBuilder.group(
            {
                requestId: [''],
                sessionId: ['', [Validators.required]],
                vin: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(17),
                        Validators.maxLength(17),
                        vinValidator,
                    ],
                ],
                sparte: [this.initialDivision, [Validators.required]],
                anfragendesSystem: [
                    this.systems[0] ? this.systems[0].value : '',
                    [Validators.required],
                ],
                zksnrCEBAS: [''],
                skidCEBAS: [''],
                zksnrETM: [''],
                skidETM: [''],
                ttz: ['', [Validators.required]],
                codes: ['', [Validators.required]],
                fin: ['', [Validators.required]],
                manipulation: [true],
            },
            { validators: [etmSkidOrZkSnrValidator, cebasSkidOrZkSnrValidator] }
        );

        this.updateRequested.emit({
            zkeRequest: ZKERequest.toModel(this.form),
            metaData: this.getDefaultAscMetadata(),
        });
    }

    ngAfterViewChecked() {
        this.form.valueChanges.subscribe(() => {
            this.toggleInput();

            this.updateRequested.emit({
                zkeRequest: ZKERequest.toModel(this.form),
                metaData: this.getUpdatedAscMetadata(),
            });

            this.code17Service.showHint.next(
                this.form.controls.codes.value?.includes('017')
            );
        });

        // set dynamically the validator on/off (disabled = not relevant when submitting the form)
        this.form.controls.manipulation.valueChanges.subscribe(checked => {
            checked
                ? this.form.controls.fin.enable({ emitEvent: false })
                : this.form.controls.fin.disable({ emitEvent: false });
            checked
                ? this.form.controls.ttz.enable({ emitEvent: false })
                : this.form.controls.ttz.disable({ emitEvent: false });
            checked
                ? this.form.controls.codes.enable({ emitEvent: false })
                : this.form.controls.codes.disable({ emitEvent: false });
        });

        this.cdRef.detectChanges();
    }

    /**
     * Reset form (input fields)
     */
    resetInput() {
        this.form.reset();
        this.initialDivision = this.getInitialDivision();
        this.form.controls.sparte.setValue(this.initialDivision);
        this.form.controls.anfragendesSystem.setValue(
            this.systems[0] ? this.systems[0].value : ''
        );
        this.resetCounter++;
        this.enableZkSnrAndSkidInput();
        this.inputReset.emit([]);
    }

    skidAndZkSnrEmpty(): boolean {
        if (
            this.form.errors &&
            this.form.errors.skidAndZkSnrIsEmpty && //
            (this.form.controls.skidCEBAS.touched ||
                this.form.controls.zksnrCEBAS.touched)
        ) {
            return this.form.errors.skidAndZkSnrIsEmpty && this.form.touched;
        }
        return false;
    }

    skidIsInvalid(errorName: string, controlName: string): boolean {
        if (this.form.errors && this.form.errors[errorName]) {
            return (
                this.form.errors[errorName] &&
                this.form.controls[controlName].touched
            );
        }
        return false;
    }

    zkSnrIsInvalid(errorName: string, controlName: string): boolean {
        if (this.form.errors && this.form.errors[errorName]) {
            return (
                this.form.errors[errorName] &&
                this.form.controls[controlName].touched
            );
        }
        return false;
    }

    /**
     * Method to check, if input is valid or not. This is a solution, to keep the html clean...
     * @param control formcontrol name, which should be checked
     */
    inputIsInvalid(control: string): boolean {
        return this.form.get(control).errors && this.form.get(control).touched;
    }

    calculate() {
        this.markFormGroupTouched(this.form);
        if (this.form.valid) {
            if (this.form.controls.manipulation.value) {
                this.calculationRequested.emit({
                    fin: this.form.controls.fin.value,
                    ttz: this.form.controls.ttz.value,
                    codes: this.form.controls.codes.value,
                });
            } else {
                this.calculationRequested.emit(null);
            }
        }
    }

    fillFormWithSampleData() {
        console.log('konami!');
        this.form.controls.sessionId.setValue('1');
        this.form.controls.vin.setValue('WDD1770121J139043');
        this.form.controls.zksnrCEBAS.setValue('A0000000001');
    }

    requestVedocData() {
        this.form.controls.vin.markAsTouched();
        if (this.form.controls.vin.valid) {
            const vin = this.form.controls.vin.value;
            this.zkeService
                .getVedocData(vin)
                .subscribe((response: VedocData) => {
                    this.form.controls.ttz.setValue(response.ttz);
                    this.form.controls.fin.setValue(response.fin);
                    this.form.controls.codes.setValue(response.codes.join(','));
                });
        }
    }

    searchVin: OperatorFunction<string, readonly string[]> = (
        text$: Observable<string>
    ) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.clickVin$.pipe(
            filter(() => !this.instance?.isPopupOpen())
        );
        const inputFocus$ = this.focusVin$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term =>
                (term === ''
                    ? this.savedVins
                    : this.savedVins.filter(
                          v => v.toLowerCase().indexOf(term.toLowerCase()) > -1
                      )
                ).slice(0, 10)
            )
        );
    };

    searchZksnrCebas: OperatorFunction<string, readonly string[]> = (
        text$: Observable<string>
    ) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.clickZksnrCebas$.pipe(
            filter(() => !this.instance?.isPopupOpen())
        );
        const inputFocus$ = this.focusZksnrCebas$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term =>
                (term === ''
                    ? this.savedZksnrCebas
                    : this.savedZksnrCebas.filter(
                          v => v.toLowerCase().indexOf(term.toLowerCase()) > -1
                      )
                ).slice(0, 10)
            )
        );
    };

    searchZksnrEtm: OperatorFunction<string, readonly string[]> = (
        text$: Observable<string>
    ) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.clickZksnrEtm$.pipe(
            filter(() => !this.instance?.isPopupOpen())
        );
        const inputFocus$ = this.focusZksnrEtm$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term =>
                (term === ''
                    ? this.savedZksnrEtm
                    : this.savedZksnrEtm.filter(
                          v => v.toLowerCase().indexOf(term.toLowerCase()) > -1
                      )
                ).slice(0, 10)
            )
        );
    };

    handleVinChange() {
        if (this.form.controls.vin.valid) {
            this.saveDataInLocalStorage(
                this.VIN_KEY,
                this.form.controls.vin.value,
                'savedVins'
            );
        }
    }

    handleZksnrCebasChange() {
        if (
            this.form.controls.zksnrCEBAS.value &&
            !this.form.errors?.invalidZkSnrCEBAS
        ) {
            this.saveDataInLocalStorage(
                this.ZKSNR_CEBAS_KEY,
                this.form.controls.zksnrCEBAS.value,
                'savedZksnrCebas'
            );
        }
    }

    handleZksnrEtmChange() {
        if (
            this.form.controls.zksnrETM.value &&
            !this.form.errors?.invalidZkSnrETM
        ) {
            this.saveDataInLocalStorage(
                this.ZKSNR_ETM_KEY,
                this.form.controls.zksnrETM.value,
                'savedZksnrEtm'
            );
        }
    }

    /**
     * Only one of SKID or ZK-SNR input field can be active
     */
    private toggleInput() {
        // Use emitEvent: false to avoid triggering valueChange temporary
        this.form.controls.zksnrCEBAS.value
            ? this.form.controls.skidCEBAS.disable({ emitEvent: false })
            : this.form.controls.skidCEBAS.enable({ emitEvent: false });
        this.form.controls.skidCEBAS.value
            ? this.form.controls.zksnrCEBAS.disable({ emitEvent: false })
            : this.form.controls.zksnrCEBAS.enable({ emitEvent: false });
        this.form.controls.zksnrETM.value
            ? this.form.controls.skidETM.disable({ emitEvent: false })
            : this.form.controls.skidETM.enable({ emitEvent: false });
        this.form.controls.skidETM.value
            ? this.form.controls.zksnrETM.disable({ emitEvent: false })
            : this.form.controls.zksnrETM.enable({ emitEvent: false });
    }

    /**
     * Enable ZK-SNR and SKID Input fields
     */
    private enableZkSnrAndSkidInput() {
        this.form.controls.skidCEBAS.enable({ emitEvent: false });
        this.form.controls.zksnrCEBAS.enable({ emitEvent: false });
    }

    /**
     * Marks all controls in a form group as touched
     * @param formGroup - The form group to touch
     */
    private markFormGroupTouched(formGroup: UntypedFormGroup) {
        const values = Object.keys(formGroup.controls).map(
            e => formGroup.controls[e]
        );
        values.forEach((control: UntypedFormGroup) => {
            control.markAsTouched();
        });
    }

    private getDefaultAscMetadata(): AscMetadata {
        const ascMetadata = new AscMetadata();
        ascMetadata.application.name =
            ZkeRequestFormularComponent.APP_PREFIX +
            this.form.controls.anfragendesSystem.value;
        ascMetadata.application.version = '1.0.0';
        ascMetadata.user.id =
            this.form.controls.anfragendesSystem.value + '_USER_001';
        ascMetadata.destination.service = this.form.controls.anfragendesSystem.value;
        ascMetadata.destination.action = 'calculate';
        return ascMetadata;
    }

    /**
     * Get updated AscMetadata
     * Collect all necessary input values and store it in ascmetadata object
     */
    private getUpdatedAscMetadata(): AscMetadata {
        const ascMetadata = this.getDefaultAscMetadata();
        ascMetadata.requestID = this.form.controls.requestId.value;
        ascMetadata.sessionID = this.form.controls.sessionId.value;
        ascMetadata.application.name =
            ZkeRequestFormularComponent.APP_PREFIX +
            this.form.controls.anfragendesSystem.value;
        return ascMetadata;
    }

    private loadLocalStorageDataByKey(key: string, variableName: string) {
        const data = this.localStorageService.getItem(key);
        if (data && data.length !== 0) {
            this[variableName] = JSON.parse(data);
            this[variableName].reverse();
        } else {
            this.localStorageService.setItem(
                key,
                JSON.stringify(this[variableName] || [])
            );
        }
    }

    private saveDataInLocalStorage(
        key: string,
        entry: string,
        variableName: string
    ) {
        const dataString = this.localStorageService.getItem(key);
        if (dataString) {
            const savedData = JSON.parse(dataString);
            if (savedData) {
                const foundVin = savedData.find(i => i === entry);
                if (!foundVin) {
                    if (savedData && savedData.length >= 200) {
                        savedData.shift();
                    }
                    savedData.push(entry);
                } else {
                    const foundVinIndex = savedData.findIndex(i => i === entry);
                    if (foundVinIndex > -1) {
                        savedData.splice(foundVinIndex, 1);
                        savedData.push(entry);
                    }
                }
                this[variableName] = savedData;
                this.localStorageService.setItem(
                    key,
                    JSON.stringify(this[variableName])
                );
                this[variableName].reverse();
            } else {
                this.localStorageService.setItem(
                    key,
                    JSON.stringify(this[variableName])
                );
            }
        }
    }

    private getInitialDivision(): Division {
        if (this.securityService.isAdmin()) {
            return this.switchSetting === ROLES.PKW
                ? Division.PKW
                : Division.VAN;
        } else if (this.securityService.getUserRole() === ROLES.PKW) {
            return Division.PKW;
        } else {
            return Division.VAN;
        }
    }
}
