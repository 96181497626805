import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { CalculationInstance } from '@shared/models/calculation-instance';
import { ZkePkwCalculationInstancesService } from '@shared/services/calculation-instances/zkePkwCalculationInstance/zke-pkw-calculation-instance.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';

@Component({
    selector: 'app-image-selector',
    templateUrl: './image-selector.component.html',
    styleUrls: ['./image-selector.component.scss'],
})
export class ImageSelectorComponent implements OnInit {
    @Input() apiUrl: string;
    @Output() nameEmitter = new EventEmitter();
    @Output() didReceiveResponse = new EventEmitter<boolean>();
    calculationInstances: CalculationInstance[];
    form: UntypedFormGroup;
    

    constructor(
        private formBuilder: UntypedFormBuilder,
        private calculationInstancesService: ZkePkwCalculationInstancesService,
        private notificationService: ErrorHandlerService
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            calculationInstance: ['', [Validators.required]],
        });
        this.calculationInstancesService
            .getCalculationInstances(this.apiUrl)
            .subscribe(
                response => {
                    this.didReceiveResponse.emit(true);
                    this.calculationInstances = response;
                },
                error => {
                    this.didReceiveResponse.emit(true);
                    this.showMessage('Fehler', 'geladen');
                }
            );
    }

    setSelectedInstance(instance: CalculationInstance) {
        if (instance) {
            this.nameEmitter.emit(instance.name);
            this.form.controls.calculationInstance.setValue(instance);
        }
    }

    private showMessage(type: string, action: string) {
        this.notificationService.showError(
            `${type}`,
            `Die Images konnten nicht ${action} werden.`
        );
    }
}
