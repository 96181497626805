<div class="card calculate-container" appKonami (konami)="fillFormWithSampleData()">
    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <span class="card-title">Anfrage-Parameter</span>
            </div>
        </div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-2">
                    <label for="vin">VIN *</label>
                    <input
                        [ngbTypeahead]="searchVin"
                        (focus)="focusVin$.next($any($event).target.value)"
                        (click)="clickVin$.next($any($event).target.value)"
                        (blur)="handleVinChange()"
                        type="text"
                        autocomplete="off"
                        class="form-control vin-loader"
                        id="vin"
                        placeholder="Vin"
                        formControlName="vin"
                        [class.is-invalid]="inputIsInvalid('vin')"
                        (keyup)="handleVinChange()"
                    />
                    <div *ngIf="inputIsInvalid('vin')" class="invalid-feedback">
                        <div *ngIf="form.get('vin').errors.required">Pflichtfeld!</div>
                        <div
                            *ngIf="(!form.get('vin').errors.required) && (form.get('vin').errors.minlength || form.get('vin').errors.maxlength)">
                            Die Vin muss exakt 17 Zeichen beinhalten!</div>
                        <div *ngIf="(!form.get('vin').errors.required) && form.get('vin').errors.invalidVin">Die Vin ist
                            nicht
                            valide!</div>
                    </div>
                </div>
                <div class="col-3">
                    <label for="zksnrCEBAS">ZK-SNR CeBAS*</label>
                    <input
                        [ngbTypeahead]="searchZksnrCebas"
                        (focus)="focusZksnrCebas$.next($any($event).target.value)"
                        (click)="clickZksnrCebas$.next($any($event).target.value)"
                        (blur)="handleZksnrCebasChange()"
                        (keyup)="handleZksnrCebasChange()"
                        autocomplete="off"
                        class="form-control"
                        id="zksnrCEBAS"
                        name="zksnrCEBAS"
                        type="text"
                        aria-describedby="zksnrCEBAS"
                        formControlName="zksnrCEBAS"
                        [ngClass]="{'is-invalid' : skidAndZkSnrEmpty() || zkSnrIsInvalid('invalidZkSnrCEBAS', 'zksnrCEBAS')}"
                    />
                    <div *ngIf="zkSnrIsInvalid('invalidZkSnrCEBAS', 'zksnrCEBAS') || skidAndZkSnrEmpty()" class="invalid-feedback">
                        <div *ngIf="skidAndZkSnrEmpty()">ZK-SNR oder Skid muss ausgefüllt sein!</div>
                        <div *ngIf="form?.errors?.invalidZkSnrCEBAS">Die ZK-SNR CeBAS ist nicht valide!</div>
                    </div>
                </div>
                <div class="col-3">
                    <label for="skidCEBAS">Skid CeBAS*</label>
                    <input autocomplete="off" class="form-control" id="skidCEBAS" name="skidCEBAS" type="text"
                           aria-describedby="skidCEBAS" formControlName="skidCEBAS"
                           [ngClass]="{'is-invalid' : skidAndZkSnrEmpty() || skidIsInvalid('invalidSkidCEBAS', 'skidCEBAS')}">
                    <div *ngIf="skidIsInvalid('invalidSkidCEBAS', 'skidCEBAS')" class="invalid-feedback">
                        <div *ngIf="form?.errors?.invalidSkidCEBAS">Die Skid CeBAS ist nicht valide!</div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-2">
                    <label for="sessionId">Session ID *</label>
                    <input autocomplete="off" class="form-control" id="sessionId" name="sessionId" type="text"
                           aria-describedby="sessionId" formControlName="sessionId"
                           [ngClass]="{'is-invalid' : inputIsInvalid('sessionId')}">
                    <div *ngIf="inputIsInvalid('sessionId')" class="invalid-feedback">
                        <div *ngIf="form.get('sessionId').errors.required">Pflichtfeld!</div>
                    </div>
                </div>
                <div class="col-3">
                    <label for="zksnrETM">ZK-SNR ETM (optional)</label>
                    <input
                        [ngbTypeahead]="searchZksnrEtm"
                        (focus)="focusZksnrEtm$.next($any($event).target.value)"
                        (click)="clickZksnrEtm$.next($any($event).target.value)"
                        (blur)="handleZksnrEtmChange()"
                        (keyup)="handleZksnrEtmChange()"
                        autocomplete="off"
                        class="form-control"
                        id="zksnrETM"
                        name="zksnrETM"
                        type="text"
                        aria-describedby="zksnrETM"
                        formControlName="zksnrETM"
                        [ngClass]="{'is-invalid' : zkSnrIsInvalid('invalidZkSnrETM', 'zksnrETM')}"
                    />
                    <div *ngIf="zkSnrIsInvalid('invalidZkSnrETM', 'zksnrETM') || skidAndZkSnrEmpty()" class="invalid-feedback">
                        <div *ngIf="form?.errors?.invalidZkSnrETM">Die ZK-SNR ETM ist nicht valide!</div>
                    </div>
                </div>
                <div class="col-3">
                    <label for="skidETM">Skid ETM (optional)</label>
                    <input autocomplete="off" class="form-control" id="skidETM" name="skidETM" type="text"
                           aria-describedby="skidETM" formControlName="skidETM"
                           [ngClass]="{'is-invalid' : skidIsInvalid('invalidSkidETM', 'skidETM')}">
                    <div *ngIf="skidIsInvalid('invalidSkidETM', 'skidETM')" class="invalid-feedback">
                        <div *ngIf="form?.errors?.invalidSkidETM">Die Skid ETM ist nicht valide!</div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-2">
                    <label for="requestId">Request ID</label>
                    <input autocomplete="off" class="form-control" id="requestId" name="requestId" type="text"
                           aria-describedby="requestId" formControlName="requestId">
                </div>
                <div class="col-1">
                    <label for="sparte">Sparte *</label>
                    <select class="form-control" formControlName="sparte" id="sparte" name="sparte"
                            [ngClass]="{'is-invalid' : inputIsInvalid('sparte')}">
                        <option *ngFor="let division of divisions" [ngValue]="division">{{division}}</option>
                    </select>
                    <div *ngIf="inputIsInvalid('sparte')" class="invalid-feedback">
                        <div *ngIf="form.get('sparte').errors.required">Pflichtfeld!</div>
                    </div>
                </div>
                <div class="col-2">
                    <label for="anfragendesSystem">Anfragendes System *</label>
                    <select class="form-control" id="anfragendesSystem" name="anfragendesSystem"
                            formControlName="anfragendesSystem"
                            [ngClass]="{'is-invalid' : inputIsInvalid('anfragendesSystem')}">
                        <option *ngFor="let system of systems">{{system.value}}</option>
                    </select>
                    <div *ngIf="inputIsInvalid('anfragendesSystem')" class="invalid-feedback">
                        <div *ngIf="form.get('anfragendesSystem').errors.required">Pflichtfeld!</div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="vedoc-manipulation-label">
                        <label>Vedoc Daten Manipulation</label>
                    </div>
                    <div class="vedoc-manipulation-toggle">
                        <app-toggle-button formControlName="manipulation"></app-toggle-button>
                    </div>
                </div>
            </div>
            <div *ngIf="form.controls.manipulation.value">
                <div class="row mt-3 mb-4">
                    <div class="col-12">
                        <button type="button" class="btn btn-outline-primary" (click)="requestVedocData()"
                            *ngIf="form.controls.manipulation.value">
                            <span>Vedoc Daten abfragen</span>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-xl-3">
                        <label for="ttz">TTZ</label>
                        <input autocomplete="off" class="form-control" id="ttz" name="ttz" type="text"
                            aria-describedby="ttz" formControlName="ttz"
                            [ngClass]="{'is-invalid' : inputIsInvalid('ttz')}">
                        <div *ngIf="inputIsInvalid('ttz')" class="invalid-feedback">
                            <div *ngIf="form.get('ttz').errors.required">Benötigt für Manipulations-Request!</div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xl-3">
                        <label for="fin">FIN</label>
                        <input autocomplete="off" class="form-control" id="fin" name="fin" type="text"
                            aria-describedby="fin" formControlName="fin"
                            [ngClass]="{'is-invalid' : inputIsInvalid('fin')}">
                        <div *ngIf="inputIsInvalid('fin')" class="invalid-feedback">
                            <div *ngIf="form.get('fin').errors.required">Benötigt für Manipulations-Request!</div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-12 col-xl-9">
                        <label for="codes">Codeleiste</label>
                        <textarea class="form-control" id="codes" name="codes" aria-describedby="codes"
                            formControlName="codes" rows="5"
                            [ngClass]="{'is-invalid' : inputIsInvalid('codes')}"></textarea>
                        <div *ngIf="inputIsInvalid('codes')" class="invalid-feedback">
                            <div *ngIf="form.get('codes').errors.required">Benötigt für Manipulations-Request!</div>
                        </div>
                        <app-hint *ngIf="showHint$ | async" [hint]="hint"></app-hint>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-secondary" (click)="resetInput()">
                            <span>Zurücksetzen</span>
                        </button>
                        <button type="button" class="btn btn-primary" (click)="calculate()">
                            <span>Berechnen</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
