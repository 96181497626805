import { Injectable } from '@angular/core';
import { permissions } from '@shared/constants/permissions';
import { Datapool } from '@shared/models/datapool/datapool';
import { AppAuthGuard } from 'src/app/app.authguard';

@Injectable({
    providedIn: 'root',
})
export class DataPoolService {
    private testDatapool = Datapool.TEST;
    private vorabDatapool = Datapool.VORAB;
    private serienDatapool = Datapool.SERIE;
    private allowedDatapools = [];

    private roleMapping = [
        {
            role: permissions.PKW.DATA_TEST_WRITE,
            dataPool: this.testDatapool,
        },
        {
            role: permissions.PKW.DATA_VORAB_WRITE,
            dataPool: this.vorabDatapool,
        },
        {
            role: permissions.PKW.DATA_SERIEN_WRITE,
            dataPool: this.serienDatapool,
        },
        {
            role: permissions.VAN.DATA_TEST_WRITE,
            dataPool: this.testDatapool,
        },
        {
            role: permissions.VAN.DATA_VORAB_WRITE,
            dataPool: this.vorabDatapool,
        },
        {
            role: permissions.VAN.DATA_SERIEN_WRITE,
            dataPool: this.serienDatapool,
        },
    ];

    constructor(private appAuthGuard: AppAuthGuard) {}

    getDataPools(): Datapool[] {
        return this.allowedDatapools;
    }

    initializeDatapools() {
        this.allowedDatapools = [];

        this.roleMapping.forEach(roleMapping => {
            this.appAuthGuard.checkRoles([roleMapping.role]).then(allowed => {
                if (allowed) {
                    this.allowedDatapools.push(roleMapping.dataPool);
                }
            });
        });
    }
}
