<div class="vus-modal">
    <form [formGroup]="form">
        <div class="modal-header">
            <h3 class="modal-title">Image produktiv schalten</h3>
        </div>
        <div class="modal-body">
            <div class="row form-group">
                <div class="col-12">
                    <div>
                        <p>Grund für die Änderung auswählen</p>
                    </div>
                    <div>
                        <div class="row form-group">
                            <div class="col-6">
                                <select id="reason-selector" class="form-control" name="reason-selector"
                                        formControlName="selectedReason">
                                    <option *ngFor="let reason of reasons" [value]="reason.text">{{reason.text}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isOther()" class="row">
                        <div class="col-sm-6 col-lg-12">
                            <input autocomplete="off" class="form-control" id="other" type="text" aria-describedby="other"
                                formControlName="other">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>Wollen Sie das Image wirklich produktiv schalten?</span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" (click)="dismiss()">
                    <span>Abbrechen</span>
                </button>
                <button type="button" class="btn btn-primary" (click)="close()" id="swapImage">
                    <span>Produktiv schalten</span>
                </button>
            </div>
        </div>
    </form>
</div>
