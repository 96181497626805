import { Injectable } from '@angular/core';
import { AscMetadata } from '@shared/models/asc-metadata';
import * as XMLWriter from 'xml-writer';

@Injectable({
    providedIn: 'root',
})
export class AscFrameGeneratorService {
    constructor() {}

    generate(metadata: AscMetadata, payload: any): string {
        const xml = new XMLWriter();
        xml.startDocument('1.0', 'UTF-8');
        xml.startElement('af:ascframe');

        xml.writeAttribute(
            'xmlns:af',
            'http://asconnector.daimler.com/ascFrame'
        );
        xml.writeAttribute(
            'xmlns:xsi',
            'http://www.w3.org/2001/XMLSchema-instance'
        );
        xml.writeAttribute('frameVersion', '1.1');
        xml.writeAttribute(
            'xsi:schemaLocation',
            'http://asconnector.daimler.com/ascFrame ascFrame_v11.xsd'
        );
        xml.writeAttribute('xsi:type', 'af:serviceRequestFrame');

        xml.startElement('tracking');

        xml.startElement('sessionID')
            .text((metadata && metadata.sessionID) || '')
            .endElement('sessionID');
        xml.startElement('requestID')
            .text((metadata && metadata.requestID) || '')
            .endElement('requestID');

        xml.endElement('tracking');

        xml.startElement('client');
        xml.startElement('platform')
            .text((metadata && metadata.platform) || 'platform')
            .endElement('platform');
        xml.startElement('dateTime')
            .text(new Date().toISOString())
            .endElement('dateTime');

        xml.startElement('locale');
        xml.startElement('language')
            .text('DE')
            .endElement('language');
        xml.startElement('country')
            .text('DE')
            .endElement('country');
        xml.endElement('locale');

        xml.startElement('application');
        xml.startElement('name')
            .text(
                (metadata && metadata.application.name) ||
                    'Application Simulator'
            )
            .endElement('name');
        xml.startElement('version')
            .text((metadata && metadata.application.version) || '1.0.0')
            .endElement('version');
        xml.endElement('application');

        xml.startElement('task');
        xml.startElement('process')
            .text('calculate')
            .endElement('process');
        xml.startElement('mode')
            .text('online')
            .endElement('mode');
        xml.endElement('task');
        xml.endElement('client');

        xml.startElement('destination');
        xml.startElement('service')
            .text((metadata && metadata.destination.service) || 'Service')
            .endElement('service');
        xml.startElement('action')
            .text((metadata && metadata.destination.action) || 'action')
            .endElement('action');
        xml.endElement('destination');

        xml.startElement('user');

        xml.startElement('userID')
            .text((metadata && metadata.user.id) || 'USER_001')
            .endElement('userID');
        xml.startElement('organisationIDs');
        xml.startElement('organisationID')
            .text('E-DE71032A')
            .endElement('organisationID');
        xml.endElement('organisationIDs');

        xml.endElement('user');

        xml.startElement('payload');
        xml.writeRaw(payload || '');
        xml.endElement('payload');
        xml.endElement('af:ascframe');
        xml.endDocument();

        return xml.toString();
    }
}
