import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { Datapool } from '@shared/models/datapool/datapool';
import { FileType } from '@shared/models/filetype/file-type';
import { ModalResult } from '@shared/models/modal-result';
import { ApiService } from '@shared/services/api/api.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { FileParserService } from '@shared/services/file-parser/file-parser.service';
import { ZkePkwMetadataService } from '@shared/services/metadata/zke-pkw-metadata/zke-pkw-metadata.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-upload-formular',
    templateUrl: './upload-formular.component.html',
})
export class UploadFormularComponent implements OnInit, OnDestroy {
    @Input() dataPool: Datapool;
    @Input() dataPoolIdentifier: string;

    form: UntypedFormGroup;
    fileType: FileType;

    private ROOT_URL: string;
    private notifier = new Subject();
    private switchSetting: string;
    private file: File;
    private fileContent: string;

    constructor(
        private fileParserService: FileParserService,
        private formBuilder: UntypedFormBuilder,
        private importMetadataService: ZkePkwMetadataService,
        private messageHandler: ErrorHandlerService,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    private static getFileExtension(fileName: string) {
        return fileName.split('.').pop();
    }

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
            });

        this.form = this.formBuilder.group({
            file: ['', Validators.required],
            note: ['', Validators.maxLength(255)],
        });
    }

    fileChanged(data: any) {
        if (data.target.files && data.target.files.length > 0) {
            this.file = data.target.files[0];
            this.fileParserService.parse(this.file).then((fileContent: any) => {
                this.fileContent = fileContent;
            });
        }
    }

    uploadFile() {
        this.markFormGroupTouched(this.form);
        if (this.form.invalid) {
            return;
        }

        if (
            UploadFormularComponent.getFileExtension(this.file.name) !== 'csv'
        ) {
            this.messageHandler.showError(
                'Fehler',
                'Keine CSV-Datei ausgewählt'
            );
            return;
        }

        const modalRef = this.modalService.open(WarningComponent);
        modalRef.componentInstance.closingOption = false;
        modalRef.componentInstance.message =
            '<p>Beim Importieren mit dem gleichen Filename wird der entsprechende Datenbestand überschrieben!</p>\
                                              <p>Möchten Sie die CSV Datei wirklich importieren?</p>';

        modalRef.result.then(
            reason => {
                if (reason === ModalResult.WARNING_OK) {
                    this.importMetadataService
                        .uploadFile(
                            this.ROOT_URL,
                            this.fileType,
                            this.dataPool,
                            undefined,
                            this.file.name,
                            this.fileContent,
                            this.form.get('note').value,
                            this.file.lastModified,
                            this.dataPoolIdentifier
                        )
                        .subscribe(() => {
                            this.messageHandler.showSuccess(
                                'Erfolgreich',
                                'Die Datei wurde erfolgreich importiert'
                            );
                            this.activeModal.close(
                                ModalResult.IMPORT_SUCCESSFUL
                            );
                        });
                } else {
                    this.activeModal.close();
                }
            },
            error => {
                this.activeModal.close(error);
            }
        );
    }

    inputIsInvalid(control: string): boolean {
        return this.form.get(control).errors && this.form.get(control).touched;
    }

    private markFormGroupTouched(formGroup: UntypedFormGroup) {
        const values = Object.keys(formGroup.controls).map(
            e => formGroup.controls[e]
        );
        values.forEach((control: UntypedFormGroup) => {
            control.markAsTouched();
        });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
