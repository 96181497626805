export const environment = {
    production: false, //Non-local environment
    baseUrl: 'http://localhost:4200',
    logoutUrl: '/logout',
    commonApiUrl: 'http://localhost:4200',
    carApiUrl: 'http://localhost:4200',
    vanApiUrl: 'http://localhost:4200',
    zkeUrl : 'http://localhost:4200',
    accessToken: '123456789', // Add your Access-Token for local development here...
    refreshToken: '', // Add your Refresh-Token for local development here...
};
