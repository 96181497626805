import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToggleComponent } from '@shared/components/toggle/toggle.component';

@NgModule({
    declarations: [ToggleComponent],
    imports: [CommonModule, FormsModule],
    exports: [ToggleComponent],
})
export class ToggleModule {}
