<ngx-datatable #notificationTable
               class="material"
               [columnMode]="'flex'"
               [rows]="topics"
               [draggable]="false"
               [headerHeight]="50"
               [rowHeight]="'auto'"
               [footerHeight]="50"
               [loadingIndicator]="isLoading"
               [sorts]="sortConfig">
    <ngx-datatable-column name="Name" prop="name" [flexGrow]="2" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Beschreibung Benachrichtigungsdienst" prop="description" [flexGrow]="5" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Aktionen" [flexGrow]="1" [resizeable]="false">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
            <div class="action-column">
                <icon-button (action)="openDetails(row)" iconSelector="eye"
                             toolTipDescription="Details ansehen">
                </icon-button>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                     let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                     let-isVisible="isVisible">
            <custom-table-footer [rowCount]="rowCount" [element]="'Benachrichtigungen'">
            </custom-table-footer>

            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                             [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                             [page]="curPage"
                             [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                             (change)="notificationTable.onFooterPage($event)">
            </datatable-pager>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>

