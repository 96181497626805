import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { DeplomentUpdateStatusDto } from '@shared/models/deploment-update-status-dto';
import { Image } from '@shared/models/image';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ContainerManagementService {
    private static readonly formDataHttpHeaders = new HttpHeaders({
        enctype: 'multipart/form-data',
    });

    constructor(private http: HttpClient) {}

    updateImage(
        url: string,
        image: Image,
        changeCause: string
    ): Observable<any> {
        const body = new FormData();
        body.append('changeCause', changeCause);
        return this.http.put(
            `${url}/${apiPaths.controllerImage}/${image.imageTag}`,
            body,
            {
                headers: ContainerManagementService.formDataHttpHeaders,
                observe: 'response',
            }
        );
    }

    checkProductiveDeploymentStatus(
        apiPath: string
    ): Observable<DeplomentUpdateStatusDto> {
        return this.http.get<DeplomentUpdateStatusDto>(`${apiPath}`);
    }

    deployImage(url: string, image: Image): Observable<any> {
        const formData = new FormData();
        formData.append('action', 'DEPLOY');

        return this.http.post(
            `${url}/${apiPaths.controllerImage}/${image.imageTag}`,
            formData,
            {
                headers: ContainerManagementService.formDataHttpHeaders,
                observe: 'response',
            }
        );
    }

    undeployImage(url: string, image: Image): Observable<any> {
        const formData = new FormData();
        formData.append('action', 'UNDEPLOY');

        return this.http.post(
            `${url}/${apiPaths.controllerImage}/${image.imageTag}`,
            formData,
            {
                headers: ContainerManagementService.formDataHttpHeaders,
                observe: 'response',
            }
        );
    }
}
