import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CalculateInstanceSelectorModule } from '@shared/components/calculate-instance-selector/calculate-instance-selector/calculate-instance-selector.module';
import { HintModule } from '@shared/components/hint/hint.module';
import { ToggleButtonModule } from '@shared/components/toggle-button/toggle-button.module';
import { KonamiModule } from '@shared/directives/konami.module';

import { ZkeRequestFormularComponent } from './zke-request-formular.component';

@NgModule({
    declarations: [ZkeRequestFormularComponent],
    imports: [
        CalculateInstanceSelectorModule,
        CommonModule,
        FormsModule,
        KonamiModule,
        ReactiveFormsModule,
        ToggleButtonModule,
        NgbTypeaheadModule,
        HintModule,
    ],
    exports: [ZkeRequestFormularComponent],
})
export class ZkeRequestFormularModule {}
