import { Component } from '@angular/core';
import { permissions } from '@shared/constants/permissions';
import { Datapool } from '@shared/models/datapool/datapool';
import { RoleConfig } from '@shared/models/role-config';

@Component({
    selector: 'app-pre-data-images',
    templateUrl: './pre-data-images.component.html',
    styleUrls: ['./pre-data-images.component.scss'],
})
export class PreDataImagesComponent {
    roleConfig: RoleConfig = {
        description: 'WRITE',
        roles: [
            permissions.PKW.IMAGE_VORAB_WRITE,
            permissions.VAN.IMAGE_VORAB_WRITE,
        ],
    };
    dataPool = Datapool.VORAB;

    constructor() {}
}
