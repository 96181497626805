import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-xentry-request-view',
    templateUrl: './xentry-request-view.component.html',
    styleUrls: ['./xentry-request-view.component.scss'],
})
export class XentryRequestViewComponent implements OnInit {
    @Input() xmlRequest: EventEmitter<string>;

    xmlRequestString: string;

    constructor() {}

    ngOnInit() {
        if (this.xmlRequest) {
            this.xmlRequest.subscribe(data => {
                this.xmlRequestString = data;
            });
        }
    }
}
