import { Component } from '@angular/core';
import { permissions } from '@shared/constants/permissions';
import { Datapool } from '@shared/models/datapool/datapool';
import { RoleConfig } from '@shared/models/role-config';

@Component({
    selector: 'app-series-images',
    templateUrl: './series-images.component.html',
    styleUrls: ['./series-images.component.scss'],
})
export class SeriesImagesComponent {
    dataPool = Datapool.SERIE;
    roleConfig: RoleConfig = {
        description: 'WRITE',
        roles: [
            permissions.PKW.IMAGE_SERIEN_WRITE,
            permissions.VAN.IMAGE_SERIEN_WRITE,
        ],
    };
}
