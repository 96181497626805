export enum Division {
    PKW = 'PKW',
    VAN = 'VAN',
    SMART = 'SMART',
    TRANSP = 'TRANSP',
}

export function getAllDivisions(): Division[] {
    return Object.keys(Division)
        .map(key => Division[key])
        .filter(isNaN);
}
