import { Injectable } from '@angular/core';

export class ImportMetadata {
    constructor(
        public fileId: number,
        public dataPool: string,
        public fileType: string,
        public fileName: string,
        public baureihe: any,
        public fileRef: any,
        public createdAt: Date,
        public uploadedAt: Date,
        public note: string,
        public deployed: string,
        public user: string
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class ImportMetadataAdapter {
    adapt(item: any): ImportMetadata {
        return new ImportMetadata(
            item.fileId,
            item.dataPool || undefined,
            item.fileType || undefined,
            item.fileName ? item.fileName : undefined,
            item.baureihe ? item.baureihe : undefined,
            item.fileRef ? item.fileRef : undefined,
            item.createdAt ? new Date(item.createdAt) : undefined,
            item.uploadedAt ? new Date(item.uploadedAt) : undefined,
            item.note || undefined,
            item.deployed || undefined,
            item.user || undefined
        );
    }
}
