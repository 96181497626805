import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UnauthorizedModalComponent } from './unauthorized-modal.component';

@NgModule({
    declarations: [UnauthorizedModalComponent],
    imports: [CommonModule],
    exports: [UnauthorizedModalComponent],
})
export class UnauthorizedModalModule {}
