import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { PlantMapperResponse } from '@shared/models/plant-mapper-response';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PlantMapperService {
    private plantMapperRoute = 'api/werksnummer-mapping';

    constructor(private http: HttpClient) {}

    getByFin(url: string, fin: string): Observable<PlantMapperResponse> {
        return this.http.get<PlantMapperResponse>(
            `${url}/${apiPaths.calculation}/${this.plantMapperRoute}/${fin}`
        );
    }
}
