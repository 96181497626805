import { SmoketestSetting } from '@shared/models/smoketest/smoketest-setting';

export class SmoketestSettingDetailResult {
    constructor(public data: SmoketestSetting, public mode: Mode) {}
}

export enum Mode {
    POST = 'POST',
    PUT = 'PUT',
}
