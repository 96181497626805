import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DataPoolSelectorComponent } from './datapool-selector.component';

@NgModule({
    imports: [CommonModule, NgbModule],
    exports: [DataPoolSelectorComponent],
    declarations: [DataPoolSelectorComponent],
    providers: [],
})
export class DataPoolSelectorModule {}
