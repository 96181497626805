<app-loading-spinner
    *ngIf='isLoading'
    [text]='loadingSubtitle'
    [overlay]='true'></app-loading-spinner>

<div *ngIf='true' class='col-5 body'>
    <p class='title'>Ein Test wird durchgeführt</p>
    <div class='cancel-running'>
        <div class='bar'>
            <app-loading-bar>
            </app-loading-bar>
        </div>
        <button class=' btn btn-outline-primary button ' (click)='openConfirmationModal()'> Abbrechen
        </button>
    </div>
</div>
