import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { MediaType } from '@shared/constants/media-type';

import { TestautomationTest } from '@shared/models/testautomation/TestautomationTest';
import { Observable } from 'rxjs';
import { TestautomationTestDetail } from '@shared/models/testautomation/TestautomationTestDetail';

@Injectable({
    providedIn: 'root',
})
export class TestautomationService {
    private testRunRoute = 'api/test-run';
    private testCaseRoute = 'test-case';
    private testActiveRoute = 'active';
    private testCancelRoute = 'cancel';
    private testRecordDownload = 'file';

    constructor(private httpClient: HttpClient, ) {
    }

    getTestRuns(url:string):Observable<TestautomationTest[]> {
        return this.httpClient.get<TestautomationTest[]>(
            `${url}${apiPaths.testManager}/${this.testRunRoute}`);
    }

    getTestDetails(url:string,key:string):Observable<TestautomationTestDetail[]> {
        return this.httpClient.get<TestautomationTestDetail[]>(
            `${url}${apiPaths.testManager}/${this.testRunRoute}/${key}/${this.testCaseRoute}`);
    }

    getActiveTestRunId(url:string):Observable<number>{
        return this.httpClient.get<number>(
            `${url}${apiPaths.testManager}/${this.testRunRoute}/${this.testActiveRoute}`);
    }

    cancelActiveTest(url:string, id : number){
        return this.httpClient.post(
            `${url}${apiPaths.testManager}/${this.testRunRoute}/${id}/${this.testCancelRoute}`,
            {id: id});
    }

    startTestRun(url:string){
        return this.httpClient.post(
            `${url}${apiPaths.testManager}/${this.testRunRoute}`,
            {});
    }

    getTestRecordData(url :string, key : string): Observable<HttpResponse<Blob>>{
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append(
            'Content-Type',
            MediaType.csvTextMediaType
        );
        httpHeaders = httpHeaders.append(
            'Accept',
             MediaType.textPlainWithoutUTF8MediaType
        );
        
        return this.httpClient.get<Blob>(`${url}${apiPaths.testManager}/${this.testRunRoute}/${this.testRecordDownload}/${key}`, {
            headers: httpHeaders,
            responseType: 'blob' as 'json',
            observe: 'response',
        });
    
    }
}
