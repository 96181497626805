import { Component, OnInit } from '@angular/core';
import { Datapool } from '@shared/models/datapool/datapool';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';
import { SelectedUserDataPoolService } from '@shared/services/datapool/userDataPool/selected-user-datapool/selected-user-datapool.service';

@Component({
    selector: 'app-test-data-selected-datapool',
    templateUrl: './test-data-selected-datapool.component.html',
})
export class TestDataSelectedDatapoolComponent implements OnInit {
    dataPool = Datapool.TEST;
    dataPoolIdentifier: string;
    dataPoolEntitlement: UserDatapoolEntitlement;
    active = 0;

    constructor(
        private selectedUserDataPoolService: SelectedUserDataPoolService
    ) {}

    ngOnInit() {
        this.selectedUserDataPoolService.sharedDataPool.subscribe(
            selectedDataPool => {
                this.dataPoolIdentifier = selectedDataPool.dataPool;
                this.dataPoolEntitlement = selectedDataPool.entitlement;
            }
        );
    }
}
