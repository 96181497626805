import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SnsTopic } from '@shared/models/sns-notification/sns-topic';

@Component({
    selector: 'app-notification-table',
    templateUrl: './notification-table.component.html',
    styleUrls: ['./notification-table.component.scss'],
})
export class NotificationTableComponent {
    @Input()
    isLoading: boolean;

    @Input()
    topics: SnsTopic[];

    @Output()
    detail = new EventEmitter<{ topic: SnsTopic }>();

    sortConfig = [{ prop: 'name', dir: 'asc' }];

    openDetails(topic: SnsTopic) {
        this.detail.emit({ topic });
    }
}
