import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { Datapool, isTestDataPool } from '@shared/models/datapool/datapool';
import { Image } from '@shared/models/image';
import { ImageDeploymentHistory } from '@shared/models/image-deployment-history';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ImageManagementService {
    constructor(private http: HttpClient) {}

    createImage(
        url: string,
        dataPool: Datapool,
        dataPoolIdentifier?: string,
        note?: string
    ): Observable<any> {
        url = `${url}/${
            apiPaths.controllerImage
        }?action=CREATE_IMAGE&dataPool=${
            isTestDataPool(dataPool) ? dataPoolIdentifier : dataPool
        }`;

        if (note && note.length > 0) {
            url += `&note=${encodeURIComponent(note)}`;
        }
        return this.http.post(url, {
            observe: 'response',
        });
    }

    promoteImage(url: string, image: Image): Observable<HttpResponse<object>> {
        url = `${url}/${apiPaths.controllerImage}/${image.imageTag}`;
        return this.http.patch(
            url,
            {},
            {
                observe: 'response',
            }
        );
    }

    getImages(url: string, dataPool: Datapool): Observable<Image[]> {
        const imageStage = isTestDataPool(dataPool)
            ? 'USER'
            : dataPool.split('_').shift();
        return this.http.get<Image[]>(
            `${url}/${apiPaths.controllerImages}?imagestage=${imageStage}`
        );
    }

    deleteImage(url: string, image: Image): Observable<any> {
        return this.http.delete(
            `${url}/${apiPaths.controllerImage}/${image.imageTag}`,
            {
                observe: 'response',
            }
        );
    }

    getImageWithFiles(
        url: string,
        dataPool: Datapool,
        imageTag: string
    ): Observable<Image> {
        const imageStage = isTestDataPool(dataPool)
            ? 'USER'
            : dataPool.split('_').shift();
        return this.http.get<Image>(
            `${url}/${apiPaths.controllerImageStage}/${imageStage}/image/${imageTag}`
        );
    }

    getImageDeploymentHistory(
        url: string,
        dataPool: Datapool,
        imageTag: string
    ): Observable<ImageDeploymentHistory[]> {
        const imageStage = isTestDataPool(dataPool)
            ? 'USER'
            : dataPool.split('_').shift();
        return this.http.get<ImageDeploymentHistory[]>(
            `${url}/${apiPaths.controllerImageStage}/${imageStage}/image/${imageTag}/deployment-history`
        );
    }
}
