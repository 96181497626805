<div class="series-data-management">
    <h3 class="vus-page-title">Datenverwaltung - Seriendaten</h3>

    <div class="row">
        <div class="col-12">
            <div class="vus-container">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="zke-nav-tabs">
                    <li [ngbNavItem]="1" class="zke-nav-item">
                        <a ngbNavLink class="zke-nav-link">Stammdaten</a>
                        <ng-template ngbNavContent>
                            <app-data-management-master-data [dataPool]="dataPool"></app-data-management-master-data>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="zke-nav-item">
                        <a ngbNavLink class="zke-nav-link">Mapping</a>
                        <ng-template ngbNavContent>
                            <app-mapping [dataPool]="dataPool"></app-mapping>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="zke-nav-item">
                        <a ngbNavLink class="zke-nav-link">Steuerdaten</a>
                        <ng-template ngbNavContent>
                            <app-data-management-control-data [dataPool]="dataPool"></app-data-management-control-data>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</div>
