<nav class="header-container">
    <div class="row">
        <div class="col">
            <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-outline-primary" id="userManualDropdown" ngbDropdownToggle>
                    <fa-icon class="menu-icon" icon="question-circle" size="lg"></fa-icon>
                    <span class="menu-text">Nutzerhandbuch</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="userManualDropdown" >
                    <button ngbDropdownItem (click)="downloadFile()">
                        <span class="menu-item-text">Herunterladen</span>
                        <fa-icon class="menu-item-icon" icon="download" size="xs"></fa-icon>
                    </button>
                    <button ngbDropdownItem (click)="viewInBrowser()">
                        <span class="menu-item-text">Im Browser öffnen</span>
                        <fa-icon class="menu-item-icon" icon="external-link" size="xs"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isAdmin" class="btn-group btn-group-toggle" role="group">
        <input type="radio" [formControl]="switch" [value]="'CAR'" class="btn-check" id="switch_car" autocomplete="off">
        <label class="btn btn-primary" for="switch_car">
            <span>PKW</span>
        </label>

        <input type="radio" [formControl]="switch" [value]="'VAN'" class="btn-check" id="switch_van" autocomplete="off">
        <label class="btn btn-primary" for="switch_van">
            <span>VAN</span>
        </label>
    </div>
    <div class="user-info">
        <fa-icon icon="user" size="lg"></fa-icon>
        <div>
            <span>{{username}}</span>
        </div>
    </div>
    <fa-icon icon="sign-out-alt" size="lg" title="Abmelden" (click)="logout()"></fa-icon>
</nav>
