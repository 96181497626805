import { Component } from '@angular/core';
import { Datapool } from '@shared/models/datapool/datapool';

@Component({
    selector: 'app-pre-data',
    templateUrl: './pre-data.component.html',
})
export class PreDataComponent {
    dataPool = Datapool.VORAB;
    active = 1;
}
