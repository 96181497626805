<div class='vus-modal'>
    <div class='modal-header'>
        <h3>Image zur Berechnung auswählen</h3>
    </div>
    <div class='modal-body'>
        <ngx-datatable #dataTable class='material' [columnMode]="'flex'" [draggable]='false'
                       [headerHeight]='50' [rowHeight]="'auto'" [rows]='rows' [sorts]='sorts' [footerHeight]='50'
                       limit='10'>
            <ngx-datatable-column [flexGrow]='1' [resizeable]='false'>
                <ng-template let-row='row' let-value='value' ngx-datatable-cell-template>
                    <input type='radio' [(ngModel)]='selectedRow' [value]='row'>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name='Image-Tag' prop='name' [flexGrow]='3' [resizeable]='false'>
                <ng-template let-value='value' ngx-datatable-cell-template>
                    <span *ngIf='value'>{{ value }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name='Erstellzeitpunkt' prop='creationDate' [flexGrow]='2' [resizeable]='false'>
                <ng-template let-value='value' let-row='row' ngx-datatable-cell-template>
                    <span *ngIf='value'>{{ value | date: 'dd.MM.yy, HH:mm:ss' }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name='Kommentar' prop='note' [flexGrow]='5' [resizeable]='false'>
                <ng-template let-value='value' ngx-datatable-cell-template>
                    <span *ngIf='value'>{{ value }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name='User' prop='createdBy' [flexGrow]='2' [resizeable]='false'>
                <ng-template let-value='value' ngx-datatable-cell-template>
                    <span *ngIf='value'>{{ value }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount='rowCount' let-pageSize='pageSize'
                             let-selectedCount='selectedCount' let-curPage='curPage' let-offset='offset'
                             let-isVisible='isVisible'>
                    <custom-table-footer (setLimit)='setLimit($event)' [rowCount]='rowCount'
                                         [element]="'Dateien'" [dataTableType]="'Modal'">
                    </custom-table-footer>

                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                     [pagerRightArrowIcon]="'datatable-icon-right'"
                                     [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                     [page]='curPage' [size]='pageSize' [count]='rowCount'
                                     [hidden]='!((rowCount / pageSize) > 1)' (change)='dataTable.onFooterPage($event)'>
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div class='modal-footer'>
        <button class='btn btn-secondary' (mousedown)='dismiss()'>
            <span>Abbrechen</span>
        </button>
        <button class='btn btn-primary' type='submit' [disabled]='!selectedRow'
                (mousedown)='useSelectedInstance(selectedRow)'>
            <span>Image verwenden</span>
        </button>
    </div>
</div>
