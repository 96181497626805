import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ROLES } from '@shared/constants/roles';
import { JWTUser, User } from '@shared/interfaces/user';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    user: User;
    authorizationError: string;
    isAuthorized = new Subject<boolean>();

    constructor(private http: HttpClient) {}

    initialize() {
        return new Promise(resolve => {
            this.initUser().subscribe(
                (user: User) => {
                    this.user = user;

                    if (this.user.permissions && this.user.permissions.length) {
                        this.authorizationError = '';
                        resolve(true);
                    } else {
                        this.authorizationError = 'MISSING_AUTHORIZATION_GROUP';
                        resolve(false);
                    }
                },
                error => {
                    this.authorizationError = error.error
                        ? error.error.message
                        : 'ERROR undefined';
                    resolve(false);
                }
            );
        });
    }

    getUserRole(): string {
        return this.user.roles[0];
    }

    isAdmin(): boolean {
        return (
            this.user.roles.includes(ROLES.PKW) &&
            this.user.roles.includes(ROLES.VAN)
        );
    }

    private setUser(user: JWTUser): User {
        return {
            upn: user.upn,
            name: {
                givenName: user.name.givenName,
                familyName: user.name.familyName,
            },
            email: user.email,
            groups: user.groups || [],
            permissions: user.permissions || [],
            roles: this.retrieveRoles(user.permissions),
        };
    }

    private retrieveRoles(permissions: string[]): string[] {
        const roles: string[] = [];
        Object.keys(ROLES).forEach(role => {
            if (permissions.some(permission => permission.includes(role))) {
                roles.push(ROLES[role]);
            }
        });
        return roles;
    }

    private initUser(): Observable<User> {
        return this.getJwt().pipe(map((user: User) => this.setUser(user)));
    }

    private getJwt(): Observable<JWTUser> {
        // this is used in the local test environment
        // to mock the jwt-echo call
        if (environment.baseUrl === 'http://localhost:8080') {
            return this.getJwtMock();
        } else {
            return this.http.get<JWTUser>(
                `${environment.commonApiUrl}/jwt-echo`
            );
        }
    }

    private getJwtMock(): Observable<JWTUser> {
        let JWTUserMock: JWTUser = {
            upn: 'KARTEST',
            name: {
                givenName: 'Cypress',
                familyName: 'User',
            },
            email: 'zke.test@extaccount.com',
            groups: [
                'VUS.ZKE_PKW_CALCULATE_TEST_WRITE',
                'VUS.ZKE_PKW_CALCULATE_VORAB_WRITE',
                'VUS.ZKE_PKW_CALCULATE_SERIEN_WRITE',
                'VUS.ZKE_PKW_DATA_TEST_READ',
                'VUS.ZKE_PKW_DATA_TEST_WRITE',
                'VUS.ZKE_PKW_DATA_VORAB_READ',
                'VUS.ZKE_PKW_DATA_VORAB_WRITE',
                'VUS.ZKE_PKW_DATA_SERIEN_READ',
                'VUS.ZKE_PKW_DATA_SERIEN_WRITE',
                'VUS.ZKE_PKW_IMAGE_TEST_READ',
                'VUS.ZKE_PKW_IMAGE_TEST_WRITE',
                'VUS.ZKE_PKW_IMAGE_VORAB_READ',
                'VUS.ZKE_PKW_IMAGE_VORAB_WRITE',
                'VUS.ZKE_PKW_IMAGE_SERIEN_READ',
                'VUS.ZKE_PKW_IMAGE_SERIEN_WRITE',
                'VUS.ZKE_PKW_SETTINGS_AUTODEPLOY_READ',
                'VUS.ZKE_PKW_SETTINGS_AUTODEPLOY_WRITE',
                'VUS.ZKE_PKW_SETTINGS_LOGLEVEL_READ',
                'VUS.ZKE_PKW_SETTINGS_LOGLEVEL_WRITE',
                'VUS.ZKE_PKW_SETTINGS_SMOKETESTDATA_READ',
                'VUS.ZKE_PKW_SETTINGS_SMOKETESTDATA_WRITE',
                'VUS.ZKE_PKW_SETTINGS_SNS_READ',
                'VUS.ZKE_PKW_SETTINGS_SNS_WRITE',
                'VUS.ZKE_VAN_CALCULATE_TEST_WRITE',
                'VUS.ZKE_VAN_CALCULATE_VORAB_WRITE',
                'VUS.ZKE_VAN_CALCULATE_SERIEN_WRITE',
                'VUS.ZKE_VAN_DATA_TEST_READ',
                'VUS.ZKE_VAN_DATA_TEST_WRITE',
                'VUS.ZKE_VAN_DATA_VORAB_READ',
                'VUS.ZKE_VAN_DATA_VORAB_WRITE',
                'VUS.ZKE_VAN_DATA_SERIEN_READ',
                'VUS.ZKE_VAN_DATA_SERIEN_WRITE',
                'VUS.ZKE_VAN_IMAGE_TEST_READ',
                'VUS.ZKE_VAN_IMAGE_TEST_WRITE',
                'VUS.ZKE_VAN_IMAGE_VORAB_READ',
                'VUS.ZKE_VAN_IMAGE_VORAB_WRITE',
                'VUS.ZKE_VAN_IMAGE_SERIEN_READ',
                'VUS.ZKE_VAN_IMAGE_SERIEN_WRITE',
                'VUS.ZKE_VAN_SETTINGS_AUTODEPLOY_READ',
                'VUS.ZKE_VAN_SETTINGS_AUTODEPLOY_WRITE',
                'VUS.ZKE_VAN_SETTINGS_LOGLEVEL_READ',
                'VUS.ZKE_VAN_SETTINGS_LOGLEVEL_WRITE',
                'VUS.ZKE_VAN_SETTINGS_SMOKETESTDATA_READ',
                'VUS.ZKE_VAN_SETTINGS_SMOKETESTDATA_WRITE',
                'VUS.ZKE_VAN_SETTINGS_SNS_READ',
                'VUS.ZKE_VAN_SETTINGS_SNS_WRITE',
            ],
            permissions: [
                'VUS.ZKE_PKW_CALCULATE_TEST_WRITE',
                'VUS.ZKE_PKW_CALCULATE_VORAB_WRITE',
                'VUS.ZKE_PKW_CALCULATE_SERIEN_WRITE',
                'VUS.ZKE_PKW_DATA_TEST_READ',
                'VUS.ZKE_PKW_DATA_TEST_WRITE',
                'VUS.ZKE_PKW_DATA_VORAB_READ',
                'VUS.ZKE_PKW_DATA_VORAB_WRITE',
                'VUS.ZKE_PKW_DATA_SERIEN_READ',
                'VUS.ZKE_PKW_DATA_SERIEN_WRITE',
                'VUS.ZKE_PKW_IMAGE_TEST_READ',
                'VUS.ZKE_PKW_IMAGE_TEST_WRITE',
                'VUS.ZKE_PKW_IMAGE_VORAB_READ',
                'VUS.ZKE_PKW_IMAGE_VORAB_WRITE',
                'VUS.ZKE_PKW_IMAGE_SERIEN_READ',
                'VUS.ZKE_PKW_IMAGE_SERIEN_WRITE',
                'VUS.ZKE_PKW_SETTINGS_AUTODEPLOY_READ',
                'VUS.ZKE_PKW_SETTINGS_AUTODEPLOY_WRITE',
                'VUS.ZKE_PKW_SETTINGS_LOGLEVEL_READ',
                'VUS.ZKE_PKW_SETTINGS_LOGLEVEL_WRITE',
                'VUS.ZKE_PKW_SETTINGS_SMOKETESTDATA_READ',
                'VUS.ZKE_PKW_SETTINGS_SMOKETESTDATA_WRITE',
                'VUS.ZKE_PKW_SETTINGS_SNS_READ',
                'VUS.ZKE_PKW_SETTINGS_SNS_WRITE',
                'VUS.ZKE_VAN_CALCULATE_TEST_WRITE',
                'VUS.ZKE_VAN_CALCULATE_VORAB_WRITE',
                'VUS.ZKE_VAN_CALCULATE_SERIEN_WRITE',
                'VUS.ZKE_VAN_DATA_TEST_READ',
                'VUS.ZKE_VAN_DATA_TEST_WRITE',
                'VUS.ZKE_VAN_DATA_VORAB_READ',
                'VUS.ZKE_VAN_DATA_VORAB_WRITE',
                'VUS.ZKE_VAN_DATA_SERIEN_READ',
                'VUS.ZKE_VAN_DATA_SERIEN_WRITE',
                'VUS.ZKE_VAN_IMAGE_TEST_READ',
                'VUS.ZKE_VAN_IMAGE_TEST_WRITE',
                'VUS.ZKE_VAN_IMAGE_VORAB_READ',
                'VUS.ZKE_VAN_IMAGE_VORAB_WRITE',
                'VUS.ZKE_VAN_IMAGE_SERIEN_READ',
                'VUS.ZKE_VAN_IMAGE_SERIEN_WRITE',
                'VUS.ZKE_VAN_SETTINGS_AUTODEPLOY_READ',
                'VUS.ZKE_VAN_SETTINGS_AUTODEPLOY_WRITE',
                'VUS.ZKE_VAN_SETTINGS_LOGLEVEL_READ',
                'VUS.ZKE_VAN_SETTINGS_LOGLEVEL_WRITE',
                'VUS.ZKE_VAN_SETTINGS_SMOKETESTDATA_READ',
                'VUS.ZKE_VAN_SETTINGS_SMOKETESTDATA_WRITE',
                'VUS.ZKE_VAN_SETTINGS_SNS_READ',
                'VUS.ZKE_VAN_SETTINGS_SNS_WRITE',
            ],
        };
        return of(JWTUserMock);
    }
}
