<div class="container-fluid vus-modal">
    <form [formGroup]="form">
        <div class="modal-header">
            <h3 class="modal-title">{{title}}</h3>
        </div>
        <ngb-alert [dismissible]="false">
            <span class="alert-title">Hinweis!</span>
            <p class="alert-text">Bitte beachten, dass der Vin-Listen Name in der Whitelist referenziert wird.</p>
        </ngb-alert>
        <div class="modal-body">
            <div class="row">
                <div class="col-6 form-group">
                    <label for="baureihe">Baureihe *</label>
                    <input autocomplete="off" class="form-control" id="baureihe" type="text" formControlName="baureihe"
                        aria-describedby="baureihe" [ngClass]="{'is-invalid' : inputIsInvalid('baureihe')}">
                    <div *ngIf="inputIsInvalid('baureihe')" class="invalid-feedback">
                        <div *ngIf="form.get('baureihe').errors.required">Pflichtfeld!</div>
                    </div>
                </div>
                <div class="col-6 form-group">
                    <label for="sparte">Sparte *</label>
                    <select class="form-control" formControlName="sparte" id="sparte" name="sparte"
                        [ngClass]="{'is-invalid' : inputIsInvalid('sparte')}">
                        <option *ngFor="let division of divisions" [ngValue]="division">{{division}}</option>
                    </select>
                    <div *ngIf="inputIsInvalid('sparte')" class="invalid-feedback">
                        <div *ngIf="form.get('sparte').errors.required">Pflichtfeld!</div>
                    </div>
                </div>
                <div class="col-12 form-group">
                    <label for="note">Notiz</label>
                    <textarea class="form-control" [ngClass]="{'is-invalid' : inputIsInvalid('note')}" id="note"
                        formControlName="note" rows="3"></textarea>
                    <div *ngIf="inputIsInvalid('note')" class="invalid-feedback">
                        <div>Die Notiz darf max. 255 Zeichen beinhalten!</div>
                    </div>
                </div>
                <div class="col-12 file-select-container">
                    <label for="vinListFile">Vin-Listen Datei</label>
                    <input autocomplete="off" class="form-control-file" id="vinListFile" type="file" formControlName="vinListFile" accept=".csv"
                        (change)="fileChanged($event)" [ngClass]="{'is-invalid' : inputIsInvalid('vinListFile')}">
                    <div *ngIf="inputIsInvalid('vinListFile')" class="invalid-feedback">
                        <div>Keine Datei ausgewählt!</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-secondary" (mousedown)="activeModal.dismiss('cancel')">
                        <span>Abbrechen</span>
                    </button>
                    <button class="btn btn-primary" (click)="uploadVINList()">
                        <span>{{button}}</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
