<span class="container-title" style="margin-bottom: 32px">Datenverwaltung - Testdaten</span>

<div class="row mt-2">
	<div class="col-12">
		<div class="vus-container">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="zke-nav-tabs">
                <li [ngbNavItem]="0" class="zke-nav-item">
                    <a ngbNavLink class="zke-nav-link">Stammdaten</a>
                    <ng-template ngbNavContent>
                        <app-data-management-master-data [dataPool]="dataPool" [dataPoolIdentifier]="dataPoolIdentifier"
                                                         [dataPoolEntitlement]="dataPoolEntitlement">
                        </app-data-management-master-data>
                    </ng-template>
                </li>
                <li [ngbNavItem]="1" class="zke-nav-item">
                    <a ngbNavLink class="zke-nav-link">Mapping</a>
                    <ng-template ngbNavContent>
                        <app-mapping [dataPool]="dataPool" [dataPoolIdentifier]="dataPoolIdentifier"
                                     [dataPoolEntitlement]="dataPoolEntitlement"></app-mapping>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="zke-nav-item">
                    <a ngbNavLink class="zke-nav-link">Steuerdaten</a>
                    <ng-template ngbNavContent>
                        <app-data-management-control-data [dataPool]="dataPool" [dataPoolIdentifier]="dataPoolIdentifier"
                                                          [dataPoolEntitlement]="dataPoolEntitlement">
                        </app-data-management-control-data>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
		</div>
	</div>
</div>
