import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CustomTableFooterComponent } from './custom-table-footer.component';

@NgModule({
    declarations: [CustomTableFooterComponent],
    imports: [CommonModule, NgbModule],
    exports: [CustomTableFooterComponent],
})
export class CustomTableFooterModule {}
