import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ControlDataModule } from '../components/control-data/control-data.module';
import { MappingModule } from '../components/mapping/mapping.module';
import { MasterDataModule } from '../components/master-data/master-data.module';
import { PreDataComponent } from './pre-data.component';

@NgModule({
    declarations: [PreDataComponent],
    imports: [
        CommonModule,
        MappingModule,
        NgbDropdownModule,
        HttpClientModule,
        NgbModule,
        MasterDataModule,
        ControlDataModule,
    ],
    exports: [PreDataComponent],
})
export class PreDataModule {}
