export function getStatusColor(code: number) {
    if (code) {
        if (code < 300 && code > 199) {
            return { 'background-color': '#008351' };
        }
        if (code < 400) {
            return { 'background-color': '#f7b500' };
        } else {
            return { 'background-color': '#bb1e10' };
        }
    }
}
