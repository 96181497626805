import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from '@shared/models/modal-result';

@Component({
    selector: 'app-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.scss'],
})
export class WarningComponent implements OnInit {
    @Input() headline = 'Achtung!';
    @Input() message: string;
    @Input() buttonNegative = 'Nein';
    @Input() buttonPositive = 'Ja';
    @Input() futureFeature: boolean;
    @Input() closingOption = true;

    ModalResult: ModalResult;

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {
        if (this.futureFeature) {
            this.message =
                'Diese Funktion steht aktuell noch nicht zur Verfügung!';
        }
    }

    close() {
        this.activeModal.close(ModalResult.WARNING_OK);
    }

    dismiss() {
        this.activeModal.dismiss(ModalResult.WARNING_CANCEL);
    }
}
