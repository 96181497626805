import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from '@env/environment';
import { DownloadService } from '@shared/services/download/download.service';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { SecurityService } from '@shared/services/security/security.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { UserManualService } from '@shared/services/user-manual/user-manual.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    username = 'Benutzername unbekannt!';
    switch;
    isAdmin: boolean;
    private apiUrl: string;
    private presignedUrl: string;

    private notifier = new Subject();

    constructor(
        private securityService: SecurityService,
        private switchService: SwitchService,
        private localStorageService: LocalStorageService,
        private fb: UntypedFormBuilder,
        private userManualService: UserManualService,
        private downloadService: DownloadService
    ) {}

    ngOnInit() {
        this.isAdmin = this.securityService.isAdmin();
        this.switch = this.fb.control('');

        if (this.isAdmin) {
            this.switch.patchValue('CAR');
            const storedSwitch = this.localStorageService.getItem('switch');
            if (storedSwitch) {
                this.switch.patchValue(storedSwitch);
            }
            this.switchService.switch.next(this.switch.value);
        }

        if (
            this.securityService.user &&
            this.securityService.user.upn &&
            this.securityService.user.upn.length
        ) {
            this.username = this.securityService.user.upn;
        }
        this.switch.valueChanges
            .pipe(takeUntil(this.notifier))
            .subscribe(event => this.onSwitchChange(event));
        this.getPresignedUrlFromService();
    }

    logout() {
        this.localStorageService.clearExcept(['savedVins','savedZksnrCebas','savedZksnrCebas']);
        window.location.href = `${environment.logoutUrl}/idp/startSLO.ping`;
    }

    private onSwitchChange(event: string) {
        this.switchService.switch.next(event);
        this.localStorageService.setItem('switch', event);
        window.location.reload();
    }

    ngOnDestroy() {
        this.notifier.next();
        this.notifier.complete();
    }

    downloadFile() {
        this.userManualService
            .getExportFileFromPresignedUrl(this.presignedUrl)
            .subscribe((res: Blob) => {
                this.downloadService.downloadFileWithName(
                    res,
                    'ZKE-User-Manual.pdf'
                );
            });
    }

    viewInBrowser() {
        window.open(this.presignedUrl);
    }

    private getPresignedUrlFromService() {
        this.apiUrl = environment.commonApiUrl;
        this.userManualService
            .getPreSignedUrl(this.apiUrl)
            .subscribe(userManual => {
                this.presignedUrl = userManual.presignedUrl.toString();
            });
    }
}
