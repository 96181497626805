import { Component, Input, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { permissions } from '@shared/constants/permissions';
import { SnsTopic } from '@shared/models/sns-notification/sns-topic';
import { SnsTopicStatus } from '@shared/models/sns-notification/sns-topic-status';
import { SnsTopicSubscriber } from '@shared/models/sns-notification/sns-topic-subscriber';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { SnsNotificationService } from '@shared/services/sns-notification/sns-notification.service';

@Component({
    selector: 'app-notification-detail',
    templateUrl: './notification-detail.component.html',
    styleUrls: ['./notification-detail.component.scss'],
})
export class NotificationDetailComponent implements OnInit {
    @Input()
    topic: SnsTopic;

    @Input()
    subscribers: SnsTopicSubscriber[];

    @Input()
    ROOT_URL: string;

    sortConfig = [{ prop: 'email', dir: 'desc' }];
    topicStatus = SnsTopicStatus;
    form: UntypedFormGroup;
    requiredWriteRoles = [
        permissions.PKW.SETTINGS_SNS_WRITE,
        permissions.VAN.SETTINGS_SNS_WRITE,
    ];

    constructor(
        private activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private snsNotificationService: SnsNotificationService,
        private fb: UntypedFormBuilder,
        private notificationService: ErrorHandlerService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            email: [
                '',
                [
                    Validators.required,
                    Validators.pattern(
                        '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
                    ),
                ],
            ],
        });
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    deleteEntry(subscriber: SnsTopicSubscriber) {
        const modalRef = this.modalService.open(WarningComponent);
        const subscriberId: string = subscriber.id.split(':').pop();
        modalRef.componentInstance.closingOption = false;
        modalRef.componentInstance.message = `<p>Wollen Sie die E-Mail Adresse ${subscriber.email} wirklich entfernen?</p>`;
        modalRef.result.then(() => {
            this.snsNotificationService
                .deleteSubscriber(this.ROOT_URL, this.topic.name, subscriberId)
                .subscribe(() => {
                    this.subscribers = [...this.subscribers].filter(
                        tempSubscribers => tempSubscribers.id !== subscriber.id
                    );
                    modalRef.dismiss();
                    this.notificationService.showSuccess(
                        '',
                        `${subscriber.email} wurde aus ${this.topic.name} entfernt`
                    );
                });
        });
    }

    save() {
        if (this.form.valid) {
            const email = this.form.get('email').value;
            this.snsNotificationService
                .saveSubscriber(this.ROOT_URL, this.topic.name, email)
                .subscribe((subscriber: SnsTopicSubscriber) => {
                    this.subscribers = [...this.subscribers, subscriber];
                    this.notificationService.showSuccess(
                        '',
                        `${email} wurde eine Anfrage für ${this.topic.name} gesendet`
                    );
                });
        } else {
            this.form.get('email').markAsTouched();
        }
    }
}
