export enum SmoketestStatus {
    SMOKE_TEST_SUCCESS = 'Erfolgreich',
    SMOKE_TEST_FAILED = 'Fehlgeschlagen',
    SMOKE_TEST_PROCESSING = 'Ausstehend',
    SMOKE_TEST_IGNORED = 'Kein Smoketest (Manueller Upload)',
    SMOKE_TEST_ROLLBACK = "Rollback"
}

class SmokeTestStatusDetails {
    constructor(
        public enumType: SmoketestStatus,
        public id: number,
        public colorClass: string
    ) {}
}

const SmokeTestStati = [
    new SmokeTestStatusDetails(
        SmoketestStatus.SMOKE_TEST_SUCCESS,
        0,
        'icon-green'
    ),
    new SmokeTestStatusDetails(
        SmoketestStatus.SMOKE_TEST_FAILED,
        1,
        'icon-red'
    ),
    new SmokeTestStatusDetails(
        SmoketestStatus.SMOKE_TEST_PROCESSING,
        2,
        'icon-yellow'
    ),
    new SmokeTestStatusDetails(
        SmoketestStatus.SMOKE_TEST_IGNORED,
        3,
        'icon-grey'
    ),
    new SmokeTestStatusDetails(
        SmoketestStatus.SMOKE_TEST_ROLLBACK,
        4,
        'icon-white'
    ),
];

export function getSmokeTestStatusEnumById(id: number): string {
    const filteredSmokeTestStatus = SmokeTestStati.find(
        smokeStatusObject => smokeStatusObject.id === id
    );
    return filteredSmokeTestStatus ? filteredSmokeTestStatus.enumType : '';
}

export function getSmokeTestStatusColorById(id: number): string {
    const filteredSmokeTestStatus = SmokeTestStati.find(
        smokeStatusObject => smokeStatusObject.id === id
    );
    return filteredSmokeTestStatus ? filteredSmokeTestStatus.colorClass : '';
}
