import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ImageManagementComponent } from './image-management.component';
import { PreDataImagesModule } from './pre-data-images/pre-data-images.module';
import { ProductiveImageModule } from './productive-image/productive-image.module';
import { SeriesImagesModule } from './series-images/series-images.module';
import { TestImagesModule } from './test-images/test-images.module';

@NgModule({
    declarations: [ImageManagementComponent],
    imports: [
        CommonModule,
        ProductiveImageModule,
        TestImagesModule,
        PreDataImagesModule,
        SeriesImagesModule,
        RouterModule,
        FormsModule,
    ],
    exports: [ImageManagementComponent],
})
export class ImageManagementModule {}
