import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '@shared/models/notification';

import { Xml2jsonService } from '../xml2json/xml2json.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorServiceService {
    private defaultErrorTitle = 'Service Fehler';

    constructor(private xml2jsonService: Xml2jsonService) {}

    getClientMessage(error: Error): Notification {
        if (!navigator.onLine) {
            return new Notification(
                'Netzwerk Fehler',
                'Bitte überprüfen Sie Ihre Internetverbindung'
            );
        }

        return new Notification(
            'Client Fehler',
            error.message ? error.message : error.toString()
        );
    }

    getServerMessage(error: HttpErrorResponse): Notification {
        const title = error.error.MESSAGE_TYPE
            ? error.error.MESSAGE_TYPE
            : this.defaultErrorTitle;
        const text = error.error.text ? error.error.text : error.statusText;
        const code = error.error.MESSAGE_CODE
            ? error.error.MESSAGE_CODE
            : error.status;
        const message = `[${code}] ${text}`;

        return new Notification(title, message);
    }

    getXMLServerMessage(error: HttpErrorResponse): Notification {
        const errorResponse = this.xml2jsonService.convert(error.error);

        const title =
            errorResponse && //
            errorResponse.ErrorMessageDto && //
            errorResponse.ErrorMessageDto.message_TYPE && //
            errorResponse.ErrorMessageDto.message_TYPE._text //
                ? errorResponse.ErrorMessageDto.message_TYPE._text
                : this.defaultErrorTitle;

        const text =
            errorResponse && //
            errorResponse.ErrorMessageDto && //
            errorResponse.ErrorMessageDto.text && //
            errorResponse.ErrorMessageDto.text._text //
                ? errorResponse.ErrorMessageDto.text._text
                : error.statusText;

        const code =
            errorResponse && //
            errorResponse.ErrorMessageDto && //
            errorResponse.ErrorMessageDto.message_CODE && //
            errorResponse.ErrorMessageDto.message_CODE._text //
                ? errorResponse.ErrorMessageDto.message_CODE._text
                : error.status;

        const message = `[${code}] ${text}`;
        return new Notification(title, message);
    }
}
