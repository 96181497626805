import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-unauthorized-modal',
    templateUrl: './unauthorized-modal.component.html',
})
export class UnauthorizedModalComponent {
    @Input() title: string;
    @Input() message: string;
    @Input() showLogin = false;

    constructor() {}

    redirectToLogin() {
        window.location.reload();
    }
}
