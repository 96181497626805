import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { MediaType } from '@shared/constants/media-type';
import { apiPaths } from '@shared/constants/paths';
import { VedocData } from '@shared/models/vedoc-data';
import { Observable } from 'rxjs';
import { sanitizeUrl } from 'src/app/core/utils/sanitizeUrl/sanitizeUrl';

@Injectable({
    providedIn: 'root',
})
export class ZkeService {
    private static CEBAS_ETM_ROUTE = '/api/v3/process/inbound/calculate';

    constructor(private http: HttpClient) {}

    calculate(url: string, target: string, zkeRequestXML: any): Observable<{}> {
        const sanitizedUrl = sanitizeUrl(
            `${url}/${this.getCalculationTarget(target)}${
                ZkeService.CEBAS_ETM_ROUTE
            }`
        );
        return this.doCalculateRequest(sanitizedUrl, zkeRequestXML);
    }

    calculateWithVedocManipulation(
        url: string,
        target: string,
        zkeRequestXML: any,
        fin: string,
        ttz: string,
        codes: string[]
    ): Observable<{}> {
        const sanitizedUrl = sanitizeUrl(
            `${url}/${this.getCalculationTarget(target)}${
                ZkeService.CEBAS_ETM_ROUTE
            }/${fin}/${ttz}?codes=${codes}`
        );
        return this.doCalculateRequest(sanitizedUrl, zkeRequestXML);
    }

    getCalculationTarget(target: string): string {
        return target ? target : apiPaths.calculation;
    }

    doCalculateRequest(url: string, request: any): Observable<{}> {
        return this.http.post(url, request, {
            headers: {
                'Content-Type': MediaType.xmlMediaType,
                Accept: MediaType.xmlMediaType,
            },
            observe: 'response',
            responseType: 'text',
        });
    }

    getVedocData(vin: string): Observable<VedocData> {
        const sanitizedUrl = sanitizeUrl(
            `${environment.carApiUrl}/${apiPaths.vedocGateway}/${vin}`
        );
        return this.http.get<VedocData>(sanitizedUrl, {
            headers: {
                'Content-Type': MediaType.jsonUtf8MediaType,
                Accept: MediaType.jsonUtf8MediaType,
            },
            observe: 'body',
            responseType: 'json',
        });
    }
}
