<div *ngFor="let menuItem of menu">
    <div *ngIf="menuItem.data?.label">
        <ng-container *appRequiredRoles="menuItem.data.oneOfThem">
            <div class="menu-item"
                 [ngClass]="isActiveMenuItem('/' + menuItem.path)"
                 (click)="navigateByUrl(menuItem.path)">
                <span>{{menuItem.data?.label}}</span>
            </div>
        </ng-container>
        <div *ngFor="let subMenuItem of menuItem.children">
             <div *ngIf="subMenuItem.data?.label">
                 <ng-container *appRequiredRoles="subMenuItem.data.oneOfThem">
                     <div class="sub-menu-item"
                         [ngClass]="isActiveMenuItem('/' + menuItem.path + '/' + subMenuItem.path)"
                         (click)="navigateByUrl(menuItem.path + '/' + subMenuItem.path)">
                         <span>{{subMenuItem.data?.label}}</span>
                     </div>
                 </ng-container>
             </div>
        </div>
    </div>
</div>
