import { Injectable } from '@angular/core';
import { CalculationInstance } from '@shared/models/calculation-instance';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export abstract class AbstractCalculationInstanceService {
    abstract getCalculationInstances(
        url: string
    ): Observable<CalculationInstance[]>;
}
