import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomTableFooterModule } from '@shared/components/custom-table-footer/custom-table-footer.module';
import { IconButtonModule } from '@shared/components/icon-button/icon-button.module';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import { ReasonDialogModule } from '@shared/components/reason-dialog/reason-dialog.module';
import { ToggleButtonModule } from '@shared/components/toggle-button/toggle-button.module';
import { RoleDirectiveModule } from '@shared/directives/role.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { CreateImageModule } from '../create-image/create-image.module';
import { ZkeImageFileListModule } from './image-file-list/images.module';
import { ImagesDatatableComponent } from './images-datatable/images-datatable.component';
import { ImagesComponent } from './images.component';

@NgModule({
    declarations: [ImagesComponent, ImagesDatatableComponent],
    imports: [
        CommonModule,
        NgxDatatableModule,
        ToggleButtonModule,
        FontAwesomeModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        CreateImageModule,
        RoleDirectiveModule,
        IconButtonModule,
        ReasonDialogModule,
        LoadingSpinnerModule,
        ZkeImageFileListModule,
        CustomTableFooterModule,
    ],
    exports: [ImagesComponent],
})
export class ImagesModule {}
