import { permissions } from '@shared/constants/permissions';
import { Datapool } from '@shared/models/datapool/datapool';

export class MetadataRoleConfig {
    private testDatapool = Datapool.TEST;
    private vorabDatapool = Datapool.VORAB;
    private serienDatapool = Datapool.SERIE;
    private roleNotAllowed = 'NOT_ALLOWED';

    public deleteRoleConfig = [
        {
            roles: [
                permissions.PKW.IMAGE_TEST_WRITE,
                permissions.VAN.IMAGE_TEST_WRITE,
            ],
            dataPool: this.testDatapool,
        },
        {
            roles: [
                permissions.PKW.IMAGE_VORAB_WRITE,
                permissions.VAN.IMAGE_VORAB_WRITE,
            ],
            dataPool: this.vorabDatapool,
        },
        {
            roles: [this.roleNotAllowed],
            dataPool: this.serienDatapool,
        },
    ];

    public writeRoleConfig = [
        {
            roles: [
                permissions.PKW.IMAGE_TEST_WRITE,
                permissions.VAN.IMAGE_TEST_WRITE,
            ],
            dataPool: this.testDatapool,
        },
        {
            roles: [
                permissions.PKW.IMAGE_VORAB_WRITE,
                permissions.VAN.IMAGE_VORAB_WRITE,
            ],
            dataPool: this.vorabDatapool,
        },
        {
            roles: [
                permissions.PKW.IMAGE_SERIEN_WRITE,
                permissions.VAN.IMAGE_SERIEN_WRITE,
            ],
            dataPool: this.serienDatapool,
        },
    ];
}
