import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { IconButtonModule } from '@shared/components/icon-button/icon-button.module';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { EntitlementsModalComponent } from './entitlements/entitlements-modal.component';
import { NewDatapoolModalComponent } from './new-datapool/new-datapool-modal.component';

@NgModule({
    declarations: [NewDatapoolModalComponent, EntitlementsModalComponent],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        NgbModalModule,
        LoadingSpinnerModule,
        IconButtonModule,
    ],
    exports: [NewDatapoolModalComponent, EntitlementsModalComponent],
})
export class TestDataModalModule {}
