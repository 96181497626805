<div class="container-fluid vus-modal">
    <form [formGroup]="form">
        <div class="modal-header">
            <h3 class="modal-title">{{title}}</h3>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6 form-group">
                    <label for="baureihe">Baureihe *</label>
                    <input autocomplete="off" class="form-control" id="baureihe" type="text" formControlName="baureihe"
                        aria-describedby="baureihe" [readonly]="overwrite"
                        [ngClass]="{'is-invalid' : inputIsInvalid('baureihe')}">
                    <div *ngIf="inputIsInvalid('baureihe')" class="invalid-feedback">
                        <div>Pflichtfeld!</div>
                    </div>
                </div>
                <div class="col-6 form-group">
                    <label for="sparte">Sparte *</label>
                    <select class="form-control" formControlName="sparte" id="sparte" name="sparte"
                        [ngClass]="{'is-invalid' : inputIsInvalid('sparte')}">
                        <option *ngFor="let division of divisions" [ngValue]="division">{{division}}</option>
                    </select>
                    <div *ngIf="inputIsInvalid('sparte')" class="invalid-feedback">
                        <div>Pflichtfeld!</div>
                    </div>
                </div>
                <div class="col-12 form-group">
                    <label for="note">Notiz</label>
                    <textarea class="form-control" [ngClass]="{'is-invalid' : inputIsInvalid('note')}" id="note"
                        formControlName="note" rows="3"></textarea>
                    <div *ngIf="inputIsInvalid('note')" class="invalid-feedback">
                        <div>Das Notiz darf max. 255 Zeichen beinhalten!</div>
                    </div>
                </div>
                <div class="col-12 file-select-container">
                    <input autocomplete="off" class="form-control-file" type="file" formControlName="whitelistFile"
                        (change)="fileChanged($event)" [ngClass]="{'is-invalid' : inputIsInvalid('whitelistFile')}">
                    <div *ngIf="inputIsInvalid('whitelistFile')" class="invalid-feedback">
                        <div>Keine Datei ausgewählt!</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" (mousedown)="activeModal.dismiss('cancel')">
                    <span>Abbrechen</span>
                </button>
                <button class="btn btn-primary" (click)="import()">
                    <span>{{button}}</span>
                </button>
            </div>
        </div>
    </form>
</div>
