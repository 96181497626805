<h5>
    <div [ngClass]="getBackgroundLayerClass()">
        <div class="spinner-box">
            <div class="spinner-border spinner" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
        <div class="text-box text-center" [innerHtml]="text">
            {{text}}
        </div>
    </div>
</h5>