<div class="row">
    <div class="col-12">
        <app-loading-spinner *ngIf="!isDataFetched" [text]="'Loading'" [overlay]="true"></app-loading-spinner>
        <app-image-selector [apiUrl]="ROOT_URL" (didReceiveResponse)="didReceiveResponse($event)" (nameEmitter)="setTargetEndpoint($event)"></app-image-selector>
    </div>
    <div class="col-12" *ngIf="checkTargetEndpoint()">
        <app-zke-request-formular
            [targetEndpoint]="targetEndpoint"
            [switchSetting]="switchSetting"
            (updateRequested)="updateRequest($event)"
            (inputReset)="resetInput()"
            (calculationRequested)="performCalculation($event)">
        </app-zke-request-formular>
    </div>
    <div class="col-12 col-md-6" *ngIf="checkTargetEndpoint()">
        <app-xentry-request-view [xmlRequest]="xmlRequestEmitter"></app-xentry-request-view>
    </div>
    <div class="col-12 col-md-6" *ngIf="checkTargetEndpoint()">
        <app-zke-response-view [response]="calculationResponseEmitter"></app-zke-response-view>
    </div>
</div>
