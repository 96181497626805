/**
 * MediaType constants for http requests
 */
export abstract class MediaType {
    public static zipMediaType = 'application/zip';
    public static jsonUtf8MediaType = 'application/json; charset=utf-8';
    public static xmlMediaType = 'application/xml';
    public static csvUtf8MediaType =
        'text/comma-separated-values;charset=UTF-8';
    public static textPlainUtf8MediaType = 'text/plain;charset=UTF-8';
    public static textCommaSeparatedUtf8MediaType =
        'text/comma-separated-values;charset=UTF-8';
    public static csvTextMediaType = 
        'text/csv'
    public static textPlainWithoutUTF8MediaType = 'text/plain'
}
