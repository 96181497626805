<div class="card vus-card" [ngClass]="isSerienDataPool() ? 'mt-4' : ''">
    <div class="card-body vus-card-body">
        <div class="vus-title">
            <span>{{headerTitle || 'Images'}}</span>
            <div class="round-button-container">
                <ng-container *ngIf="!isSerienDataPool()">
                    <icon-button (action)="refreshDatatable()" [buttonType]="'Page'" [iconSelector]="'redo'">
                    </icon-button>
                    <ng-container *appRequiredRoles="writeRole">
                        <icon-button (action)="createNewImage()" [buttonType]="'Page'" [iconSelector]="'plus'">
                        </icon-button>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="!isTestDataPool()">
            <app-images-datatable [roleConfig]="roleConfig" [dataPool]="dataPool"></app-images-datatable>
        </ng-container>

        <ng-container *ngIf="isTestDataPool()">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="uploadToolTabset zke-nav-tabs">
                <li [ngbNavItem]="tab" *ngFor="let tab of tabs; let index = index" id="{{tab}}" class="zke-nav-item">
                    <a ngbNavLink class="zke-nav-link">{{tab}}</a>
                    <ng-template ngbNavContent>
                        <app-images-datatable [roleConfig]="roleConfig" [dataPool]="dataPool"
                                              [dataPoolType]="active"></app-images-datatable>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </ng-container>

    </div>
</div>
