import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ROLE_ENV_MAPPING, URLS } from '@shared/constants/urls';
import { SecurityService } from '@shared/services/security/security.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private securityService: SecurityService) {}

    getUrlBasedOnPermissionAndManualSwitch(
        urlIdentifier: string,
        manualSwitch: string
    ): string {
        const role = this.securityService.getUserRole();
        let apiUrl = environment[ROLE_ENV_MAPPING[role]];

        if (manualSwitch) {
            apiUrl = environment[ROLE_ENV_MAPPING[manualSwitch]];
        }

        return manualSwitch
            ? `${apiUrl}/${URLS[urlIdentifier][manualSwitch]}`
            : `${apiUrl}/${URLS[urlIdentifier][role]}`;
    }
}
