import { Component, EventEmitter, Input, Output } from '@angular/core';

enum buttonType {
    ROW = 'Row',
    EXPANDER = 'Expander',
    PAGE = 'Page',
    CHECKBOX = 'Checkbox',
    RADIO = 'Radio',
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
    @Output() action = new EventEmitter<boolean>();
    @Input() toolTipDescription: string;
    @Input() iconSelector: string;
    @Input() visible = true;
    @Input() buttonType: string = buttonType.ROW;
    @Input() toggle;
    @Input() disabled = false;

    constructor() {}

    clickAction() {
        this.action.emit(this.toggle);
    }

    isButtonTypeRow(): boolean {
        return this.buttonType === buttonType.ROW;
    }

    isButtonTypeExpander(): boolean {
        return this.buttonType === buttonType.EXPANDER;
    }

    isButtonTypePage(): boolean {
        return this.buttonType === buttonType.PAGE;
    }

    isButtonTypeCheckbox(): boolean {
        return this.buttonType === buttonType.CHECKBOX;
    }

    isButtonTypeRadio(): boolean {
        return this.buttonType === buttonType.RADIO;
    }
}
