import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HintModule } from '@shared/components/hint/hint.module';
import { XmlPipeModule } from '@shared/pipes/xml/xml.pipe.module';

import { ZkeResponseViewComponent } from './zke-response-view.component';

@NgModule({
    declarations: [ZkeResponseViewComponent],
    imports: [CommonModule, XmlPipeModule.forRoot(), HintModule],
    exports: [ZkeResponseViewComponent],
})
export class ZkeResponseViewModule {}
