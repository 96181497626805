import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CalculateInstanceSelectorComponent } from './calculate-instance-selector.component';

@NgModule({
    declarations: [CalculateInstanceSelectorComponent],
    imports: [CommonModule, FormsModule],
    exports: [CalculateInstanceSelectorComponent],
})
export class CalculateInstanceSelectorModule {}
