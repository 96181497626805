import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WarningModule } from '@shared/components/warning/warning.module';

import { UploadFormularComponent } from './upload-formular.component';

@NgModule({
    declarations: [UploadFormularComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, WarningModule],
    exports: [UploadFormularComponent],
})
export class UploadFormularModule {}
