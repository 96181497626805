import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import { CalculateComponent } from './calculate.component';
import { ImageSelectorModule } from './image-selector/image-selector.module';
import { XentryRequestViewModule } from './xentry-request-view/xentry-request-view.module';
import { ZkeRequestFormularModule } from './zke-request-formular/zke-request-formular.module';
import { ZkeResponseViewModule } from './zke-response-view/zke-response-view.module';

@NgModule({
    declarations: [CalculateComponent],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        ZkeResponseViewModule,
        XentryRequestViewModule,
        ZkeRequestFormularModule,
        ImageSelectorModule,
        LoadingSpinnerModule
    ],
    exports: [CalculateComponent],
})
export class CalculateModule {}
