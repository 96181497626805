<app-loading-spinner *ngIf="isNewProductiveImage" [text]="text" [overlay]="true"></app-loading-spinner>
<ngx-datatable #imagesTable class="material" [columnMode]="'force'" [rows]="images" [draggable]="false"
               [headerHeight]="50" [rowHeight]="56" [footerHeight]="50" [sorts]="sortConfig"
               [loadingIndicator]="!images || isDataLoading" [limit]="dataTableLimit">
    <ngx-datatable-column name="Image-Tag" prop="imageTag" [width]="225" [canAutoResize]="false" [resizeable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ng-container *ngIf="isSerienDataPool()">
        <ngx-datatable-column name="Promotet am" prop="promotedAt" [width]="200" [canAutoResize]="false"
                              [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span *ngIf="value">{{value | date: 'dd.MM.yy'}}, {{value | date: 'HH:mm'}}</span>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf="!isSerienDataPool()">
        <ngx-datatable-column name="Erstellzeitpunkt" prop="imageCreationDate" [width]="200" [canAutoResize]="true"
                              [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span *ngIf="value">{{value | date: 'dd.MM.yy'}}, {{value | date: 'HH:mm'}}</span>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf="isCardMeine() || isCardUserspezifisch()">
        <ngx-datatable-column name="Datenpool" prop="userFriendlyName" [width]="200" [canAutoResize]="true"
                              [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{value}}</span>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf="isCardUserspezifisch()">
        <ngx-datatable-column name="Autor" prop="owner" [width]="130" [canAutoResize]="true" [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{value}}</span>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ngx-datatable-column name="Kommentar" prop="note" [resizeable]="false">
        <ng-template let-value='value' let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value" class="long-text" placement="top" [ngbTooltip]="value" data-container="body"
                  tooltipClass="note-tooltip">{{ value }}</span>
        </ng-template>
    </ngx-datatable-column>
    <ng-container *ngIf="isSerienDataPool()">
        <ngx-datatable-column name="Änderungsgrund" prop="changeCause" [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{value}}</span>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf="isSerienDataPool()">
        <ngx-datatable-column name="Produktiv seit" prop="deployedFrom" [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{ getDeployedFromDateString(value, row.deployedUntil) }}</span>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf='isSerienDataPool()'>
        <ngx-datatable-column name='Produktiv bis' prop='deployedUntil' [resizeable]='false'>
            <ng-template let-value='value' let-row='row' ngx-datatable-cell-template>
                <span>{{ getDeployedUntilDateString(row.deployedFrom, value) }}</span>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf="isVorabDataPool()">
        <ngx-datatable-column name="Smoketest Status" prop="smokeTestStatus" [width]="300" [canAutoResize]="true"
                              [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div class="combined-column">
                    <fa-icon icon="circle" [ngClass]="getSmokeTestStatusColor(row)"></fa-icon>
                    <span>{{getSmokeTestStatus(row)}}</span>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf="!isTestDataPool()">
        <ngx-datatable-column name="User" prop="createdBy" [width]="200" [canAutoResize]="true"
                              [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{value}}</span>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
    <ngx-datatable-column name="Aktionen" [width]="150" [canAutoResize]="false" [resizeable]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <ng-container *appRequiredRoles="deleteRole">
                <icon-button (action)="delete(row)" *ngIf="imageIsDeletable(row)" [iconSelector]="'trash'"
                             [toolTipDescription]="'Image löschen'">
                </icon-button>
            </ng-container>
            <icon-button (action)="showImageFileList(row)" [visible]="true"
                         [toolTipDescription]="'Image Dateiliste anzeigen'" [iconSelector]="'eye'"></icon-button>

            <icon-button (action)="showImageDeploymentHistory(row)" *ngIf="isSerienDataPool()"
                         [toolTipDescription]="'Deployment Historie anzeigen'" [iconSelector]="'calendar'"></icon-button>
        </ng-template>

    </ngx-datatable-column>
    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                     let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                     let-isVisible="isVisible">
            <custom-table-footer (setLimit)="setLimit($event)" [rowCount]="rowCount" [element]="'Images'">
            </custom-table-footer>

            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                             [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                             [page]="curPage"
                             [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                             (change)="imagesTable.onFooterPage($event)">
            </datatable-pager>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>
