import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Datapool,
    isSerienDataPool,
    isTestDataPool,
} from '@shared/models/datapool/datapool';
import { ModalResult } from '@shared/models/modal-result';
import { RoleConfig } from '@shared/models/role-config';
import { AbstractMetadataService } from '@shared/services/metadata/abstract-metadata.service';
import { ZkePkwMetadataService } from '@shared/services/metadata/zke-pkw-metadata/zke-pkw-metadata.service';

import { CreateImageComponent } from '../create-image/create-image.component';
import { ImagesDatatableComponent } from './images-datatable/images-datatable.component';

@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.scss'],
    providers: [
        {
            provide: AbstractMetadataService,
            useExisting: ZkePkwMetadataService,
        },
    ],
})
export class ImagesComponent implements OnInit {
    @ViewChild(ImagesDatatableComponent)
    dataTable: ImagesDatatableComponent;

    @Input() roleConfig: RoleConfig;
    @Input() headerTitle?: string;
    @Input() dataPool?: Datapool;

    writeRole: string[];
    tabs = ['Meine', 'Userspezifisch'];
    active = this.tabs[0];

    constructor(private modalService: NgbModal) {}

    ngOnInit() {
        if (!this.isSerienDataPool()) {
            this.writeRole = this.roleConfig.roles;
        }
    }

    createNewImage() {
        let modalRef;

        if (isTestDataPool(this.dataPool)) {
            modalRef = this.modalService.open(CreateImageComponent, {
                windowClass: 'modalBig',
            });

            modalRef.componentInstance.dataPoolType = this.active;
        } else {
            modalRef = this.modalService.open(CreateImageComponent);
        }

        modalRef.componentInstance.dataPool = this.dataPool;

        modalRef.result.then(
            result => {
                if (result === ModalResult.CREATE_IMAGE_OK) {
                    this.refreshDatatable();
                }
            },
            error => {}
        );
    }

    refreshDatatable() {
        this.dataTable.getImages();
    }

    isTestDataPool(): boolean {
        return isTestDataPool(this.dataPool);
    }

    isSerienDataPool(): boolean {
        return isSerienDataPool(this.dataPool);
    }
}
