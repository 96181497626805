import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImagesModule } from '../images/images.module';
import { TestImagesComponent } from './test-images.component';

@NgModule({
    declarations: [TestImagesComponent],
    imports: [CommonModule, ImagesModule],
    exports: [TestImagesComponent],
})
export class TestImagesModule {}
