import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { commonPaths } from '@shared/constants/paths';
import { SecurityService } from '@shared/services/security/security.service';
import { Observable } from 'rxjs';

@Injectable()
export class AppAuthGuard {
    path: ActivatedRouteSnapshot[];

    constructor(
        private router: Router,
        private securityService: SecurityService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.isAccessAllowed(route, state);
    }

    isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return new Promise(async resolvePromise => {
            if (!!route.data.requiredRoles) {
                this.checkRoles(route.data.requiredRoles).then(
                    (authorized: boolean) => {
                        if (!authorized) {
                            this.router.navigate([commonPaths.forbidden]);
                        }
                        resolvePromise(authorized);
                    }
                );
            } else {
                this.checkRoleAtLeastOne(route.data.oneOfThem).then(
                    (authorized: boolean) => {
                        if (!authorized) {
                            this.router.navigate([commonPaths.forbidden]);
                        }
                        resolvePromise(authorized);
                    }
                );
            }
        });
    }

    /**
     * Check if at least one role is matching
     *
     * @param roles which are getting checked
     */
    checkRoleAtLeastOne(roles: string[]): Promise<boolean> {
        return new Promise(resolve => {
            roles.map(permission => {
                if (
                    this.securityService.user &&
                    this.securityService.user.permissions &&
                    this.securityService.user.permissions.indexOf(permission) >
                        -1
                ) {
                    resolve(true);
                }
            });
            resolve(false);
        });
    }

    /**
     * Check if all of the given roles are matching with the roles in keycloak
     *
     * @param requiredRoles which are getting checked if they match
     */
    checkRoles(requiredRoles: string[]): Promise<boolean> {
        return new Promise(resolve => {
            let foundRoles = 0;

            if (!requiredRoles || requiredRoles.length === 0) {
                resolve(true);
            } else {
                requiredRoles.map(permissions => {
                    if (
                        this.securityService.user &&
                        this.securityService.user.permissions &&
                        this.securityService.user.permissions.indexOf(
                            permissions
                        ) > -1
                    ) {
                        foundRoles++;
                    }
                });
            }

            if (foundRoles === requiredRoles.length) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    }
}
