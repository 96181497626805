export class AscMetadata {
    public sessionID: string;
    public requestID: string;
    public platform: string;
    public service: string;
    public application = {
        name: '',
        version: '',
    };
    public user = {
        id: '',
    };
    public destination = {
        service: '',
        action: '',
    };
}
