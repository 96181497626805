import { Component } from '@angular/core';
import { AbstractMetadataService } from '@shared/services/metadata/abstract-metadata.service';
import { ZkePkwMetadataService } from '@shared/services/metadata/zke-pkw-metadata/zke-pkw-metadata.service';

@Component({
    selector: 'app-expert-tool',
    templateUrl: './expert-tool.component.html',
    styleUrls: ['./expert-tool.component.scss'],
    providers: [
        {
            provide: AbstractMetadataService,
            useExisting: ZkePkwMetadataService,
        },
    ],
})
export class ExpertToolComponent {
    constructor() {}
}
