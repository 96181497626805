import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CODE_017_HINT } from '@shared/constants/app-constants';
import { CalculationResponse } from '@shared/interfaces/calculation-response';
import { ZKEResponse } from '@shared/models/zke-response';
import { Code17Service } from '@shared/services/code-17/code-17.service';
import { Observable } from 'rxjs';
import { getStatusColor } from 'src/app/core/utils/getStatusColor/getStatusColor';

@Component({
    selector: 'app-zke-response-view',
    templateUrl: './zke-response-view.component.html',
    styleUrls: ['./zke-response-view.component.scss'],
})
export class ZkeResponseViewComponent implements OnInit {
    @Input() response: EventEmitter<CalculationResponse>;

    zkeResponse: ZKEResponse;
    code: number;
    statusColor: any;
    hint = CODE_017_HINT;
    showHint$: Observable<boolean>;

    constructor(private code17Service: Code17Service) {}

    ngOnInit(): void {
        this.showHint$ = this.code17Service.showHint;
        if (this.response) {
            this.response.subscribe(data => {
                this.code = data.code;
                this.zkeResponse = (data.payload as unknown) as ZKEResponse;
                this.statusColor = getStatusColor(this.code);
            });
        }
    }
}
