import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import {
    CalculationInstance,
    CalculationInstanceAdapter,
} from '@shared/models/calculation-instance';
import { AbstractCalculationInstanceService } from '@shared/services/calculation-instances/abstract-calculation-instance.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ZkePkwCalculationInstancesService extends AbstractCalculationInstanceService {
    constructor(
        private httpClient: HttpClient,
        private adapter: CalculationInstanceAdapter
    ) {
        super();
    }

    getCalculationInstances(url: string): Observable<CalculationInstance[]> {
        return this.httpClient
            .get(`${url}/${apiPaths.controllerImages}?is_active=true`)
            .pipe(
                map((data: any[]) => data.map(item => this.adapter.adapt(item)))
            );
    }
}
