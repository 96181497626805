import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { menuPaths } from '@shared/constants/paths';
import { UserDatapool } from '@shared/models/datapool/user-datapool';
import {
    UserDatapoolEntitlement,
    setEntitlementText,
} from '@shared/models/datapool/user-datapool-entitlement';
import { SelectedUserDataPoolService } from '@shared/services/datapool/userDataPool/selected-user-datapool/selected-user-datapool.service';
import { UserDataPoolService } from '@shared/services/datapool/userDataPool/user-datapool.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';

import { EntitlementsModalComponent } from '../modal/entitlements/entitlements-modal.component';

@Component({
    selector: 'app-test-data-list-datatable',
    templateUrl: './test-data-list-datatable.component.html',
})
export class TestDataListDatatableComponent {
    @Input() data: UserDatapool[];
    @Input() type: string;
    @Input() apiUrl: string;
    @Output() refreshUserdataPools = new EventEmitter<any>();

    sortConfig = [{ prop: 'createdAt', dir: 'desc' }];

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private selectedUserDataPoolService: SelectedUserDataPoolService,
        private notificationService: ErrorHandlerService,
        private userDataPoolService: UserDataPoolService
    ) {}

    showDataPoolContent(selectedUserDataPool: UserDatapool) {
        this.selectedUserDataPoolService.OnSelectUserDataPool(
            selectedUserDataPool
        );
        this.router
            .navigate([
                `/${menuPaths.dataManagement.root}/${menuPaths.dataManagement.testData}`,
                selectedUserDataPool.dataPool,
            ])
            .then();
    }

    openEntitlementModal(selectedUserDataPool: UserDatapool) {
        const modalRef = this.modalService.open(EntitlementsModalComponent);
        modalRef.componentInstance.datapool = selectedUserDataPool;

        modalRef.result.then(
            result => {},
            error => {}
        );
    }

    deleteDataPool(selectedUserDataPool: UserDatapool) {
        const modalRef = this.modalService.open(WarningComponent);
        modalRef.componentInstance.message = `<p>Wollen Sie den ausgewählten Daten-Pool "${selectedUserDataPool.userFriendlyName}" und die darin vorhandenen Daten unwiderruflich löschen?</p>`;
        modalRef.componentInstance.closingOption = false;
        modalRef.result.then(
            result => {
                this.userDataPoolService
                    .deleteSelectedUserDataPool(
                        this.apiUrl,
                        selectedUserDataPool
                    )
                    .subscribe(
                        response => {
                            this.notificationService.showSuccess(
                                'Erfolgreich',
                                'Der ausgewählte Datenpool wurde erfolgreich gelöscht.'
                            );
                            this.refreshUserdataPools.emit();
                        },
                        error => {
                            this.notificationService.showError(
                                'Fehler',
                                'Der ausgewählte Datenpool konnte nicht gelöscht werden.'
                            );
                        }
                    );
            },
            error => {}
        );
    }

    showAuthorizationOption(selectedUserDataPool: UserDatapool): boolean {
        return (
            selectedUserDataPool.entitlement ===
                UserDatapoolEntitlement.OWNER ||
            selectedUserDataPool.entitlement === UserDatapoolEntitlement.WRITE
        );
    }

    showDeleteOption(selectedUserDataPool: UserDatapool): boolean {
        return (
            selectedUserDataPool.entitlement === UserDatapoolEntitlement.OWNER
        );
    }

    showIconByAuthorization(selectedUserDataPool: UserDatapool): string {
        return selectedUserDataPool.entitlement !== UserDatapoolEntitlement.READ
            ? 'edit'
            : 'file-alt';
    }

    showEntitlementText(entitlement: UserDatapoolEntitlement): string {
        return setEntitlementText(entitlement);
    }
}
