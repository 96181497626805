import { HttpResponse } from '@angular/common/http';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
    NgbActiveModal,
    NgbModal,
    NgbNavChangeEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { ReasonDialogComponent } from '@shared/components/reason-dialog/reason-dialog.component';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { menuPaths } from '@shared/constants/paths';
import { ROLE_ENV_MAPPING } from '@shared/constants/urls';
import {
    Datapool,
    isSerienDataPool,
    isVorabDataPool,
} from '@shared/models/datapool/datapool';
import {
    FILE_TYPES,
    FileType,
    FileTypeDetail,
    getPath,
} from '@shared/models/filetype/file-type';
import { Image } from '@shared/models/image';
import { ImageDeploymentStatus } from '@shared/models/image-deployment-status.enum';
import { ImportMetadata } from '@shared/models/import-metadata';
import { ModalResult } from '@shared/models/modal-result';
import { ApiService } from '@shared/services/api/api.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { ZkePkwMetadataService } from '@shared/services/metadata/zke-pkw-metadata/zke-pkw-metadata.service';
import { SecurityService } from '@shared/services/security/security.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ImageManagementService } from '../../services/image-management/image-management.service';

@Component({
    selector: 'app-images-file-list',
    templateUrl: './images-file-list.component.html',
    styleUrls: ['./images-file-list.component.scss'],
})
export class ImageFileListComponent implements OnInit, OnDestroy {
    @ViewChild('dataTable') table: DatatableComponent;

    @Input() dataPool: Datapool;
    @Input() image: Image;

    imageStage = '';
    activeTabId = 'attribute';
    fileTypes: FileTypeDetail[] = FILE_TYPES;
    fileRecords: any[] = [];
    sortConfig = [{ prop: 'baureihe', dir: 'asc' }];
    rows: any[];
    dataTableLimit = 5;
    isSecondModalActive = false;
    active = this.fileTypes[0];
    imageDeploymentStatus = ImageDeploymentStatus;
    isPromotingImage = false;
    promoteLoadingText = 'Das Vorab-Image wird zum Serien-Image promoted';

    private ROOT_URL: string;
    private notifier = new Subject();
    private switchSetting: string;

    constructor(
        private activeModal: NgbActiveModal,
        private downloadService: DownloadService,
        private metadataService: ZkePkwMetadataService,
        private imageAdministrationService: ImageManagementService,
        private modalService: NgbModal,
        private notificationService: ErrorHandlerService,
        private renderer: Renderer2,
        private switchService: SwitchService,
        private apiService: ApiService,
        private securityService: SecurityService,
        private router: Router
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
            });

        this.getAllFilesFromImage();
    }

    getAllFilesFromImage() {
        Object.keys(this.image.files).forEach(fileType => {
            this.image.files[fileType].forEach(file => {
                this.fileRecords.push(file);
            });
        });

        this.refreshData(this.activeTabId);
    }

    refreshData(fileType: string) {
        this.rows = [];
        this.rows = this.fileRecords.filter(
            fileRecord =>
                fileRecord.fileType.toUpperCase() === fileType.toUpperCase()
        );
    }

    exportFile(row: any) {
        const metadata = new ImportMetadata(
            row.fileId,
            this.dataPool,
            row.fileType,
            row.fileName,
            row.baureihe,
            row.fileRef,
            row.createdAt,
            row.uploadedAt,
            row.note,
            this.image.deployed,
            row.user
        );
        this.metadataService
            .getExportFile(this.ROOT_URL, metadata, this.dataPool)
            .subscribe((response: HttpResponse<Blob>) => {
                this.downloadService.downloadFile(response);
            });
    }

    promoteImage() {
        this.isSecondModalActive = true;

        setTimeout(() => {
            const element = document.getElementsByClassName('modal-content');

            const modalRef = this.modalService.open(WarningComponent);
            modalRef.componentInstance.message =
                '<p>Möchten Sie das Image als Serien-Image bestätigen?</p>';
            modalRef.componentInstance.closingOption = false;
            modalRef.componentInstance.buttonNegative = 'Abbrechen';
            modalRef.componentInstance.buttonPositive = 'Promoten';

            modalRef.result.then(
                reason => {
                    if (reason === ModalResult.WARNING_OK) {
                        this.isPromotingImage = true;
                        this.imageAdministrationService
                            .promoteImage(this.ROOT_URL, this.image)
                            .subscribe(
                                (response: HttpResponse<any>) => {
                                    this.notificationService.showSuccess(
                                        'Erfolgreich',
                                        'Image wurde promoted'
                                    );
                                    const role = this.securityService.getUserRole();
                                    let apiUrl =
                                        environment[ROLE_ENV_MAPPING[role]];
                                    console.log(apiUrl, this.ROOT_URL);
                                    this.isPromotingImage = false;
                                    this.activeModal.close(
                                        ModalResult.WARNING_OK
                                    );
                                    this.router.navigate([
                                        `${menuPaths.imageManagement.root}/${menuPaths.imageManagement.seriesImages}`,
                                    ]);
                                },
                                error => {
                                    this.renderer.removeClass(
                                        element[0],
                                        'hideModal'
                                    );
                                    this.isPromotingImage = false;
                                    this.isSecondModalActive = false;
                                }
                            );
                    }
                },
                error => {
                    this.renderer.removeClass(element[0], 'hideModal');
                    this.isSecondModalActive = false;
                }
            );
        });
    }

    toProductionImage() {
        this.isSecondModalActive = true;

        setTimeout(() => {
            const element = document.getElementsByClassName('modal-content');
            this.renderer.addClass(element[0], 'hideModal');

            const modalRef = this.modalService.open(ReasonDialogComponent);

            modalRef.result.then(
                reasonDialog => {
                    if (reasonDialog.result === ModalResult.UPDATE_SUCCESSFUL) {
                        this.activeModal.close(reasonDialog);
                    }
                },
                error => {
                    this.renderer.removeClass(element[0], 'hideModal');
                    this.isSecondModalActive = false;
                }
            );
        });
    }

    showBaureiheColumn(): boolean {
        return (
            this.active.path !== getPath(FileType.SKID_MAPPING) &&
            this.active.path !== getPath(FileType.WERKSNUMMER_MAPPING) &&
            this.active.path !== getPath(FileType.SPARTE_MAPPING)
        );
    }

    isPromotable(): boolean {
        return (
            (!this.image.promotedAt || this.image.promotedAt === null) &&
            (this.image.smokeTestStatus === 0 ||
                this.image.smokeTestStatus === 3) && (!this.image.promoted)
        );
    }

    isDeployable(): boolean {
        return this.image.isLambdaReady && !this.isSerienImage(this.image);
    }

    private isSerienImage(image: Image): boolean {
        return image.deployedFrom != null && image.deployedUntil == null;
    }

    isVorabDataPool() {
        return isVorabDataPool(this.dataPool);
    }

    isSerienDataPool() {
        return isSerienDataPool(this.dataPool);
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    setLimit(event) {
        this.dataTableLimit = event;

        setTimeout(() => {
            this.table.limit = this.dataTableLimit;
            this.table.recalculate();
        });
    }

    onNavChange(event: NgbNavChangeEvent<FileTypeDetail>) {
        this.refreshData(event.nextId.path);
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
