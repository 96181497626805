import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XmlPipeModule } from '@shared/pipes/xml/xml.pipe.module';

import { XentryRequestViewComponent } from './xentry-request-view.component';

@NgModule({
    declarations: [XentryRequestViewComponent],
    imports: [CommonModule, XmlPipeModule.forRoot()],
    exports: [XentryRequestViewComponent],
})
export class XentryRequestViewModule {}
