import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { SnsTopic } from '@shared/models/sns-notification/sns-topic';
import { SnsTopicSubscriber } from '@shared/models/sns-notification/sns-topic-subscriber';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SnsNotificationService {
    private topicRoute = 'api/topic';
    private subscriberRoute = 'subscriber';

    constructor(private http: HttpClient) {}

    get(url: string): Observable<SnsTopic[]> {
        return this.http.get<SnsTopic[]>(
            `${url}/${apiPaths.subscriptionManager}/${this.topicRoute}`
        );
    }

    getSubscribersByTopicName(
        url: string,
        topicId: string
    ): Observable<SnsTopicSubscriber[]> {
        return this.http.get<SnsTopicSubscriber[]>(
            `${url}/${apiPaths.subscriptionManager}/${this.topicRoute}/${topicId}/${this.subscriberRoute}`
        );
    }

    deleteSubscriber(url: string, topicName: string, subscriberId: string) {
        return this.http.delete(
            `${url}/${apiPaths.subscriptionManager}/${this.topicRoute}/${topicName}/${this.subscriberRoute}/${subscriberId}`
        );
    }

    saveSubscriber(
        url: string,
        topicName: string,
        subscriberEmail: string
    ): Observable<SnsTopicSubscriber> {
        return this.http.post<SnsTopicSubscriber>(
            `${url}/${apiPaths.subscriptionManager}/${this.topicRoute}/${topicName}/${this.subscriberRoute}`,
            { email: subscriberEmail }
        );
    }
}
