export enum ModalResult {
    IMPORT_SUCCESSFUL,
    IMPORT_FAILED,
    IMPORT_FILE,
    IMPORT_CANCEL,
    WARNING_OK,
    WARNING_CANCEL,
    CREATE_DATAPOOL_OK,
    CREATE_IMAGE_OK,
    CREATE_IMAGE_FAILED,
    UPDATE_SUCCESSFUL,
    UPDATE_CANCEL,
}
