import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoleDirectiveModule } from '@shared/directives/role.module';

import { AppRoutingModule } from '../../app-routing.module';
import { MenuComponent } from './menu/menu.component';
import { SidebarComponent } from './sidebar.component';
import { VersionComponent } from './version/version.component';

@NgModule({
    declarations: [MenuComponent, VersionComponent, SidebarComponent],
    imports: [
        CommonModule,
        RouterModule,
        AppRoutingModule,
        RoleDirectiveModule,
    ],
    exports: [MenuComponent, VersionComponent, SidebarComponent],
})
export class SidebarModule {}
