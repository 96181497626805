import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomTableFooterModule } from '@shared/components/custom-table-footer/custom-table-footer.module';
import { IconButtonModule } from '@shared/components/icon-button/icon-button.module';
import { LoadingSpinnerModule } from '@shared/components/loading-spinner/loading-spinner.module';
import { ToggleButtonModule } from '@shared/components/toggle-button/toggle-button.module';
import { ToggleModule } from '@shared/components/toggle/toggle.module';
import { RoleDirectiveModule } from '@shared/directives/role.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NotificationDetailComponent } from './notification-management/components/notification-detail/notification-detail.component';
import { NotificationTableComponent } from './notification-management/components/notification-table/notification-table.component';
import { NotificationManagementComponent } from './notification-management/notification-management.component';
import { ProductiveSettingComponent } from './productive-setting/productive-setting.component';
import { SettingsComponent } from './settings.component';
import { SmoketestSettingDetailComponent } from './smoketest-overview/components/smoketest-setting-detail/smoketest-setting-detail.component';
import { SmoketestTableComponent } from './smoketest-overview/components/smoketest-table/smoketest-table.component';
import { SmoketestManagementComponent } from './smoketest-overview/smoketest-management.component';
import { TestCancelComponent } from './testautomation/components/test-cancel/test-cancel.component';
import { TestDetailComponent } from './testautomation/components/test-detail/test-detail.component';
import { TestResultComponent } from './testautomation/components/test-result/test-result.component';
import { TestHistoryComponent } from './testautomation/components/test-history/test-history.component';
import { TestStartComponent } from './testautomation/components/test-start/test-start.component';
import { TestautomationComponent } from './testautomation/testautomation.component';
import { LoadingBarModule } from '@shared/components/loading-bar/loading-bar.module';

@NgModule({
    declarations: [
        SettingsComponent,
        ProductiveSettingComponent,
        SmoketestManagementComponent,
        SmoketestTableComponent,
        SmoketestSettingDetailComponent,
        NotificationManagementComponent,
        NotificationTableComponent,
        NotificationDetailComponent,
        TestautomationComponent,
        TestDetailComponent,
        TestResultComponent,
        TestHistoryComponent,
        TestStartComponent,
        TestCancelComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ToggleButtonModule,
        FormsModule,
        ToggleModule,
        NgxDatatableModule,
        LoadingSpinnerModule,
        CustomTableFooterModule,
        IconButtonModule,
        NgbModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RoleDirectiveModule,
        MatChipsModule,
        LoadingBarModule,
    ],
    exports: [SettingsComponent],
})
export class SettingsModule {}
