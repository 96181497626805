import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { TestautomationService } from '@shared/services/testautomation/testautomation.service';
import { takeUntil } from 'rxjs/operators';
import { SwitchService } from '@shared/services/switch/switch.service';
import { ApiService } from '@shared/services/api/api.service';

@Component({
  selector: 'app-test-start',
  templateUrl: './test-start.component.html',
  styleUrl: './test-start.component.scss'
})
export class TestStartComponent implements OnInit{
    @Input() testIsRunning: boolean;
    @Output() testStartedEvent = new EventEmitter<boolean>();
    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();
    isLoading = false;
    loadingSubtitle: string;

    constructor(
        private testautomationService: TestautomationService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) { }

    ngOnInit(): void {
        this.loadingSubtitle = 'Ein Testlauf wird gestartet. Bitte einen Moment Geduld ... ';
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'BASE',
                    this.switchSetting
                );
            });
    }

    startTest(){
        this.isLoading = true;
        this.testautomationService.startTestRun(this.ROOT_URL).subscribe(
            response =>{
                console.log(
                    'Testlauf erfolgreich gestartet!', response
                );
                this.getActiveTestRunIdWithTimeout().subscribe(
                    (response: number) => {
                        console.log(response);
                        this.isLoading = false;
                        this.testStartedEvent.emit(true);
                    },
                    (error: any) => {
                        console.error(error);
                    }
                );
            },
            error => {
                console.error(
                    'Testlauf konnte nicht gestartet werden!', error
                );
            }
        );
    }

    getActiveTestRunIdWithTimeout(){
        return new Observable<number>((observer: Observer<number>) => {
            const makeBackendCall = () => {
                this.testautomationService.getActiveTestRunId(this.ROOT_URL).subscribe(
                    (response: number) => {
                        if (response !== null) {
                            observer.next(response);
                            observer.complete();
                        } else {
                            setTimeout(() => {
                                makeBackendCall();
                            }, 3000);
                        }
                    },
                    (error: any) => {
                        observer.error(error);
                    }
                );
            };
            makeBackendCall();
        });
    }
}
