export const ROLE_ENV_MAPPING = {
    VAN: 'vanApiUrl',
    CAR: 'carApiUrl',
};

export const URLS = {
    TEST: {
        VAN: 'van-test',
        CAR: 'car-test',
    },
    ROOT: {
        VAN: 'zke-van',
        CAR: 'zke-pkw',
    },
    BASE: {
        VAN: '',
        CAR: '',
    },
};
