<div class="card vus-card stretch">
    <div class="card-body">
    <span class="result-title">
      Aktuelles Testergebnis
    </span>
        <p class="subtitle">
            Testlauf {{ recentTestRun.timeStamp | date: "dd.MM.yy/HH:mm:ss" }}</p>
        <div class="test-result-section">
            <div class="test-result" *ngIf="recentTestRun.successfulTestCases > 0">
                <label class="label">
                    <fa-icon
                        [style.color]="'#008351'"
                        icon="circle"
                    ></fa-icon>
                    {{ recentTestRun.successfulTestCases }} bestanden
                </label>
            </div>
            <div class="test-result" *ngIf="recentTestRun.unsuccessfulTestCases > 0">
                <label class="label">
                    <fa-icon
                        [style.color]="'#bb1e10'"
                        icon="circle"
                    ></fa-icon>
                    {{ recentTestRun.unsuccessfulTestCases }} nicht bestanden
                </label>
            </div>
        </div>
    </div>
</div>
