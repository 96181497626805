export enum Datapool {
    TEST = 'TEST_DATEN',
    VORAB = 'VORAB_DATEN',
    SERIE = 'SERIEN_DATEN',
}

export function isTestDataPool(dataPool: Datapool): boolean {
    return dataPool === Datapool.TEST;
}

export function isVorabDataPool(dataPool: Datapool): boolean {
    return dataPool === Datapool.VORAB;
}

export function isSerienDataPool(dataPool: Datapool): boolean {
    return dataPool === Datapool.SERIE;
}
