import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { Baureihe } from '@shared/models/baureihe';
import { Datapool } from '@shared/models/datapool/datapool';
import { Division, getAllDivisions } from '@shared/models/division';
import { FileType } from '@shared/models/filetype/file-type';
import { ModalResult } from '@shared/models/modal-result';
import { ApiService } from '@shared/services/api/api.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { FileParserService } from '@shared/services/file-parser/file-parser.service';
import { ZkePkwMetadataService } from '@shared/services/metadata/zke-pkw-metadata/zke-pkw-metadata.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-vinlist-formular',
    templateUrl: './vinlist-formular.component.html',
    styleUrls: ['./vinlist-formular.component.scss'],
})
export class VinlistFormularComponent implements OnInit, OnDestroy {
    @Output()
    vinListSuccess = new EventEmitter<any>();

    @Output()
    vinListError = new EventEmitter<any>();

    @Input() dataPool: Datapool;
    @Input() dataPoolIdentifier: string;

    form: UntypedFormGroup;
    overwrite = false;
    divisions: Division[];
    title = 'VIN-Liste importieren';
    button = 'Import';

    private ROOT_URL: string;
    private vinListFileContent: any;
    private notifier = new Subject();
    private switchSetting: string;
    private vinListFile: File;
    private vinListFileName: string;
    private messages = {
        successful: 'VIN-Liste wurde erfolgreich importiert',
        error: 'Import der VIN-List ist fehlgeschlagen',
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private errorHandler: ErrorHandlerService,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private fileParserService: FileParserService,
        private importMetadataService: ZkePkwMetadataService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
            });

        this.divisions = getAllDivisions();
        this.form = this.formBuilder.group({
            vinListFile: ['', Validators.required],
            sparte: [
                this.divisions[0] ? this.divisions[0] : '',
                [Validators.required],
            ],
            baureihe: ['', Validators.required],
            note: ['', Validators.maxLength(255)],
        });

        this.activatedRoute.params.subscribe(params => {
            if (params.vinlistname) {
                this.overwrite = true;
                this.form.controls.vinListName.setValue(params.vinlistname);
                this.adjustView();
            }
        });
    }

    fileChanged(data: any) {
        if (data.target.files && data.target.files.length > 0) {
            this.vinListFile = data.target.files[0];
            this.fileParserService
                .parse(this.vinListFile)
                .then((fileContent: any) => {
                    this.vinListFileContent = fileContent;
                    this.vinListFileName = this.vinListFile.name;
                });
        }
    }

    adjustView() {
        if (this.overwrite) {
            this.title = 'VIN-Liste überschreiben';
            this.messages.successful =
                'VIN-Liste wurde erfolgreich überschrieben';
            this.messages.error = 'VIN-Liste konnte nicht überschrieben werden';
            this.button = 'Überschreiben';
        } else {
            this.title = 'VIN-Liste importieren';
            this.messages.successful = 'VIN-Liste wurde erfolgreich importiert';
            this.messages.error = 'VIN-Liste konnte nicht importiert werden';
            this.button = 'Importieren';
        }
    }

    inputIsInvalid(control: string): boolean {
        return this.form.get(control).errors && this.form.get(control).touched;
    }

    uploadVINList() {
        this.markFormGroupTouched(this.form);
        if (this.form.invalid) {
            return;
        }

        const modalRef = this.modalService.open(WarningComponent);
        modalRef.componentInstance.closingOption = false;
        modalRef.componentInstance.message = `<p>Die existierenden Daten der VIN-Liste: ${this.vinListFileName} \
                                              werden durch den Import der VIN-Listen überschrieben. \
                                              Soll der Vorgang fortgesetzt werden?</p>`;

        modalRef.result.then(
            reason => {
                if (reason === ModalResult.WARNING_OK) {
                    const baureihe = new Baureihe(
                        this.form.controls.baureihe.value
                    );
                    this.importMetadataService
                        .uploadFile(
                            this.ROOT_URL,
                            FileType.VINLIST,
                            this.dataPool,
                            baureihe,
                            this.vinListFile.name,
                            this.vinListFileContent,
                            this.form.get('note').value,
                            this.vinListFile.lastModified,
                            this.dataPoolIdentifier
                        )
                        .subscribe(
                            (response: any) => {
                                this.errorHandler.showSuccess(
                                    'Erfolgreich',
                                    this.messages.successful
                                );
                                this.vinListSuccess.emit('success');
                                this.activeModal.close(
                                    ModalResult.IMPORT_SUCCESSFUL
                                );
                            },
                            error => {
                                this.vinListError.emit('error');
                                this.activeModal.close(error);
                            }
                        );
                }
            },
            error => {
                this.activeModal.close(error);
            }
        );
    }

    private markFormGroupTouched(formGroup: UntypedFormGroup) {
        const values = Object.keys(formGroup.controls).map(
            e => formGroup.controls[e]
        );
        values.forEach((control: UntypedFormGroup) => {
            control.markAsTouched();
        });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
