import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { AutoDeployment } from '@shared/models/auto-deployment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ZkePkwAutoDeploymentService {
    constructor(private http: HttpClient) {}

    getAutoDeployment(url: string): Observable<AutoDeployment> {
        return this.http.get<AutoDeployment>(
            `${url}/${apiPaths.controller}/api/autodeployment`
        );
    }

    updateAutoDeployment(url: string, autoDeployment: AutoDeployment) {
        return this.http.put(
            `${url}/${apiPaths.controller}/api/autodeployment`,
            {
                enabled: autoDeployment.enabled,
            }
        );
    }
}
