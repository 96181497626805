import { Injectable } from '@angular/core';
import { ZKERequest } from '@shared/models/zke-request';
import * as XMLWriter from 'xml-writer';

@Injectable({
    providedIn: 'root',
})
export class ZkeXMLSchemaService {
    constructor() {}

    generateCEBASAndETMRequest(zkeRequest: ZKERequest) {
        const xml = new XMLWriter();
        xml.startElement('zke:getZkeRequest');

        xml.writeAttribute(
            'xmlns:xsi',
            'http://www.w3.org/2001/XMLSchema-instance'
        );
        xml.writeAttribute('xmlns:zke', 'http://aftersales.daimler.com/zke');
        xml.writeAttribute(
            'xsi:schemaLocation',
            'http://aftersales.daimler.com/zke zkeCalculation_V3.xsd'
        );

        xml.startElement('finOrVin')
            .text((zkeRequest && zkeRequest.vin) || '')
            .endElement('finOrVin');
        xml.startElement('category')
            .text((zkeRequest && zkeRequest.category) || '')
            .endElement('category');

        xml.startElement('vehicleRead');

        xml.startElement('cebas');
        if (zkeRequest && zkeRequest.zksnrCEBAS) {
            xml.startElement('zkPartNo')
                .text(zkeRequest.zksnrCEBAS)
                .endElement('zkPartNo');
        }
        if (zkeRequest && zkeRequest.skidCEBAS) {
            xml.startElement('BackendSubjectKeyIdentifier')
                .text(zkeRequest.skidCEBAS)
                .endElement('BackendSubjectKeyIdentifier');
        }
        xml.endElement('cebas');

        xml.startElement('etm');
        if (zkeRequest && zkeRequest.zksnrETM) {
            xml.startElement('zkPartNo')
                .text(zkeRequest.zksnrETM)
                .endElement('zkPartNo');
        }
        if (zkeRequest && zkeRequest.skidETM) {
            xml.startElement('RootSubjectKeyIdentifier')
                .text(zkeRequest.skidETM)
                .endElement('RootSubjectKeyIdentifier');
        }
        xml.endElement('etm');

        xml.endElement('vehicleRead');
        xml.endElement('zke:getZkeRequest');

        return xml;
    }
}
