import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export const cebasSkidOrZkSnrValidator: ValidatorFn = (
    formGroup: UntypedFormGroup
) => {
    const zksnrCEBAS = formGroup.get('zksnrCEBAS').value;
    const skidCEBAS = formGroup.get('skidCEBAS').value;

    if (!zksnrCEBAS && !skidCEBAS) {
        return { skidAndZkSnrIsEmpty: true };
    } else if (!zksnrCEBAS && skidCEBAS) {
        const validSkidCEBAS = /^([0-9A-Fa-f]{2}-){19}[0-9A-Fa-f]{2}$/.test(
            skidCEBAS
        );
        return validSkidCEBAS
            ? null
            : { invalidSkidCEBAS: { value: skidCEBAS } };
    } else if (zksnrCEBAS && !skidCEBAS) {
        const validZkSnrCEBAS = /^A[0-9]{10}$/.test(zksnrCEBAS);
        return validZkSnrCEBAS
            ? null
            : { invalidZkSnrCEBAS: { value: zksnrCEBAS } };
    }
};
