import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { commonPaths, loginPath, menuPaths } from '@shared/constants/paths';
import { permissions } from '@shared/constants/permissions';

import { AppAuthGuard } from './app.authguard';
import { ForbiddenPageComponent } from './core/forbidden-page/forbidden-page.component';
import { LoginComponent } from './core/login/login.component';
import { DataManagementComponent } from './modules/data-management/data-management.component';
import { PreDataComponent } from './modules/data-management/pre-data/pre-data.component';
import { SeriesDataComponent } from './modules/data-management/series-data/series-data.component';
import { TestDataSelectedDatapoolComponent } from './modules/data-management/test-data/test-data-list/test-data-list-datatable/test-data-selected-datapool/test-data-selected-datapool.component';
import { TestDataListComponent } from './modules/data-management/test-data/test-data-list/test-data-list.component';
import { ExpertToolComponent } from './modules/expert-tool/expert-tool.component';
import { ImageManagementComponent } from './modules/image-management/image-management.component';
import { PreDataImagesComponent } from './modules/image-management/pre-data-images/pre-data-images.component';
import { SeriesImagesComponent } from './modules/image-management/series-images/series-images.component';
import { TestImagesComponent } from './modules/image-management/test-images/test-images.component';
import { NotificationManagementComponent } from './modules/settings/notification-management/notification-management.component';
import { ProductiveSettingComponent } from './modules/settings/productive-setting/productive-setting.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { SmoketestManagementComponent } from './modules/settings/smoketest-overview/smoketest-management.component';
import { TestautomationComponent } from './modules/settings/testautomation/testautomation.component';

export const routes: Routes = [
    // Login
    {
        path: loginPath,
        component: LoginComponent,
    },
    // Expert Tool
    {
        path: menuPaths.expertTool.root,
        component: ExpertToolComponent,
        canActivate: [AppAuthGuard],
        data: {
            label: 'Expertentool',
            oneOfThem: [
                permissions.PKW.CALCULATE_TEST_WRITE,
                permissions.PKW.CALCULATE_VORAB_WRITE,
                permissions.PKW.CALCULATE_SERIEN_WRITE,
                permissions.VAN.CALCULATE_TEST_WRITE,
                permissions.VAN.CALCULATE_VORAB_WRITE,
                permissions.VAN.CALCULATE_SERIEN_WRITE,
            ],
        },
    },
    // Data Management
    {
        path: menuPaths.dataManagement.root,
        component: DataManagementComponent,
        canActivate: [AppAuthGuard],
        data: {
            label: 'Datenverwaltung',
            oneOfThem: [
                permissions.PKW.DATA_TEST_READ,
                permissions.PKW.DATA_VORAB_READ,
                permissions.PKW.DATA_SERIEN_READ,
                permissions.VAN.DATA_TEST_READ,
                permissions.VAN.DATA_VORAB_READ,
                permissions.VAN.DATA_SERIEN_READ,
            ],
        },
        children: [
            // Default Selection - Pre Data
            {
                path: '',
                pathMatch: 'full',
                redirectTo: `${menuPaths.dataManagement.seriesData}`,
            },
            {
                path: menuPaths.dataManagement.testData,
                component: TestDataListComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Testdaten',
                    oneOfThem: [
                        permissions.PKW.DATA_TEST_READ,
                        permissions.VAN.DATA_TEST_READ,
                    ],
                },
            },
            {
                path: menuPaths.dataManagement.testData + '/:datapool',
                component: TestDataSelectedDatapoolComponent,
                canActivate: [AppAuthGuard],
                data: {
                    oneOfThem: [
                        permissions.PKW.DATA_TEST_READ,
                        permissions.VAN.DATA_TEST_READ,
                    ],
                },
            },
            {
                path: menuPaths.dataManagement.preData,
                component: PreDataComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Vorabdaten',
                    oneOfThem: [
                        permissions.PKW.DATA_VORAB_READ,
                        permissions.VAN.DATA_VORAB_READ,
                    ],
                },
            },
            {
                path: menuPaths.dataManagement.seriesData,
                component: SeriesDataComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Seriendaten',
                    oneOfThem: [
                        permissions.PKW.DATA_SERIEN_READ,
                        permissions.VAN.DATA_SERIEN_READ,
                    ],
                },
            },
        ],
    },
    // Image Management
    {
        path: menuPaths.imageManagement.root,
        component: ImageManagementComponent,
        canActivate: [AppAuthGuard],
        data: {
            label: 'Image-Verwaltung',
            oneOfThem: [
                permissions.PKW.IMAGE_TEST_READ,
                permissions.PKW.IMAGE_VORAB_READ,
                permissions.PKW.IMAGE_SERIEN_READ,
                permissions.VAN.IMAGE_TEST_READ,
                permissions.VAN.IMAGE_VORAB_READ,
                permissions.VAN.IMAGE_SERIEN_READ,
            ],
        },
        children: [
            // Default Selection - Series Images
            {
                path: '',
                pathMatch: 'full',
                redirectTo: `${menuPaths.imageManagement.seriesImages}`,
            },
            {
                path: menuPaths.imageManagement.testImages,
                component: TestImagesComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Test-Images',
                    oneOfThem: [
                        permissions.PKW.IMAGE_TEST_READ,
                        permissions.VAN.IMAGE_TEST_READ,
                    ],
                },
            },
            {
                path: menuPaths.imageManagement.preImages,
                component: PreDataImagesComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Vorab-Images',
                    oneOfThem: [
                        permissions.PKW.IMAGE_VORAB_READ,
                        permissions.VAN.IMAGE_VORAB_READ,
                    ],
                },
            },
            {
                path: menuPaths.imageManagement.seriesImages,
                component: SeriesImagesComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Serien-Images',
                    oneOfThem: [
                        permissions.PKW.IMAGE_SERIEN_READ,
                        permissions.VAN.IMAGE_SERIEN_READ,
                    ],
                },
            },
        ],
    },
    // Settings
    {
        path: menuPaths.settings.root,
        component: SettingsComponent,
        canActivate: [AppAuthGuard],
        data: {
            label: 'Einstellungen',
            oneOfThem: [
                permissions.PKW.SETTINGS_AUTODEPLOY_READ,
                permissions.PKW.SETTINGS_LOGLEVEL_READ,
                permissions.PKW.SETTINGS_SMOKETESTDATA_READ,
                permissions.VAN.SETTINGS_AUTODEPLOY_READ,
                permissions.VAN.SETTINGS_LOGLEVEL_READ,
                permissions.VAN.SETTINGS_SMOKETESTDATA_READ,
            ],
        },
        children: [
            // Default Selection - Productive Setting
            {
                path: '',
                pathMatch: 'full',
                redirectTo: `${menuPaths.settings.productiveSetting}`,
            },
            {
                path: menuPaths.settings.productiveSetting,
                component: ProductiveSettingComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Produktivsetzung',
                    oneOfThem: [
                        permissions.PKW.SETTINGS_AUTODEPLOY_READ,
                        permissions.VAN.SETTINGS_AUTODEPLOY_READ,
                    ],
                },
            },
            {
                path: menuPaths.settings.smokeTestManagement,
                component: SmoketestManagementComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Smoketest-Verwaltung',
                    oneOfThem: [
                        permissions.PKW.SETTINGS_SMOKETESTDATA_READ,
                        permissions.VAN.SETTINGS_SMOKETESTDATA_READ,
                    ],
                },
            },
            {
                path: menuPaths.settings.notificationManagement,
                component: NotificationManagementComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Benachrichtigungen',
                    oneOfThem: [
                        permissions.PKW.SETTINGS_SNS_READ,
                        permissions.VAN.SETTINGS_SNS_READ,
                    ],
                },
            },
            {
                path: menuPaths.settings.testautomation,
                component: TestautomationComponent,
                canActivate: [AppAuthGuard],
                data: {
                    label: 'Testing',
                    oneOfThem: [
                        permissions.PKW.TESTINGBUTTON
                    ],
                },
            },
        ],
    },
    {
        path: commonPaths.forbidden,
        component: ForbiddenPageComponent,
    },
    {
        path: '**',
        redirectTo: commonPaths.home,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
