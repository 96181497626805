import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningComponent } from '@shared/components/warning/warning.component';
import {
    Datapool,
    isSerienDataPool,
    isTestDataPool,
    isVorabDataPool,
} from '@shared/models/datapool/datapool';
import { UserDatapool } from '@shared/models/datapool/user-datapool';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';
import { DeploymentUpdateStatus } from '@shared/models/deployment-update-status';
import { Image } from '@shared/models/image';
import { ImageDeploymentHistory } from '@shared/models/image-deployment-history';
import { ModalResult } from '@shared/models/modal-result';
import { RoleConfig } from '@shared/models/role-config';
import {
    getSmokeTestStatusColorById,
    getSmokeTestStatusEnumById,
} from '@shared/models/smoketest/smoketest-status';
import { ApiService } from '@shared/services/api/api.service';
import { UserDataPoolService } from '@shared/services/datapool/userDataPool/user-datapool.service';
import { ErrorHandlerService } from '@shared/services/error-handler/error-handler.service';
import { AbstractMetadataService } from '@shared/services/metadata/abstract-metadata.service';
import { ZkePkwMetadataService } from '@shared/services/metadata/zke-pkw-metadata/zke-pkw-metadata.service';
import { SecurityService } from '@shared/services/security/security.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { DatatableComponent, isNullOrUndefined } from '@swimlane/ngx-datatable';
import { Subject, Subscription, interval, of } from 'rxjs';
import { catchError, finalize, takeUntil, takeWhile } from 'rxjs/operators';

import { ContainerManagementService } from '../../services/container-administration/container-management.service';
import { ImageManagementService } from '../../services/image-management/image-management.service';
import { ProductiveImageService } from '../../services/productive-image/productive-image.service';
import { ImageDeploymentHistoryComponent } from '../image-deployment-history/image-deployment-history.component';
import { ImageFileListComponent } from '../image-file-list/images-file-list.component';

@Component({
    selector: 'app-images-datatable',
    templateUrl: './images-datatable.component.html',
    styleUrls: ['./images-datatable.component.scss'],
    providers: [
        {
            provide: AbstractMetadataService,
            useExisting: ZkePkwMetadataService,
        },
        DatePipe,
    ],
})
export class ImagesDatatableComponent implements OnInit, OnDestroy {
    @ViewChild('imagesTable', { static: true }) table: DatatableComponent;
    @Input() roleConfig: RoleConfig;
    @Input() dataPool?: Datapool;
    @Input() dataPoolType?: string;

    images: Image[];
    sortConfig = [{ prop: 'imageCreationDate', dir: 'desc' }];
    isDataLoading = true;
    dataTableLimit = 10;
    isNewProductiveImage = false;
    text = 'Das Serien-Image wird produktiv geschaltet';
    deleteRole: string[]; // archiveRole in Serie
    releaseRole: string[];
    canBeDeployed = false;
    ROOT_URL: string;

    private notifier = new Subject();
    private switchSetting: string;
    private apiRefreshSubscription: Subscription;

    constructor(
        private modalService: NgbModal,
        private imageService: ImageManagementService,
        private productiveImageService: ProductiveImageService,
        private notificationService: ErrorHandlerService,
        private containerAdministrationService: ContainerManagementService,
        private securityService: SecurityService,
        private userDataPoolService: UserDataPoolService,
        private datePipe: DatePipe,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
                this.getImages();
            });

        this.mapRoles();
        this.apiRefreshSubscription = interval(60000).subscribe(() => {
            this.getImages();
        });
    }

    ngOnDestroy() {
        if (this.apiRefreshSubscription) {
            this.apiRefreshSubscription.unsubscribe();
        }
        this.notifier.next();
        this.notifier.complete();
    }

    getRowIndex(row: any): number {
        return this.table.bodyComponent.getRowIndex(row);
    }

    getImages() {
        this.isDataLoading = true;
        this.imageService.getImages(this.ROOT_URL, this.dataPool).subscribe(
            (allImages: Image[]) => {
                if (this.isTestDataPool()) {
                    this.images = [];
                    // Workaround: If TestDatapool -> get all Userdatapools, merge properties and filter
                    this.userDataPoolService
                        .getAllUserDataPools(this.ROOT_URL)
                        .subscribe(
                            (dataPools: UserDatapool[]) => {
                                const filteredDatapools = dataPools.filter(
                                    datapool =>
                                        datapool.entitlement ===
                                        (this.isCardMeine()
                                            ? UserDatapoolEntitlement.OWNER
                                            : UserDatapoolEntitlement.WRITE)
                                );

                                for (const image of allImages) {
                                    const dataPoolName = image.imageTag.substring(
                                        image.imageTag.indexOf('_') + 1,
                                        image.imageTag.lastIndexOf('-')
                                    );

                                    const imageDatapool = filteredDatapools.find(
                                        datapool =>
                                            datapool.name === dataPoolName
                                    );

                                    if (imageDatapool) {
                                        image.userFriendlyName =
                                            imageDatapool.userFriendlyName;
                                        image.owner = imageDatapool.owner;

                                        this.images.push(image);
                                    }
                                }
                                this.updateTable();
                            },
                            error => {}
                        );
                } else {
                    this.images = allImages;
                    if (this.images[0].imageTag.includes('SERIEN')) {
                        this.images = this.images.filter(
                            img =>
                                img.deployedUntil !== null ||
                                img.deployedFrom == null
                        );
                    }
                    this.updateTable();
                }
            },
            error => {
                this.images = [];
                this.isDataLoading = false;
            }
        );
    }

    delete(image: Image) {
        const action = this.isSerienDataPool() ? 'archiviert' : 'gelöscht';
        const modalRef = this.modalService.open(WarningComponent);
        modalRef.componentInstance.message = `<p>Bestätigen Sie, damit das ausgewählte Image "${image.imageTag}" ${action} wird.</p>`;
        modalRef.componentInstance.closingOption = false;
        modalRef.result.then(result => {
            this.imageService.deleteImage(this.ROOT_URL, image).subscribe(
                response => {
                    this.notificationService.showSuccess(
                        'Erfolgreich',
                        `Image ${image.imageTag} wurde erfolgreich ${action}.`
                    );
                    this.getImages();
                },
                error => {
                    this.notificationService.showError(
                        'Fehler',
                        `Image ${image.imageTag} konnte nicht ${action} werden.`
                    );
                }
            );
        });
    }

    async showImageFileList(image: Image) {
        let toOpenImage: Image;
        let success = false;
        try {
            toOpenImage = await this.getImageFiles(image);
            success = !isNullOrUndefined(toOpenImage.files);
        } finally {
            if (success) {
                const modalRef = this.modalService.open(
                    ImageFileListComponent,
                    {
                        windowClass: 'modalVeryBig',
                        backdrop: 'static',
                        keyboard: false,
                    }
                );
                modalRef.componentInstance.image = toOpenImage;
                modalRef.componentInstance.dataPool = this.dataPool;

                modalRef.result.then(
                    reasonDialog => {
                        if (
                            reasonDialog.result ===
                            ModalResult.UPDATE_SUCCESSFUL
                        ) {
                            const reason = reasonDialog.reason;
                            if (this.isSerienDataPool()) {
                                return this.imageGoProductive(image, reason);
                            }
                        }
                    },
                    error => {}
                );
            }
        }
    }
    async showImageDeploymentHistory(image: Image) {
        let toOpenHistory: ImageDeploymentHistory[];
        let success = false;
        try {
            toOpenHistory = await this.getImageDeploymentHistory(image);
            success = !isNullOrUndefined(toOpenHistory);
        } finally {
            if (success) {
                const modalRef = this.modalService.open(
                    ImageDeploymentHistoryComponent,
                    {
                        windowClass: 'modalBig',
                        backdrop: 'static',
                        keyboard: false,
                    }
                );
                modalRef.componentInstance.image = image;
                modalRef.componentInstance.history = toOpenHistory;
            }
        }
    }

    setLimit(event) {
        this.dataTableLimit = event;

        setTimeout(() => {
            this.table.limit = this.dataTableLimit;
            this.table.recalculate();
        });
    }

    imageIsDeletable(image: Image): boolean {
        if (image.deployedFrom === null) {
            return true;
        } else return false;
    }

    isTestDataPool(): boolean {
        return isTestDataPool(this.dataPool);
    }

    isVorabDataPool(): boolean {
        return isVorabDataPool(this.dataPool);
    }

    isSerienDataPool(): boolean {
        return isSerienDataPool(this.dataPool);
    }

    isCardMeine(): boolean {
        return this.isTestDataPool() && this.dataPoolType === 'Meine';
    }

    isCardUserspezifisch(): boolean {
        return this.isTestDataPool() && this.dataPoolType === 'Userspezifisch';
    }

    runDeploymentStatusPoller() {
        const pollingInterval = 4000;
        const timeout = 80; // timeout after x tries
        let counter = 0;

        interval(pollingInterval)
            .pipe(
                catchError(error =>
                    of(
                        `Deployment status konnte nicht ermittelt werden: ${error}`
                    )
                ),
                takeWhile(
                    (status: DeploymentUpdateStatus) =>
                        status === DeploymentUpdateStatus.DEPLOYING &&
                        counter < timeout
                ),
                finalize(() => {
                    if (counter >= timeout) {
                        this.notificationService.showError(
                            'Fehler',
                            'Die Fertigstellung des Deployments hat zu lange gedauert (Timeout)'
                        );
                    } else {
                        this.notificationService.showSuccess(
                            'Erfolgreich',
                            'Image wurde erfolgreich produktiv geschaltet.'
                        );
                    }
                    this.getImages();
                    this.productiveImageService.refreshImageStatus();
                    this.isNewProductiveImage = false;
                })
            )
            .subscribe(
                () => counter++,
                error => {
                    console.log(
                        'Deployment status konnte nicht ermittelt werden',
                        error
                    );
                    this.isNewProductiveImage = false;
                }
            );
    }

    getSmokeTestStatus(row: Image): string {
        return getSmokeTestStatusEnumById(row.smokeTestStatus);
    }

    getSmokeTestStatusColor(row: Image): string {
        return getSmokeTestStatusColorById(row.smokeTestStatus);
    }

    getDeployedFromDateString(deployedFrom: string, deployedUntil: string) {
        if (!deployedFrom && !deployedUntil) {
            return 'Nicht produktiv';
        } else {
            return this.datePipe.transform(deployedFrom, 'dd.MM.yy, HH:mm');
        }
    }

    getDeployedUntilDateString(deployedFrom: string, deployedUntil: string) {
        if (!deployedFrom && !deployedUntil) {
            return 'Nicht produktiv';
        } else {
            return this.datePipe.transform(deployedUntil, 'dd.MM.yy, HH:mm');
        }
    }

    private mapRoles() {
        this.deleteRole = this.roleConfig.roles;

        if (isVorabDataPool(this.dataPool) || isSerienDataPool(this.dataPool)) {
            this.releaseRole = this.roleConfig.roles;
        }

        const roles = this.securityService.user.permissions;

        !this.isSerienDataPool() &&
        roles.some(role => this.roleConfig.roles.includes(role))
            ? (this.canBeDeployed = true)
            : (this.canBeDeployed = false);
    }

    private imageGoProductive(image: Image, reason: string) {
        this.isNewProductiveImage = true;
        this.containerAdministrationService
            .updateImage(this.ROOT_URL, image, reason)
            .subscribe(
                (response: any) => {
                    // Workaround for wrong returned location header
                    this.runDeploymentStatusPoller();
                },
                error => {
                    this.notificationService.showError('Fehler', error);
                    this.isNewProductiveImage = false;
                }
            );
    }

    private async getImageFiles(image: Image): Promise<Image> {
        var myImage;
        try {
            myImage = await this.imageService
                .getImageWithFiles(this.ROOT_URL, this.dataPool, image.imageTag)
                .toPromise();
        } catch (error) {
            this.notificationService.showError(
                'Fehler',
                `Image details von ${image.imageTag} konnten nicht geladen werden.`
            );
        }
        return myImage;
    }

    private updateTable(): NodeJS.Timeout {
        return setTimeout(() => {
            this.images = [...this.images];
            this.isDataLoading = false;
        }, 1000);
    }

    private async getImageDeploymentHistory(
        image: Image
    ): Promise<ImageDeploymentHistory[]> {
        var myDeploymentHistory = [];
        try {
            myDeploymentHistory = await this.imageService
                .getImageDeploymentHistory(
                    this.ROOT_URL,
                    this.dataPool,
                    image.imageTag
                )
                .toPromise();
        } catch (error) {
            this.notificationService.showError(
                'Fehler',
                `Deployment Historie von ${image.imageTag} konnte nicht geladen werden.`
            );
        }
        return myDeploymentHistory;
    }
}
