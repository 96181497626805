import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { menuPaths } from '@shared/constants/paths';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-image-management',
    templateUrl: './image-management.component.html',
    styleUrls: ['./image-management.component.scss'],
})
export class ImageManagementComponent implements OnInit {
    childRouting = false;
    private imageManagementPath = `/${menuPaths.imageManagement.root}`;

    constructor(private router: Router) {}

    ngOnInit() {
        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe(
                (route: NavigationEnd) =>
                    (this.childRouting = route.url !== this.imageManagementPath)
            );

        if (this.router.routerState.snapshot.url !== this.imageManagementPath) {
            this.childRouting = true;
        }
    }

    showProductive(): boolean {
        const url = this.router.routerState.snapshot.url;
        return (
            url.endsWith(menuPaths.imageManagement.seriesImages) ||
            url === this.imageManagementPath
        );
    }
}
