import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor() {}

    getItem(key: string) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    setItem(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    clearExcept(keys: string[]) {
        const localStorageRef = { ...localStorage };
        Object.keys(localStorageRef).forEach(key => {
          if (!keys.includes(key)) {
            localStorage.removeItem(key);
            }
        });
    }
}
