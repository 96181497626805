<div class="modal-header">
    <span class="modal-title">{{title || 'Authentifizierungsfehler' }}</span>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            {{message  || 'Es ist ein Authentifizierungsfehler aufgetreten. Bitte laden Sie die Seite neu!' }}
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="showLogin">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="float-right">
                    <button type="button" class="btn btn-primary" id="reloadPage" (click)="redirectToLogin()">
                        <span>Zum Login</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
