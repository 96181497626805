<app-loading-spinner
    *ngIf='isLoading'
    [text]='loadingSubtitle'
    [overlay]='true'></app-loading-spinner>

<div class='card vus-card stretch'>
    <div class='card-body'>
  <span class='start-title'>
     Neuen Testlauf starten
  </span>
        <p class='subtitle'>
            Ein Testlauf kann etwa zwei Stunden in Anspruch nehmen.
        </p>
        <button *ngIf='testIsRunning' [disabled]='true' class='btn btn-primary mt justify disabled'>
            Testlauf starten
        </button>
        <button *ngIf='!testIsRunning' (click)='startTest()' class='btn btn-primary mt justify'>
            Testlauf starten
        </button>
    </div>
</div>
