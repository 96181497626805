import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalculateInstanceSelectorModule } from '@shared/components/calculate-instance-selector/calculate-instance-selector/calculate-instance-selector.module';

import { ImageSelectorComponent } from './image-selector.component';

@NgModule({
    declarations: [ImageSelectorComponent],
    imports: [CommonModule, CalculateInstanceSelectorModule],
    exports: [ImageSelectorComponent],
})
export class ImageSelectorModule {}
