import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CalculateModule } from './calculate/calculate.module';
import { ExpertToolComponent } from './expert-tool.component';

@NgModule({
    declarations: [ExpertToolComponent],
    imports: [CommonModule, CalculateModule],
    exports: [ExpertToolComponent],
})
export class ExpertToolModule {}
