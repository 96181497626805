import { Injectable } from '@angular/core';
import { UserDatapoolEntitlement } from '@shared/models/datapool/user-datapool-entitlement';

export class CalculationInstance {
    constructor(
        public name: string,
        public stage: string,
        public endpoint: string,
        public creationDate: string,
        public note: string,
        public createdBy: string,
        public entitlement?: UserDatapoolEntitlement
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class CalculationInstanceAdapter {
    adapt(item: any): CalculationInstance {
        return new CalculationInstance(
            item.imageTag,
            item.imageStage,
            item.endpoint,
            item.imageCreationDate,
            item.note,
            item.createdBy,
            item.entitlement
        );
    }
}
