import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MediaType } from '@shared/constants/media-type';
import { apiPaths } from '@shared/constants/paths';
import { Baureihe } from '@shared/models/baureihe';
import {
    Datapool,
    isSerienDataPool,
    isTestDataPool,
} from '@shared/models/datapool/datapool';
import { FileType, getPath } from '@shared/models/filetype/file-type';
import { ImageDeploymentStatus } from '@shared/models/image-deployment-status.enum';
import {
    ImportMetadata,
    ImportMetadataAdapter,
} from '@shared/models/import-metadata';
import { AbstractMetadataService } from '@shared/services/metadata/abstract-metadata.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ZkePkwMetadataService extends AbstractMetadataService {
    constructor(
        private http: HttpClient,
        private adapter: ImportMetadataAdapter
    ) {
        super();
    }

    getAllMetadata(
        url: string,
        dataPool: Datapool,
        fileType: FileType,
        dataPoolIdentifier?: string
    ): Observable<ImportMetadata[]> {
        const filePath = getPath(fileType);
        const loadDatapool = isTestDataPool(dataPool)
            ? dataPoolIdentifier
            : dataPool;
        url = isSerienDataPool(dataPool)
            ? `${url}/${apiPaths.calculation}/api/files?fileType=${filePath}`
            : `${url}/${apiPaths.dataManager}/api/files?dataPool=${loadDatapool}&fileType=${filePath}`;

        return this.http
            .get(url)
            .pipe(
                map((data: ImportMetadata[]) =>
                    data.map(item => this.adapter.adapt(item))
                )
            );
    }

    deleteMetadata(url: string, metadata: ImportMetadata) {
        url = `${url}/${apiPaths.dataManager}/api/file/${metadata.fileId}`;
        return this.http.delete(url);
    }

    getExportFile(
        url: string,
        metadata: ImportMetadata,
        dataPool: Datapool
    ): Observable<HttpResponse<Blob>> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append(
            'Content-Type',
            MediaType.jsonUtf8MediaType
        );
        httpHeaders = httpHeaders.append(
            'Accept',
            metadata.fileType === FileType.VINLIST
                ? MediaType.textPlainUtf8MediaType
                : MediaType.csvUtf8MediaType
        );

        if (isSerienDataPool(dataPool)) {
            if (metadata.deployed === ImageDeploymentStatus.READY) {
                url = `${url}/${apiPaths.calculation}/api/file/${metadata.fileId}`;
            } else {
                url = `${url}/${apiPaths.dataManager}/api/file/${metadata.fileId}`;
            }
        } else {
            url = `${url}/${apiPaths.dataManager}/api/file/${metadata.fileId}`;
        }

        return this.http.get<Blob>(url, {
            headers: httpHeaders,
            responseType: 'blob' as 'json',
            observe: 'response',
        });
    }

    uploadFile(
        url: string,
        fileType: FileType,
        dataPool: Datapool,
        baureihe: Baureihe,
        sourceFileName: string,
        file: any,
        note: string,
        createdAt: number,
        dataPoolIdentifier?: string
    ) {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append(
            'Content-Type',
            fileType === FileType.VINLIST
                ? MediaType.textPlainUtf8MediaType
                : MediaType.csvUtf8MediaType
        );

        httpHeaders = httpHeaders.append(
            'Created-At',
            new Date(createdAt).toISOString()
        );

        url = `${url}/${apiPaths.dataManager}/api/file`;

        url =
            url +
            '/' +
            (isTestDataPool(dataPool) ? dataPoolIdentifier : dataPool);

        url = url + '/' + getPath(fileType);

        // WERKSNUMMER_MAPPING doesnt have a baureihe
        if (baureihe) {
            url = url + '/' + baureihe.value;
        }

        url = url + '/' + sourceFileName;

        if (note && note.length > 0) {
            url = url + `?note=${encodeURIComponent(note)}`;
        }

        return this.http.put(url, file, {
            headers: httpHeaders,
            observe: 'response',
        });
    }
}
