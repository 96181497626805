import { FileTypeCategory } from '@shared/models/filetype/file-type-category';

export enum FileType {
    ATTRIBUTE = 'Attribute CSV',
    SKID_MAPPING = 'SKID-Mapping CSV',
    SPARTE_MAPPING = 'Sparte-Mapping CSV',
    TRANSITION = 'Transition CSV',
    VINLIST = 'VIN-Listen',
    WERKSNUMMER_MAPPING = 'Werksnummer-Mapping CSV',
    WHITELIST = 'Whitelisten',
}

export class FileTypeDetail {
    constructor(
        public enumType: FileType,
        public category: FileTypeCategory,
        public path: string
    ) {}
}

export const FILE_TYPES = [
    new FileTypeDetail(
        FileType.ATTRIBUTE,
        FileTypeCategory.Stammdaten,
        'attribute'
    ),
    new FileTypeDetail(
        FileType.TRANSITION,
        FileTypeCategory.Stammdaten,
        'transition'
    ),
    new FileTypeDetail(
        FileType.SKID_MAPPING,
        FileTypeCategory.Mapping,
        'skid_mapping'
    ),
    new FileTypeDetail(
        FileType.WERKSNUMMER_MAPPING,
        FileTypeCategory.Mapping,
        'werksnummer_mapping'
    ),
    new FileTypeDetail(
        FileType.SPARTE_MAPPING,
        FileTypeCategory.Mapping,
        'sparte_mapping'
    ),
    new FileTypeDetail(
        FileType.VINLIST,
        FileTypeCategory.Steuerdaten,
        'vinlist'
    ),
    new FileTypeDetail(
        FileType.WHITELIST,
        FileTypeCategory.Steuerdaten,
        'whitelist'
    ),
];

export function getFileTypesByCategory(
    fileTypeCategory: FileTypeCategory
): FileTypeDetail[] {
    return Object.keys(FILE_TYPES)
        .map(key => FILE_TYPES[key])
        .filter(isNaN)
        .filter(key => key.category === FileTypeCategory[fileTypeCategory]);
}

export function getPath(fileType: FileType): string {
    const filteredFileType = FILE_TYPES.filter(
        fileTypeObject => fileTypeObject.enumType === fileType
    );

    return filteredFileType && filteredFileType.length > 0
        ? filteredFileType[0].path
        : '';
}
