export class Page {
    constructor(
        public page: number,
        public size: number,
        public sort: string
    ) {}
}

export class NgxDataTablePage {
    constructor(public offset: number) {}
}

export class NgxDataTableSorts {
    constructor(public sorts: { dir: string; prop: string }[]) {}
}
