<div class="version-container">
    <div class="grid">
        <label [hidden]="uiVersion.length === 0">UI:</label>
        <span [hidden]="calculationVersion.length === 0">{{uiVersion}}</span>

        <label [hidden]="calculationVersion.length === 0">Berechnung:</label>
        <span [hidden]="calculationVersion.length === 0">{{calculationVersion}}</span>

        <label [hidden]="dataManagerVersion.length === 0">Datamanager:</label>
        <span [hidden]="dataManagerVersion.length === 0">{{dataManagerVersion}}</span>

        <label [hidden]="controllerVersion.length === 0">Steuerung:</label>
        <span [hidden]="controllerVersion.length === 0">{{controllerVersion}}</span>

        <label [hidden]="subscriptionManagerVersion.length === 0">Subscriptions:</label>
        <span [hidden]="subscriptionManagerVersion.length === 0">{{subscriptionManagerVersion}}</span>

        <label [hidden]="vedocGatewayVersion.length === 0">Vedoc-Gateway:</label>
        <span [hidden]="vedocGatewayVersion.length === 0">{{vedocGatewayVersion}}</span>
    </div>
</div>
