import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { CalculationResponse } from '@shared/interfaces/calculation-response';
import { UpdateRequest } from '@shared/interfaces/update-request';
import { VedocCalculationRequest } from '@shared/interfaces/vedoc-calculation-request';
import { AscMetadata } from '@shared/models/asc-metadata';
import { ZKERequest } from '@shared/models/zke-request';
import { ZKEResponse } from '@shared/models/zke-response';
import { ApiService } from '@shared/services/api/api.service';
import { AscFrameGeneratorService } from '@shared/services/asc-frame-generator/asc-frame-generator.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { ZkeService } from '@shared/services/zke/zke.service';
import { ZkeXMLSchemaService } from '@shared/services/zkeXMLSchema/zke-xmlschema.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-calculate',
    templateUrl: './calculate.component.html',
    styleUrls: ['./calculate.component.scss'],
})
export class CalculateComponent implements OnInit, OnDestroy {
    @Output() calculationResult = new EventEmitter<CalculationResponse>();

    calculationResponseEmitter = new EventEmitter<CalculationResponse>();
    xmlRequestEmitter = new EventEmitter<string>();
    zkeRequest: ZKERequest;
    xentryMetadata: AscMetadata;
    targetEndpoint;
    xmlRequest: string;
    ROOT_URL: string;
    BASE_URL: string;
    switchSetting: string;
    isDataFetched = false;

    private notifier = new Subject();

    constructor(
        private zkeService: ZkeService,
        private ascFrameGeneratorService: AscFrameGeneratorService,
        private zkeXMLSchemaService: ZkeXMLSchemaService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.targetEndpoint = '';

        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    this.switchSetting
                );
                this.BASE_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'BASE',
                    this.switchSetting
                );
            });
    }

    didReceiveResponse(status) {
        this.isDataFetched = status;
    }

    setTargetEndpoint(name) {
        if (name.includes('SERIEN')) {
            this.targetEndpoint = apiPaths.calculation;
        }
        if (name.includes('pkw-VORAB')) {
            this.targetEndpoint = apiPaths.zkePkwVorabImage + '/' + name;
        }
        if (name.includes('pkw-USER')) {
            this.targetEndpoint = apiPaths.zkePkwTestImage + '/' + name;
        }
        if (name.includes('van-VORAB')) {
            this.targetEndpoint = apiPaths.zkeVanVorabImage + '/' + name;
        }
        if (name.includes('van-USER')) {
            this.targetEndpoint = apiPaths.zkeVanTestImage + '/' + name;
        }
    }

    checkTargetEndpoint(): boolean {
        switch (this.targetEndpoint) {
            case '':
            case undefined:
                return false;
            default:
                return true;
        }
    }

    updateRequest(data: UpdateRequest) {
        this.zkeRequest = data.zkeRequest;
        this.xentryMetadata = data.metaData;
        this.generateXentryRequestXML();
    }

    resetInput() {
        this.calculationResponseEmitter.emit({ code: null, payload: '' });
    }

    performCalculation(calculationRequest: VedocCalculationRequest) {
        if (calculationRequest) {
            this.zkeService
                .calculateWithVedocManipulation(
                    this.BASE_URL,
                    this.targetEndpoint,
                    this.xmlRequest,
                    calculationRequest.fin,
                    calculationRequest.ttz,
                    calculationRequest.codes
                )
                .subscribe(
                    (response: HttpResponse<ZKEResponse>) => {
                        this.calculationResponseEmitter.emit({
                            code: response.status,
                            payload: response.body.toString(),
                        });
                    },
                    (error: HttpErrorResponse) => {
                        this.calculationResponseEmitter.emit({
                            code: error.status,
                            payload: error.error,
                        });
                    }
                );
        } else {
            this.zkeService
                .calculate(this.BASE_URL, this.targetEndpoint, this.xmlRequest)
                .subscribe(
                    (response: HttpResponse<ZKEResponse>) => {
                        this.calculationResponseEmitter.emit({
                            code: response.status,
                            payload: response.body.toString(),
                        });
                    },
                    (error: HttpErrorResponse) => {
                        this.calculationResponseEmitter.emit({
                            code: error.status,
                            payload: error.error,
                        });
                    }
                );
        }
    }

    private generateXentryRequestXML() {
        const xmlPayload = this.zkeXMLSchemaService.generateCEBASAndETMRequest(
            this.zkeRequest
        );

        this.xmlRequest = this.ascFrameGeneratorService.generate(
            this.xentryMetadata,
            xmlPayload
        );
        this.xmlRequestEmitter.emit(this.xmlRequest);
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }
}
