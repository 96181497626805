<div class="container-fluid vus-modal">
    <form [formGroup]="form">
        <div class="modal-header">
            <h3 class="modal-title">{{ fileType }} importieren</h3>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 form-group">
                    <label for="note">Notiz</label>
                    <textarea class="form-control" [ngClass]="{'is-invalid' : inputIsInvalid('note')}" id="note"
                        formControlName="note" rows="3"></textarea>
                    <div *ngIf="inputIsInvalid('note')" class="invalid-feedback">
                        <div>Das Notiz darf max. 255 Zeichen beinhalten!</div>
                    </div>
                </div>
                <div class="col-12 file-select-container">
                    <label for="file">{{ fileType }} Datei</label>
                    <input autocomplete="off" id="file" class="form-control-file" type="file" formControlName="file"
                        accept=".csv" (change)="fileChanged($event)" [ngClass]="{'is-invalid' : inputIsInvalid('file')}">
                    <div *ngIf="inputIsInvalid('file')" class="invalid-feedback">
                        <div *ngIf="form.get('file').errors.required">Keine Datei ausgewählt!</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" (mousedown)="activeModal.dismiss('cancel')">
                    <span>Abbrechen</span>
                </button>
                <button class="btn btn-primary" (click)="uploadFile()">
                    <span>Import</span>
                </button>
            </div>
        </div>
    </form>
</div>
