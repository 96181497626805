<div class="productive-settings">
    <h3 class="vus-page-title">Produktivsetzung</h3>

    <div class="card calculate-container">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <span class="card-title">Automatische Produktivsetzung eines täglich neuen Vorab-Images</span>
                </div>
                <div *ngIf="autoDeployment">
                    <div class="col-12">
                        <div class="productive-setting-container">
                            <p>Möchten Sie die automatische Produktivsetzung (Serien Image) aktivieren?</p>
                            <h3>{{division}}</h3>
                            <app-toggle [status]="autoDeployment.enabled" (changed)="updateAutoDeployment($event)"></app-toggle>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
