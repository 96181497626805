import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import {
    UserDatapool,
    UserDatapoolAdapter,
} from '@shared/models/datapool/user-datapool';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserDataPoolService {
    constructor(
        private http: HttpClient,
        private adapter: UserDatapoolAdapter
    ) {}

    getAllUserDataPools(url: string): Observable<UserDatapool[]> {
        return this.http
            .get(`${url}/${apiPaths.dataManager}/api/user-datapool`)
            .pipe(
                map((data: UserDatapool[]) =>
                    data.map(item => this.adapter.adapt(item))
                )
            );
    }

    saveNewUserDataPool(url: string, dataPoolName: string, note?: string) {
        return this.http.post(
            `${url}/${
                apiPaths.dataManager
            }/api/user-datapool?dataPool=${dataPoolName}${
                note && note !== '' ? `&note=${encodeURIComponent(note)}` : ''
            }`,
            {
                observe: 'response',
            }
        );
    }

    deleteSelectedUserDataPool(url: string, userDataPool: UserDatapool) {
        return this.http.delete(
            `${url}/${apiPaths.dataManager}/api/user-datapool/${userDataPool.dataPool}`
        );
    }
}
