import { Component } from '@angular/core';
import { AbstractMetadataService } from '@shared/services/metadata/abstract-metadata.service';
import { ZkePkwMetadataService } from '@shared/services/metadata/zke-pkw-metadata/zke-pkw-metadata.service';

@Component({
    selector: 'app-data-management',
    templateUrl: './data-management.component.html',
    providers: [
        {
            provide: AbstractMetadataService,
            useExisting: ZkePkwMetadataService,
        },
    ],
})
export class DataManagementComponent {}
