import { Injectable } from '@angular/core';
import { xml2json } from 'xml-js';

@Injectable({
    providedIn: 'root',
})
export class Xml2jsonService {
    constructor() {}

    convert(xmlData: any) {
        if (xmlData) {
            try {
                return JSON.parse(xml2json(xmlData, { compact: true }));
            } catch (exception) {
                return null;
            }
        } else {
            return JSON.parse('{}');
        }
    }
}
