import { DatePipe, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomTableFooterModule } from '@shared/components/custom-table-footer/custom-table-footer.module';
import { CalculationInstance } from '@shared/models/calculation-instance';
import {
    DatatableComponent,
    NgxDatatableModule,
} from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-calculate-instance-table',
    templateUrl: './calculate-instance-table.component.html',
    styleUrls: ['./calculate-instance-table.component.scss'],
    standalone: true,
    imports: [
        NgxDatatableModule,
        FormsModule,
        CustomTableFooterModule,
        DatePipe,
        NgIf,
    ],
})
export class CalculateInstanceTableComponent implements OnInit {
    @Input() instances: CalculationInstance[];
    @ViewChild('dataTable') table: DatatableComponent;

    dataTableLimit: 5;
    rows: any[];
    selectedRow: any;
    sorts = [{ prop: 'creationDate', dir: 'desc' }];

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
        this.rows = this.instances;
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    useSelectedInstance(row: any) {
        this.activeModal.close(row);
    }

    setLimit(event) {
        this.dataTableLimit = event;

        setTimeout(() => {
            this.table.limit = this.dataTableLimit;
            this.table.recalculate();
        });
    }
}
