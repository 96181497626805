<div class='notification-detail-modal vus-modal'>
    <div class='modal-header close-top-right'>
        <div *ngIf='image'>
            <h3 class='modal-title'>Produktivzeiträume von {{ image.imageTag }}</h3>
        </div>
        <button type='button' class='btn close' aria-label='Close'
                (click)='dismiss()'>
            <fa-icon icon='times'></fa-icon>
        </button>
    </div>
    <div class='modal-body'>
        <ngx-datatable class='material' [columnMode]="'force'" [rows]='history' [draggable]='false'
                       [headerHeight]='50' [rowHeight]="'auto'" [footerHeight]='50' [sorts]='sortConfig'
                       [loadingIndicator]='!history'>
            <ngx-datatable-column name='Produktiv seit' prop='deployedFrom' [resizeable]='false' [sortable]='false'>
                <ng-template let-value='value' let-row='row' ngx-datatable-cell-template>
                    <span>{{ getDateString(value) }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name='Produktiv bis' prop='deployedUntil' [resizeable]='false' [sortable]='false'>
                <ng-template let-value='value' let-row='row' ngx-datatable-cell-template>
                    <span>{{ getDateString(value) }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template>
                    <custom-table-footer [dataTableType]="'Modal'">
                    </custom-table-footer>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>
